export default {
  'primary': '#6A47E9',

  'accent': '#6A47E9',
  'accent-800': '#170F49',
  'accent-100': '#3121B1',
  'accent-80': '#625BF7',
  'accent-70': '#9461DB',
  'accent-60': '#A572F9',
  'accent-40': '#6F6C90',
  'accent-30': '#D6BDFF',
  'accent-20': '#C7B8FF',
  'accent-10': '#F0E8FF',

  // it will probably be primary color
  'gray-120': '#2F2F2F',
  'gray-100': '#2F3135',
  'gray-95': '#3F3F3F',
  'gray-90': '#4D4D4D',
  'gray-80': '#55575D',
  'gray-60': '#81848B',
  'gray-50': '#AEAEAE',
  'gray-45': '#C4C4C4',
  'gray-40': '#D4D4D4',
  'gray-35': '#D9D9D9',
  'gray-30': '#DCDDDE',
  'gray-20': '#E9E9E9',
  'gray-15': '#EFF0F7',
  'gray-10': '#F5F5F5',
  'gray-2': '#F4F4F4',
  'gray-0': '#ffffff',

  'black-300': '#161616',
  'black-100': '#252525',

  // border
  'border-10': '#E9E9E9',

  //statuses
  'created': '#55575D',
  'in_review': '#FF9F2E',
  'in_progress': '#2E90FF',
  'rejected': '#D13434',
  'approved': '#1DCA62',
  'archived': '#6A47E9',
  'to_do': '#55575D',
  'paused': '#D13434',
  'done': '#1DCA62',
  'draft': '#55575D',
  'finished': '#1DCA62',
  'skipped': '#00fff5',
  'locked': '#003c81',

  'error': '#D13434',

  'warning': '#FF9F2E',

  'success': '#6FCF97',
  'success-100': '#1DCA62',
  'revision_needed': '#D13434',
}
