<template>
  <div>
    <v-tabs
      v-model="tabs"
      slider-color="accent"
      slider-size="3"
      height="32"
      grow
      class="tabs mb-6"
    >
      <v-tab
        :disabled="disableExistingUsersTab"
        v-if="!hideMemberTab"
        href="#tab-1"
        class="tab relative px-2 sm:px-4 justify-start text-captions-1 text-capitalize rounded-t gray-60--text"
      >
        <slot name="existingUsersTab">Team members</slot>
      </v-tab>
      <v-tab
        :disabled="disableInvitedUsersTab"
        href="#tab-2"
        class="tab relative px-2 sm:px-4 justify-start text-captions-1 text-capitalize rounded-t gray-60--text"
      >
        <slot name="invitedUsersTab">Client members</slot>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item value="tab-1" v-if="!hideMemberTab">
        <UiAutocomplete
          v-model="searchVal"
          :items="users"
          :item-value="'id'"
          return-object
          clearable
          ref="autocomplete"
          @change="selectNewReviewer"
          class="mb-4"
          placeholder="Search Members"
        />

        <div
          v-if="selectedUsers.existingUsers.length"
          class="pa-2 space-y-2 rounded gray-10"
        >
          <div v-if="showSelectAllForMembers" class="primary--text text-captions-2">
            <button @click="selectAll(true, 'existingUsers')" class="text-decoration-underline underline-offset-1">Select all</button>
            /
            <button @click="selectAll(false, 'existingUsers')" class="text-decoration-underline underline-offset-1">Deselect all</button>
          </div>
          <div
            v-for="user in selectedUsers.existingUsers"
            :key="user.user_id"
            class="d-flex align-center"
          >
            <UiCheckbox
              v-model="user.selected"
              checkbox-form="square"
              hide-details
              :disabled="user.permanent"
              @change="updateUserList"
              style="margin-top: -2px; padding-left: 1px"
            />

            <div
              class="user-row border d-flex align-center px-3 rounded fill-width text-captions-1"
            >
              <UiAvatar
                :src="user.avatar ? user.avatar.url : ''"
                :text="user.first_name"
                size="18"
                class="mr-2"
              />
              <div class="d-flex flex-wrap flex-grow-1">
                <p class="mb-0 mr-1 font-weight-semi-bold">
                  {{ user.first_name || "" }} {{ user.last_name || "" }}
                </p>
                <span class="gray-60--text">({{ user.email }})</span>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <ValidationObserver tag="div" ref="form" class="mb-4">
          <ValidationProvider
            mode="eager"
            tag="div"
            name="email"
            vid="email"
            rules="email"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="inviteMemberEmail"
              dense
              outlined
              hide-details
              placeholder="Invite external users by entering their email address below."
              @keyup.enter="inviteNewUser"
              @blur="inviteNewUser"
              :error="!!errors.length"
              class="invite-new-members-input"
            >
              <template #prepend-inner>
                <IconEnvelopeSolid width="14"/>
              </template>
            </v-text-field>
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">
              {{ errors[0] | capitalize }}
            </div>
          </ValidationProvider>
        </ValidationObserver>

        <div
          v-if="selectedUsers.invitedUsers.length"
          class="pa-2 space-y-2 rounded gray-10"
        >
          <div
            v-for="user in selectedUsers.invitedUsers"
            :key="user.user_id"
            class="d-flex flex-column"
          >
            <div class="d-flex align-center">
              <UiCheckbox
                v-model="user.selected"
                checkbox-form="square"
                hide-details
                @change="updateUserList"
                style="margin-top: -2px; padding-left: 1px"
              />

              <div
                class="user-row border d-flex align-center px-3 rounded fill-width text-captions-1"
              >
                <UiAvatar :text="user.email" size="18" class="mr-2"/>
                <span class="gray-60--text">{{ user.email }}</span>
              </div>
            </div>
            <div class="ml-7 mt-1 text-captions-1 error--text" v-if="errors[user.email] && user.selected">
              {{ errors[user.email] }}
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import UiAutocomplete from "@/components/UI/UiAutocomplete";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UiAvatar from "@/components/UI/UiAvatar";

export default {
  name: "SelectUsers",
  components: {
    IconEnvelopeSolid: () => import("@/components/icons/IconEnvelopeSolid"),

    UiAutocomplete,
    UiCheckbox,
    UiAvatar,
  },
  props: {
    disableExistingUsersTab: {
      type: Boolean,
      default: false,
    },
    disableInvitedUsersTab: {
      type: Boolean,
      default: false,
    },
    hideMemberTab: {
      type: Boolean,
      default: false,
    },
    showSelectAllForMembers: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    users: {
      type: Array,
      default: () => [
        {
          user_id: 1,
          text: "", // first_name last_name
          first_name: "",
          last_name: "",
          email: "",
          selected: false,
          active: false,
          permanent: false,
        },
      ],
    },
  },
  data() {
    return {
      tabs: 1,
      searchVal: "",
      inviteMemberEmail: "",
      selectedUsers: {
        existingUsers: [],
        invitedUsers: [],
      },
    };
  },
  watch: {
    users: {
      deep: true,
      handler() {
        this.selectedUsers.existingUsers = this.users.filter(
          (user) => user.active
        );
      },
    },
  },
  created() {
    this.selectedUsers.existingUsers = JSON.parse(
      JSON.stringify(this.users)
    ).filter((user) => user.active);
  },
  methods: {
    selectNewReviewer(new_user) {
      if (!new_user) return;

      if (
        !this.selectedUsers.existingUsers.find(
          (user) => user.user_id === new_user.user_id
        )
      ) {
        this.selectedUsers.existingUsers.push({
          ...new_user,
          selected: true,
          active: true,
        });
      }

      this.$nextTick(() => {
        this.searchVal = "";
        this.$refs.autocomplete.$children[0].blur();
      });

      this.updateUserList();
    },
    inviteNewUser() {
      this.$refs.form.validate().then((success) => {
        if (!success || !this.inviteMemberEmail.trim()) {
          return;
        }

        if (
          this.selectedUsers.invitedUsers.find(
            (member) => member.email === this.inviteMemberEmail
          )
        ) {
          this.selectedUsers.invitedUsers =
            this.selectedUsers.invitedUsers.filter(
              (member) => member.email !== this.inviteMemberEmail
            );
        }

        this.selectedUsers.invitedUsers.push({
          email: this.inviteMemberEmail,
          selected: true,
        });
        this.inviteMemberEmail = "";

        this.updateUserList();
      });
    },
    updateUserList() {
      this.$emit("onChange", {
        invitedUsers: this.selectedUsers.invitedUsers.filter(
          (item) => item.selected
        ),
        existingUsers: this.selectedUsers.existingUsers.filter(
          (item) => item.selected
        ),
      });
      this.$emit("onUpdate", {
        invitedUsers: this.selectedUsers.invitedUsers,
        existingUsers: this.selectedUsers.existingUsers,
      });
    },
    selectAll(status, group) {
      this.selectedUsers[group].forEach(user => user.selected = status)
      this.updateUserList()
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-slide-group__wrapper {
  overflow: initial;
  contain: initial;
}

::v-deep .tabs {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }
}

.tab {
  height: 30px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2px;
    height: 3px;
    background-color: var(--v-gray-10-base);
  }

  &.v-tab--active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
}

.user-row {
  min-height: 30px;
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-0-base);
}

::v-deep .invite-new-members-input {
  .v-input__slot {
    min-height: 30px !important;
    padding: 0 10px !important;
  }

  .v-input__prepend-inner {
    margin-top: 9px !important;
    padding-right: 14px;
  }
}
</style>
