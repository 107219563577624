export const chatConfig = {
  chat: {
    maxMessageLength: 10000,
    file: {
      acceptedUploadFormats: ['.png', '.jpeg', '.jpg', '.gif', '.pdf'],
      maxUploadFileSize: 1024 * 1000 * 10,
      maxUploadFileSizeLabel: '10MB'
    },
    messageTypes: {
      textMessage: 1,
      task: 2,
      statusChanged: 3,
      newFileAdded: 4,
      newStyleCreated: 5,
      styleRenamed: 6,
      fileDeleted: 7,
      styleDeleted: 8,
    },
  },
  notifications: {
    notificationType: {
      messages: 'messages',
      notifications: 'notifications'
    },
    types: {
      designRequestReviewDesign: 'design-request-review-design',
      updateStatus: 'update-status',
      updateReviewer: 'update-reviewer',
      assetReviewed: 'asset-reviewed',
      projectArchived: 'project-archived',
      projectDelete: 'project-delete',
      mentionInComment: 'mention-in-comment',
      mentionInTask: 'mention-in-task',
      specificationAddToTeam: 'specification-add-to-team',
      taskChangeStatus: 'task-change-status',
      userTaggedInMessage: 'user-tagged-in-message',
      specificationAddAddToTeam: 'specification-add-add-to-team',
      newComment: 'new-comment',
      taskAssigned: 'task-assigned',
      mentionInTaskFile: 'mention-in-task-file',
    },
  }
}
