<template>
  <div class="d-flex align-center">
    <UiBtn :width="showSearch && $vuetify.breakpoint.smAndUp ? '250' : '30'" icon color="gray-60" @click="toggleSearch(true)">
      <IconSearch width="15"/>
    </UiBtn>

    <div v-show="showSearch" class="search-wrap absolute rounded fill-width overflow-hidden">
      <v-text-field
        v-model="searchVal"
        prepend-inner-icon=""
        append-icon=""
        solo
        dense
        flat
        hide-details
        autofocus
        @blur="toggleSearch(false)"
        @keyup.enter="setSearchItem"
        class="project-side-drawer-search"
      >
        <template #prepend-inner>
          <button class="d-flex cursor-pointer" @click="setSearchItem">
            <IconSearch width="14"/>
          </button>
        </template>
        <template #append>
          <button class="d-flex cursor-pointer" @click="clearSearch">
            <IconCancel width="14"/>
          </button>
        </template>
      </v-text-field>

      <v-list dense class="project-side-drawer-search-items pa-0 overflow-y-auto">
        <template v-for="(item, idx) in searchItems">
          <v-list-item
            v-if="item.temp && searchItemsHistory.length && !searchVal.length || !item.temp && searchVal.length || !searchItemsHistory.length"
            :key="`item-${idx+searchItemsHistory.length}`"
            :ripple="false"
            @click="search(item)"
          >
            <v-list-item-title class="d-flex align-center text-captions-1">
              <IconLightbulb v-if="item.temp" width="15" class="flex-shrink-0 mr-2 gray-30--text"/>
              <IconPalette v-else width="15" class="flex-shrink-0 mr-2 gray-30--text"/>
              <div v-html="item.label" class="text-overflow-ellipsis overflow-hidden text-no-wrap"/>
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {highlightText} from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'Search',
  components: {
    IconSearch: () => import('@/components/icons/IconSearch'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconPalette: () => import('@/components/icons/IconPalette'),
    IconLightbulb: () => import('@/components/icons/IconLightbulb'),
    UiBtn,
  },
  data() {
    return {
      showSearch: false,
      searchVal: '',
      searchItem: null,
      searchItemsHistory: [],
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getAllProjectStyles',
    ]),
    searchItems() {
      const items = [
        ...this.searchItemsHistory.map(item => ({...item, label: item.title, temp: true})),
        ...this.getProject.tag.map(tag => ({...tag, type: 'project_tag_id', label: tag.title, temp: false})),
        ...this.getAllProjectStyles.map(style => ({...style, type: 'project_style_id', label: style.title, temp: false})),
      ]
      if (!this.searchVal.trim().length) return items;
      return items.reduce((acc, item) => {
        if (item.title.toLowerCase().includes(this.searchVal.toLowerCase().trim())) {
          acc.push({
            ...item,
            label: highlightText(this.searchVal, item.label)
          });
        }
        return acc;
      }, []);
    },
    noUnique() {
      return !!this.searchItems.find(item => item.title.toLowerCase() === this.searchVal.toLowerCase().trim());
    }
  },
  methods: {
    toggleSearch(val) {
      if (val) {
        this.showSearch = val;
        return;
      }

      setTimeout(() => {
        this.showSearch = val;
      }, 150);
    },
    setSearchItem() {
      if (this.noUnique) {
        this.searchItem = this.searchItems.find(item => item.title.toLowerCase().includes(this.searchVal.toLowerCase().trim()));
      } else {
        this.searchItem = {
          id: null,
          type: 'search',
          title: this.searchVal,
          label: this.searchVal,
        }
      }

      this.search(this.searchItem);
    },
    search(item) {
      this.showSearch = false;

      if (this.searchItemsHistory.length === 6) {
        this.searchItemsHistory.slice(-1, 1);
      }

      this.searchItemsHistory = this.searchItemsHistory.filter(historyItem => historyItem.title !== item.title);

      if (item.title.trim().length) {
        this.searchItemsHistory.unshift(item);
      }
      this.$emit('onSearch', item.title);
    },
    clearSearch() {
      this.searchVal = '';
      this.setSearchItem();
    },
  },
}
</script>

<style scoped lang="scss">
.search-wrap {
  top: 12px;
  right: 24px;
  max-width: 240px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--v-gray-30-base);
  z-index: 2;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    left: 22px;
    right: 22px;
    max-width: calc(100% - 44px);
  }
}

.project-side-drawer-search {
  height: 30px;
  font-size: 12px;

  &::v-deep {
    .v-input__slot {
      padding: 0 10px!important;
      min-height: 30px;
      font-size: 12px;
      input {
        padding-top: 3px!important;
        caret-color: var(--v-gray-80-base) !important;
      }
    }

    .v-input__prepend-inner {
      padding-right: 10px!important;
    }

    .v-input__append-inner {
      color: var(--v-gray-60-base);
    }
  }
}

::v-deep .project-side-drawer-search-items {
  max-height: 300px;
  border-top: 1px solid var(--v-gray-30-base);
  background: var(--v-gray-0-base) !important;

  .v-list-item {
    min-height: 32px;
    padding: 0 10px;

    &:hover {
      background: var(--v-gray-10-base) !important;
    }
  }
}
</style>
