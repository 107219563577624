export default axios => ({

  /**
   * Get file comments
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-GetApiV1Projects1Modules2DesignComments
   * @param project_id
   * @param module_id
   * @param params
   * @returns {*}
   */
  list(project_id, module_id, params = {}) {
    return axios.get(`/projects/${project_id}/modules/${module_id}/design/comments`, {params});
  },

  /**
   * Get file comment
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-GetApiV1Projects1Modules2DesignCommentsId
   * @param project_id
   * @param module_id
   * @param comment_id
   * @returns {*}
   */
  get(project_id, module_id, comment_id) {
    return axios.get(`/projects/${project_id}/modules/${module_id}/design/comments/${comment_id}`);
  },

  /**
   * Create file comment
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-PostApiV1ProjectsProject_idModulesProject_module_idDesignComments
   * @param project_id
   * @param module_id
   * @param data
   * @returns {*}
   */
  create(project_id, module_id, data) {
    return axios.post(`/projects/${project_id}/modules/${module_id}/design/comments`, data);
  },

  /**
   * Update file comment
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-PutApiV1ProjectsProject_idModulesProject_module_idDesignCommentsId
   * @param project_id
   * @param module_id
   * @param comment_id
   * @param data
   * @returns {*}
   */
  update(project_id, module_id, comment_id, data) {
    return axios.put(`/projects/${project_id}/modules/${module_id}/design/comments/${comment_id}`, data);
  },


  /**
   * Toggle comment read status
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-PatchApiV1ProjectsProject_idModulesProject_module_idDesignCommentsIdChangeRead
   * @param project_id
   * @param module_id
   * @param comment_id
   * @param data
   * @returns {*}
   */
  toggleReadStatus(project_id, module_id, comment_id, data) {
    return axios.patch(`/projects/${project_id}/modules/${module_id}/design/comments/${comment_id}/change-read`, data);
  },


  /**
   * Delete file comment
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Comments-DeleteApiV1ProjectsProject_idModulesProject_module_idDesignCommentsId
   * @param project_id
   * @param module_id
   * @param comment_id
   * @returns {*}
   */
  delete(project_id, module_id, comment_id) {
    return axios.delete(`/projects/${project_id}/modules/${module_id}/design/comments/${comment_id}`);
  },

});
