export default {
  /**
   * @param commit
   * @param payload
   */
  setActiveFolder({ commit, rootState }, payload) {
    if(!payload) {
      return
    }
    const activeFolder = rootState.Spaces.spaces.reduce((acc, el) => {
      const folder = el.folders.find(f => f.id.toString() === payload.toString())
      if(folder) {
        acc = folder
      }
      return acc
    }, null)
    commit('SET_ACTIVE_FOLDER', activeFolder)
    commit('SET_ACTIVE_SPACE', activeFolder?.space_id || null)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateActiveFolder({ commit }, payload) {
    commit('UPDATE_ACTIVE_FOLDER', payload)
  },

  /**
   *
   * @param commit
   */
  removeActiveFolder({ commit }) {
    commit('DELETE_ACTIVE_FOLDER')
  },

}
