<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    dense
    outlined
    hide-details
    :clearable="clearable"
    :menu-props="{contentClass: 'ui-autocomplete-menu accent-scroll', maxHeight: 300, ...menuProps}"
    class="ui-autocomplete"
  >
    <template #append>
      <slot name="append">
        <IconChevronDown width="12" class="chevron-icon icon-transition"/>
      </slot>
      <button v-if="clearable" type="button" class="clear-btn absolute align-center justify-center pointer-events-none cursor-pointer">
        <IconCancel width="14"/>
      </button>
    </template>
    <template #append-item>
      <slot name="append-item"/>
    </template>
    <template #append-outer>
      <slot name="append-outer"/>
    </template>
    <template v-if="!!$scopedSlots.counter" #counter="{counter}">
      <slot name="counter" :counter="counter"/>
    </template>
    <template v-if="!!$scopedSlots.item" #item="{item}">
      <slot name="item" :item="item"/>
    </template>
    <template #label>
      <slot name="label"/>
    </template>
    <template v-if="!!$scopedSlots.message" #message="{message}">
      <slot name="message" :message="message"/>
    </template>
    <template #no-data>
      <slot name="no-data"/>
    </template>
    <template #prepend>
      <slot name="prepend"/>
    </template>
    <template #prepend-inner>
      <slot name="prepend-inner">
        <IconSearch width="13"/>
      </slot>
    </template>
    <template #prepend-item>
      <slot name="prepend-item"/>
    </template>
    <template #progress>
      <slot name="progress"/>
    </template>
    <template v-if="!!$scopedSlots.selection" #selection="{item}">
      <slot name="selection" :item="item"/>
    </template>
  </v-autocomplete>
</template>

<script>

export default {
  name: 'UiAutocomplete',
  inheritAttrs: false,
  components: {
    IconSearch: () => import('@/components/icons/IconSearch'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconCancel: () => import('@/components/icons/IconCancel'),
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    menuProps: {
      type: Object,
      default: () => {},
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep.ui-autocomplete {

  .v-input__slot {
    transition: padding 0s;
    min-height: 30px!important;
    padding: 0 12px!important;
  }

  &.v-select--is-menu-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .chevron-icon {
      transform: rotate(-180deg);
    }
    fieldset {
      border-color: var(--v-gray-30-base);
      border-bottom: none;
    }
  }

  &.v-input--is-dirty {
    .v-input__slot {
      padding-right: 0!important;
    }
    .clear-btn {
      display: block;
    }
    .chevron-icon {
      display: none;
    }
  }

  .chevron-icon {
    margin-top: 9px!important;
  }

  .clear-btn {
    display: none;
    right: 4px;
    width: 24px;
    height: 24px;
    padding-top: 3px;
  }

  .v-input__prepend-inner {
    margin-top: 9px!important;
    padding-right: 14px;
  }

  .v-input__append-inner {
    margin-top: 3px!important;
  }

  .v-input__icon--clear {
    opacity: 0!important;
  }

  .v-select__selections {
    padding: 1px 0 0!important;
  }
}
</style>
