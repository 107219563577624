<template>
  <UITextModal modal-name="textModal" max-width="500">
    <div class="py-12 px-6 px-sm-12 text-center">
      <h3 v-if="getModal('textModal').data.title" class="mb-4 font-weight-semi-bold text-header">
        {{ getModal('textModal').data.title }}
      </h3>

      <p
        v-if="typeof getModal('textModal').data.text === 'string'"
         style="word-break: break-word"
         class="mb-0 t-2 text-body"
      >
        {{ getModal('textModal').data.text }}
      </p>
      <template v-else>
        <p
          v-for="(text, idx) in getModal('textModal').data.text"
          :key="idx"
          style="word-break: break-word"
          class="mb-0 mt-2 text-body"
        >
          {{text}}
        </p>
      </template>
    </div>
  </UITextModal>
</template>

<script>
import UITextModal from "../UI/UITextModal";
import {mapGetters} from "vuex";

export default {
  name: 'TextModal',
  components: {
    UITextModal,
  },
  computed: {
    ...mapGetters([
      'getModal'
    ])
  }
}
</script>
