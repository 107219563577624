<template>
  <tr>
    <td>
      <div class="pl-5">
        <v-icon size="15">{{ filesIcon }}</v-icon>
      </div>
    </td>
    <td>
      <div class="d-flex">
        <v-tooltip left top color="accent">
          <template #activator="{attrs, on}">
            <div class="flex overflow-hidden file-name-cell" v-bind="attrs" v-on="on">
              <div class="text-truncate">{{ file.file.download_file_name || file.file.original_name }}</div>
              <div class="text-truncate accent--text font-weight-bold">{{ file.project_module.title }}</div>
            </div>
          </template>
          <div class="text-captions-1">
            <div>{{ file.file.download_file_name || file.file.original_name }}</div>
            <div class="font-weight-bold">{{ file.project_module.title }}</div>
          </div>
        </v-tooltip>
      </div>
    </td>
    <td>
      <v-tooltip left top color="accent">
        <template #activator="{attrs, on}">
          <div class="d-flex flex-column text-no-wrap" v-bind="attrs" v-on="on">
            <span>Updated</span>
            <span class="gray-80--text font-weight-bold">{{ displayDate }}</span>
          </div>
        </template>
        <span class="text-captions-1">{{ formattedDate }}</span>
      </v-tooltip>
    </td>
    <td>
      <div>V:
        <span class="font-weight-bold accent--text">
          {{ file.version }}
        </span>
      </div>
    </td>
    <td>
      <div class="space-x-1 d-flex pr-2">
        <UiBtn icon color="gray-60" @click="viewFile(file.file)">
          <IconEye width="16"/>
        </UiBtn>
        <UiMenu offset-y nudge-bottom="4" nudge-left="8">
          <template #activator="{on, attrs}">
            <UiBtn icon v-bind="attrs" v-on="on" color="gray-60">
              <IconDotsH width="18"/>
            </UiBtn>
          </template>
          <v-list nav dense color="gray-10">
            <v-list-item :disabled="fileLoading"
                         :loading="fileLoading"
                         @click="download(file.file)">
              <v-list-item-title class="text-captions-1">
                <IconDownload width="14" class="mr-2 gray-60--text"/>
                Download file
              </v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="!deleteDisable" @click="deleteFile">
              <v-list-item-title class="text-captions-1">
                <IconDelete width="14" class="mr-2 gray-60--text"/>
                Delete file
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </UiMenu>
      </div>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import {isToday, formatDistanceToNowStrict, format, parseISO} from 'date-fns'

export default {
  name: "DesignFileItem",
  components: {
    IconEye: () => import('@/components/icons/IconEye'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconDelete: () => import('@/components/icons/IconDelete'),

    UiMenu,
    UiBtn
  },
  props: {
    file: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      fileLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getPermission',
      'getProjectPermissionData'
    ]),
    deleteDisable() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (this.file.created_by === this.getAuthId ||
        this.getProjectPermissionData(this.$route.params.project_id).roles.some(role => role === projectOwner || role === projectManager)
      ) && this.getPermission(this.$route.params.project_id)['project-s-drawer']['can-delete-design-file-p-s-d'];
    },
    filesIcon() {
      switch (this.file.file.type) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/gif': {
          return 'far fa-file-image'
        }
        case 'application/pdf': {
          return 'far fa-file-pdf'
        }
        default: {
          return 'far fa-file'
        }
      }
    },
    displayDate() {
      const date = parseISO(this.file.file.created_at)
      return isToday(date) ? `${formatDistanceToNowStrict(date)} ago` : format(date, 'MMMM d - hh:mm aaa')
    },
    formattedDate() {
      const date = parseISO(this.file.file.created_at)
      return format(date, 'PP - hh:mm aaa')
    }
  },
  methods: {
    viewFile() {
      this.$emit('viewFile', this.file.file)
    },
    async download(file) {
      if (this.fileLoading || !file) return;
      this.fileLoading = true;
      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || file.original_name;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
      this.fileLoading = false;
    },
    deleteFile() {
      if (!this.deleteDisable) return
      this.$emit('deleteFile', this.file)
    }
  }
}
</script>

<style scoped lang="scss">
.file-name-cell {
  max-width: 170px;
}
</style>
