<template>
  <div class="d-flex flex-column fill-height overflow-hidden">
    <div ref="files_body" class="fill-height rounded-scroll-track overflow-auto py-3" @scroll="loadFiles">
      <div class="px-6 mb-3">
        <div class="text-captions-1 gray-80--text mb-2">Logs ({{ getProjectSideDrawerLogHistory.total }})</div>
        <v-divider/>
      </div>
      <SideDrawerTable :headers="headers">
        <LogHistoryItem v-for="log in getProjectSideDrawerLogHistory.data" :key="log.id" :log="log"
                        @viewFile="viewFile"/>
      </SideDrawerTable>
      <div v-if="loadingData" class="d-flex justify-center align-center pt-2">
        <v-progress-circular :size="24" width="3" color="accent" indeterminate/>
      </div>
    </div>
    <v-footer height="70" color="gray-30" class="d-flex justify-end flex-shrink-0 px-6" v-if="false">
      <UiBtn color="accent">
        <IconUpload width="16" class="mr-2"/>
        Upload File
      </UiBtn>
    </v-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SideDrawerTable from "@/components/project/side-drawer/SideDrawerTable";
import LogHistoryItem from "@/components/project/side-drawer/LogHistoryItem";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'LogsTab',
  components: {
    IconUpload: () => import('@/components/icons/IconUpload'),

    UiBtn,
    LogHistoryItem,
    SideDrawerTable
  },
  data() {
    return {
      loadingData: false,
      headers: [
        {title: 'File Name', classes: 'border-b', classesTh: 'pl-6 px-0 text-no-wrap', width: '40%'},
        {title: 'Last Updated', classes: 'border-b', classesTh: 'px-0 text-no-wrap', width: '20%'},
        {title: 'Version', classes: 'border-b', classesTh: 'px-0 text-no-wrap', width: '10%'},
        {title: '', classes: 'border-b', classesTh: 'pr-6 pl-0 text-no-wrap', width: '1%'},
      ]
    }
  },
  watch: {
    showProjectSideDrawer(val) {
      if (val) {
        this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'logs', data: []});
        this.loadDesignFiles(`/projects/${this.$route.params.project_id}/items`)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectSideDrawer',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerLogHistory'
    ]),
  },
  created() {
    this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'logs', data: []});
    this.loadLogHistory(`/projects/${this.$route.params.project_id}/site-drawer/log-history`)
  },
  mounted() {
    this.$eventBus.$on('updateSidDrawerFilters', async data => {
      const logsFilters = this.getProjectSideDrawer.filtersByTab.logs;
      const updatedFilterItems = Object.keys(data);
      const updateNeeded = updatedFilterItems.some(filterItem => logsFilters.includes(filterItem) && JSON.stringify(data[filterItem]) !== JSON.stringify(this.getProjectSideDrawerFilters[filterItem]))
      if (updateNeeded) {
        await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'logs', data: []});
        this.loadLogHistory(`/projects/${this.$route.params.project_id}/site-drawer/log-history`, {
          ...this.getProjectSideDrawerFilters,
          ...data,
        })
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('updateSidDrawerFilters');
  },
  methods: {
    loadFiles() {
      const filesBody = this.$refs.files_body;
      if (!this.getProjectSideDrawerLogHistory.nextLink || this.loadingData) return
      if (filesBody.scrollTop + filesBody.clientHeight >= filesBody.scrollHeight) {
        this.loadLogHistory(this.getProjectSideDrawerLogHistory.nextLink)
      }
    },
    viewFile({type, url, original_name}) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;
      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type, url, original_name
        },
        handlers: {
          onClose: () => {
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          }
        }
      });
    },
    loadLogHistory(url, data = null) {
      this.loadingData = true
      const {
        search,
        project_tag_id,
        project_style_id,
        order,
        assignee,
        event_type,
        project_module
      } = data || this.getProjectSideDrawerFilters
      this.$api.project.getProjectSideDrawerLogHistory(url, {
        order,
        search,
        project_style_id,
        project_tag_id,
        assignee,
        event_type,
        project_module: project_module ? [project_module] : null
      })
        .then(res => {
          const logs = [...this.getProjectSideDrawerLogHistory.data, ...res.data].map(file => {
            return {
              ...file,
              loadingForDownload: false,
              loading: false
            }
          })
          this.$store.dispatch('setProjectSideDrawerTabData', {
            tab: 'logs',
            data: logs,
            total: res.total,
            nextLink: res.links.next,
          })
        }).finally(() => {
        this.loadingData = false
      })
    }
  }
}
</script>
