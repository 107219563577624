import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const STORAGE_ADMIN_TOKEN_KEY = 'admin_auth'
export const STORAGE_TEMP_AUTH_KEY = 'temp_auth'
export const STORAGE_AUTH_KEY = 'auth'

const state = {
  auth: JSON.parse(localStorage.getItem(STORAGE_AUTH_KEY)),
  temp_auth: JSON.parse(sessionStorage.getItem(STORAGE_TEMP_AUTH_KEY)),
  admin_token: JSON.parse(sessionStorage.getItem(STORAGE_ADMIN_TOKEN_KEY)),
  user: null,
  rolesList: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
