<template>
  <v-navigation-drawer
    v-if="project !== null || getProject.id !== null"
    v-model="getProjectSideDrawer.showProjectSideDrawer"
    class="side-drawer"
    :permanent="getProjectSideDrawer.isSideDrawerPermanent"
    fixed
    hide-overlay
    right
    temporary
    disable-route-watcher
    touchless
    :width="$vuetify.breakpoint.xsOnly? '100%' : 508"
  >
    <v-flex v-if="getProjectSideDrawer.initialRender" class="d-flex justify-center align-center">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </v-flex>
    <template v-else>
      <div class="d-flex align-center mb-5">
        <UiBtn color="accent" fab class="ml-6"
               @click="$store.dispatch('toggleProjectSideDrawer', false)"
        >
          <IconExpandRight width="16" v-if="showProjectSideDrawer"/>
          <IconExpandLeft width="16" v-else/>
        </UiBtn>

        <v-tabs
          v-model="getProjectSideDrawer.tabs"
          slider-color="accent"
          slider-size="3"
          height="32"
          grow
          optional
          class="tabs pl-4 pr-6"
          :show-arrows="$vuetify.breakpoint.xsOnly"
        >
          <v-tab
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-activity-p-s-d']"
            class="tab relative text-captions-1 text-capitalize rounded-t" href="#activities">
            Activity
          </v-tab>

          <v-tab
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-tasks-p-s-d']"
            class="tab relative text-captions-1 text-capitalize rounded-t" href="#tasks">
            Tasks
          </v-tab>

          <v-tab
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-design-files-p-s-d']"
            class="tab relative text-captions-1 text-capitalize rounded-t" href="#files">
            Design Files
          </v-tab>

          <v-tab
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-log-history-p-s-d']"
            class="tab relative text-captions-1 text-capitalize rounded-t" href="#logs">
            Log History
          </v-tab>
        </v-tabs>
      </div>

      <v-divider/>

      <Filters/>

      <div class="fill-height relative overflow-hidden">
        <v-tabs-items v-model="getProjectSideDrawer.tabs" class="fill-height overflow-hidden gray-10" touchless>
          <v-tab-item
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-activity-p-s-d'] && getProjectSideDrawer.tabs === 'activities'"
                      value="activities"
                      class="fill-height"
          >
            <ActivityTab/>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-tasks-p-s-d'] && getProjectSideDrawer.tabs === 'tasks'"
            value="tasks"
            class="fill-height">
            <TasksTab/>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-design-files-p-s-d'] && getProjectSideDrawer.tabs === 'files'"
            value="files"
            class="fill-height">
            <FilesTab/>
          </v-tab-item>
          <v-tab-item
            v-if="getPermission($route.params.project_id)['project-s-drawer']['can-view-log-history-p-s-d'] && getProjectSideDrawer.tabs === 'logs'"
            value="logs"
            class="fill-height">
            <LogsTab/>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </template>

  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import Filters from "@/components/project/side-drawer/filters/Filters";
import ActivityTab from "@/components/project/side-drawer/ActivityTab";
import TasksTab from "@/components/project/side-drawer/TasksTab";
import FilesTab from "@/components/project/side-drawer/FilesTab";
import LogsTab from "@/components/project/side-drawer/LogsTab";

export default {
  name: 'ProjectSideDrawer',
  components: {
    IconExpandRight: () => import('@/components/icons/IconExpandRight'),
    IconExpandLeft: () => import('@/components/icons/IconExpandLeft'),

    Filters,
    ActivityTab,
    TasksTab,
    FilesTab,
    LogsTab,
    UiBtn
  },
  data() {
    return {
      project: null,
      prevRouteName: null,
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
      'getProject',
      'getPermission',
      'getActiveStyle',
      'getProjectSideDrawer',
      'showProjectSideDrawer',
      'getProjectDesignsFilters',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerDefaultFilters',
      'getProjectSideDrawerActivities',
    ]),
  },
  watch: {
    '$route.name'(val, oldVal) {
      this.prevRouteName = oldVal
    },
    showProjectSideDrawer: {
      async handler(nv) {
        if (nv) {
          if (['ProjectDesign', 'DesignViewPage'].includes(this.$route.name)) {
            if (this.$route.name === 'DesignViewPage') {
              const filters = {
                project_style_id: this.getActiveStyle.id,
                project_module: +this.$route.query.module_id,
              }
              this.$eventBus.$emit('updateSidDrawerFilters', filters);
              await this.$store.dispatch('setProjectSideDrawerFilters', filters);
            } else if(this.$route.name === 'ProjectDesign' && this.prevRouteName !== 'ProjectDesign'){
                const filters = {
                  project_style_id: null,
                  project_module: null,
                }
                this.$eventBus.$emit('updateSidDrawerFilters', filters);
                await this.$store.dispatch('setProjectSideDrawerFilters', filters);
                this.prevRouteName = this.$route.name
              }
          }
        } else {
          this.setActiveTab(null)
        }


        if (nv && this.$store.state.ProjectSideDrawer.initialRender) {
          await this.loadData();

          this.$store.state.ProjectSideDrawer.initialRender = false;
          this.getMessages();
        }
      }
    }
  },
  beforeDestroy() {
    this.getProjectSideDrawer.showProjectSideDrawer = false
    this.getModal('createNewTaskModal').projectList = [];
  },
  created() {
    if (this.$route.query.open_project_chat) {
      this.$store.dispatch('toggleProjectSideDrawer', true);
      this.setActiveTab('activities')
      this.$router.$updateQueryParams({open_project_chat: null});
    }
  },
  mounted() {
    this.$ws.addOnMessageCallback('projectSideDrawerData', data => {
      if (data.method_id === this.$config.ws.methods.paginationMessage) {
        this.$store.dispatch('setProjectSideDrawerTabData', {
          tab: 'activities',
          data: [
            ...this.getProjectSideDrawerActivities.data,
            ...data.data.data,
          ],
          total: data.data.total,
        });
      }
      if (data.method_id === this.$config.ws.methods.createMessage) {
        this.$store.dispatch('setProjectSideDrawerTabData', {
          tab: 'activities',
          data: [
            data.data,
            ...this.getProjectSideDrawerActivities.data,
          ],
          total: this.getProjectSideDrawerActivities.total + 1,
        });
      }
      if (data.method_id === this.$config.ws.methods.deleteMessage) {
        this.$store.dispatch('setProjectSideDrawerTabData', {
          tab: 'activities',
          data: [
            ...this.getProjectSideDrawerActivities.data.filter(message => message.id !== data.data.message_id),
          ],
          total: this.getProjectSideDrawerActivities.total - 1,
        });
      }
      if (data.method_id === this.$config.ws.methods.updateMessage) {
        this.$store.dispatch('setProjectSideDrawerTabData', {
          tab: 'activities',
          data: [
            ...this.getProjectSideDrawerActivities.data.map(message => {
              if (message.id !== data.data[0].id) {
                return message
              } else {
                return data.data[0]
              }
            }),
          ],
          total: this.getProjectSideDrawerActivities.total,
        });
      }
      if (data.method_id === this.$config.ws.methods.projectUpdates) {
        this.$store.dispatch('setProjectSideDrawerTabData', {
          tab: 'activities',
          data: [
            data.data,
            ...this.getProjectSideDrawerActivities.data,
          ],
          total: this.getProjectSideDrawerActivities.total + 1,
        });
      }
    });
  },
  methods: {
    ...mapActions([
      'setActiveTab',
    ]),
    async loadData() {
      const project = await this.$api.project.get(this.$route.params.project_id);
      this.project = project.data;

      const designModule = this.project.modules.find(module => module.type === this.$config.project.moduleTypes.design);
      const tags = this.project.tag.map(tag => tag.id);
      const styleList = await this.$api.projectModuleDesign.list(this.project.id, designModule.id, {tags});

      await this.$store.dispatch('setAllProjectStyles', styleList.data?.reverse());
      await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'activities', data: [], total: 0});
    },
    getMessages() {
      const {project_module} = this.getProjectSideDrawerFilters;
      this.$ws.sendMessage(this.$config.ws.methods.paginationMessage, {
        ...this.getProjectSideDrawerFilters,
        project_id: this.project.id,
        project_module: project_module ? [project_module] : null,
        from: 0,
        search: encodeURIComponent(this.getProjectSideDrawerFilters.search),
      });
    },
    async resetDrawerFilter() {
      const filters = {
        project_style_id: this.getActiveStyle.id,
        project_module: this.moduleId,
      }
      this.$eventBus.$emit('updateSidDrawerFilters', filters);
      await this.$store.dispatch('setProjectSideDrawerFilters', filters);
    }
  }
}
</script>

<style lang="scss" scoped>
.side-drawer {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  z-index: 11;

  &::v-deep {
    .v-navigation-drawer__content {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-top: 76px;
    }

    .v-navigation-drawer__border {
      display: none;
    }
  }
}


::v-deep .tabs {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }

  .v-slide-group__wrapper {
    overflow: initial;
    contain: initial;
  }

  .tab {
    height: 30px;
    padding: 14px;
    color: var(--v-gray-60-base) !important;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      padding: 0 7px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      height: 3px;
      background-color: var(--v-gray-30-base);
    }

    &.v-tab--active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      color: var(--v-gray-60-base) !important;
    }
  }
}
</style>
