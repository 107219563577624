<template>
  <div v-if="asSlot">
    <slot :selectedStatus="selectedStatus"></slot>
  </div>
  <div
    v-else-if="block && selectedStatus"
    v-on="$listeners"
    v-bind="$attrs"
    class="status-block text-captions-2 text-center text-capitalize py-1"
    :style="{borderColor: `var(--v-${selectedStatus.color}-base)`}">
    {{ selectedStatus.title }}
  </div>
  <div
    v-else-if="!block && selectedStatus"
    v-on="$listeners"
    v-bind="$attrs"
    class="status-circle text-captions-1 d-flex align-center">
    <span class="d-inline-block circle rounded-circle"
          :style="{backgroundColor: `var(--v-${selectedStatus.color}-base)`}"/>
    <span class="ml-2" v-if="!circle">{{ selectedStatus.title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    block: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    statusType: {
      type: String,
      default: 'project' // project, module, task
    },
    asSlot: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statuses: [],
    }
  },
  computed: {
    selectedStatus() {
      return this.statuses.find(el => +el.id === +this.id)
    }
  },
  created() {
    let statusLabels = this.$config.project.statusLabels
    let statusColors = this.$config.project.statusColors;

    if (this.$props.statusType === 'module') {
      statusLabels = this.$config.project.moduleStatusLabels;
      statusColors = this.$config.project.moduleStatusColors;
    } else if (this.$props.statusType === 'task') {
      statusLabels = this.$config.tasks.statusLabels;
      statusColors = this.$config.tasks.statusColors;
    } else if (this.$props.statusType === 'space') {
      statusLabels = this.$config.space.userStatusLabels;
      statusColors = this.$config.space.userStatusColors;
    }

    this.statuses = Object.keys(statusLabels).map(el => ({
      id: el,
      title: statusLabels[el],
      color: statusColors[el]
    }))
  },
}
</script>

<style lang="scss" scoped>
.status-block {
  background-color: var(--v-gray-10-base);
  border-bottom: 2px solid;
}

.status-circle {
  .circle {
    width: 14px;
    height: 14px;
  }
}
</style>
