<template>
  <div v-if="file" class="relative h-full">
    <UiFileIcon v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file.original_name))" :extension="getFileName(file.original_name).ext" width="24"/>
    <img v-else :src="getFileThumbnails(file.thumbnails) || file.url" alt="" class="fill-width h-full">
    <v-tooltip top color="accent">
      <template v-slot:activator="{ on, attrs }">
        <UiBtn
          icon
          plain
          class="px-0 download-icon"
          v-bind="attrs"
          v-on="on"
          @click.stop="download(file)"
        >
          <IconDownload width="14" class="gray-60--text"/>
        </UiBtn>
      </template>
      Download file
    </v-tooltip>
  </div>
</template>

<script>
import {getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import {filesystemConfig as $config} from "@/config/_filesystemConfig";
export default {
  name: "UiUploadedFile",
  computed: {
    $config() {
      return $config
    }
  },
  components: {UiFileIcon, IconDownload, UiBtn},
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getFileName,
    getFileExtension,
    getFileThumbnails,
    async download(file) {
      if (!file) return;

      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || 'file';

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
    },
  },
}
</script>

<style scoped lang="scss">
.h-full {
  height: 100%;
}
img {
  object-fit: cover;
}
.download-icon {
  position: absolute;
  left: 3px;
  bottom: 4px;
  top: auto !important;
  right: auto !important;
  width: 15px;
  height: 15px;
}
</style>
