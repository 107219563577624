export default axios => ({

  /**
   * Get project tasks list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-GetApiV1ProjectsProject_idTasks
   * @param url
   * @param params
   * @returns {*}
   */
  list(url, params = {}) {
    return axios.get(url, {params});
  },

  /**
   * Get project tasks by id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-PostApiV1ProjectsProject_idTasksTask_id
   * @param project_id
   * @param task_id
   * @returns {*}
   */
  get(project_id, task_id) {
    return axios.get(`/projects/${project_id}/tasks/${task_id}`);
  },

  /**
   * Create project task
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-PostApiV1ProjectsProject_idTasks
   * @param project_id
   * @param data
   * @returns {*}
   */
  create(project_id, data) {
    return axios.post(`projects/${project_id}/tasks`, data);
  },

  /**
   * Update project task
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-PostApiV1ProjectsProject_idTasks
   * @param project_id
   * @param task_id
   * @param data
   * @returns {*}
   */
  update(project_id, task_id, data) {
    return axios.put(`projects/${project_id}/tasks/${task_id}`, data);
  },

  /**
   * Update project task status
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Tasks-PutApiV1ProjectsProject_idTasksTask_idChangeStatus
   * @param project_id
   * @param task_id
   * @param data
   * @returns {*}
   */
  updateStatus(project_id, task_id, data) {
    return axios.put(`projects/${project_id}/tasks/${task_id}/change-status`, data);
  },

  /**
   * Delete project task
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-PostApiV1ProjectsProject_idTasks
   * @param project_id
   * @param task_id
   * @returns {*}
   */
  delete(project_id, task_id) {
    return axios.delete(`projects/${project_id}/tasks/${task_id}`);
  },

  /**
   * Upload project task attachments
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Tasks-PutApiV1ProjectsProject_idTasksAttachment
   * @param project_id
   * @param data
   * @returns {*}
   */
  uploadAttachments(project_id, data) {
    return axios.post(`projects/${project_id}/tasks/attachment`, data);
  },


})
