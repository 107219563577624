export default {

  /**
   * @param commit
   * @param payload
   */
  openModal({commit}, payload) {
    commit('OPEN_MODAL', payload);
  },

  /**
   * @param commit
   * @param modalName
   */
  closeModal({commit}, modalName) {
    commit('CLOSE_MODAL', modalName);
  },

  /**
   * @param commit
   * @param payload
   */
  updateModalData({commit}, payload) {
    commit('UPDATE_MODAL_DATA', payload);
  },

  /**
   * @param commit
   * @param payload
   */
  updateModalHandlers({commit}, payload) {
    commit('UPDATE_MODAL_HANDLERS', payload);
  },
}
