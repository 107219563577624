<template>
  <div>
    <div
      v-bind="$attrs"
      v-on="$listeners"
      ref="chat_input"
      :class="['ui-mention-input', contentClass]"
      :contenteditable="!disabled"
      @tribute-replaced="editMessage"
      @keyup="editMessage"
      @paste="pasteHandler"
      tabindex="-1"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
import Tribute from "tributejs";
import {removeSpacesFromString, removeHTMLTagFromString} from "@/utils/helpers";

export default {
  name: 'UiMentionInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    },
    contentClass: {
      type: [String, Array],
      default: '',
    },
    menuClass: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Type something'
    },
    menuContainerSelector: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputVal: '',
      mentionsOptions: {
        trigger: "@",
        values: [],
        containerClass: `mention-list-menu ${this.$props.menuClass}`,
        positionMenu: !this.$props.menuContainerSelector,
        requireLeadingSpace: true,
        menuItemTemplate: item => {
          return (
            '<div class="mention-user-avatar">' + item.original.avatar + '</div>' +
            '<span>' + item.original.key + '</span>'
          );
        },
        selectTemplate: function (item) {
          return (
            '<span contenteditable="false" class="mention-username" data-user="' + item.original.id + '">@' + item.original.value + '</span>'
          );
        },
      }
    }
  },
  created() {
    this.mentionsOptions.values = this.$props.users.map(user => ({
      id: user.user.id,
      key: `${user.user.first_name} ${user.user.last_name || ''}`,
      value: `${removeSpacesFromString(user.user.first_name)}${removeSpacesFromString(user.user.last_name)}`.toLowerCase().trim(),
      avatar: user.user.avatar !== null ? `<img src="${user.user.avatar.url}">` : `<div>${user.user.first_name.split('')[0].toUpperCase()}</div>`
    }));
  },
  mounted() {
    this.$refs.chat_input.innerHTML = this.value.trim();

    if (this.$props.menuContainerSelector) {
      this.mentionsOptions.menuContainer = document.querySelector(this.$props.menuContainerSelector);
    }

    this.mentionsInstance = new Tribute(this.mentionsOptions);
    this.mentionsInstance.attach(this.$refs.chat_input);

    this.$nextTick(() => {
      this.inputVal = this.$refs.chat_input.textContent;
    });
  },
  methods: {
    callMention() {
      const lastCharCode = this.$refs.chat_input.textContent.charCodeAt(this.$refs.chat_input.textContent.length - 1);

      if (lastCharCode !== 160) { // if there are no spaces we must add it
        this.$refs.chat_input.textContent += ' ';
      }

      this.mentionsInstance.showMenuForCollection(this.$refs.chat_input);
    },
    clearInput() {
      this.$refs.chat_input.textContent = "";
    },
    getFormattedMessageData() {
      const mentionUsers = [...this.$refs.chat_input.querySelectorAll('.mention-username')].map(item => {
        return {
          id: +item.getAttribute('data-user'),
          slug: item.textContent,
          userName: item.textContent.substring(1),
        }
      });
      this.inputVal = removeHTMLTagFromString(this.inputVal); // remove all html tags

      if (mentionUsers.length) {
        this.inputVal = this.inputVal.replace(/\u00a0/g, " ").split(' ').map(word => {
          const user = mentionUsers.find(user => user.slug === word);
          if (user) {
            return `user_id=${user.id}`
          }
          return word;
        }).join(' ');
      }

      // this.inputVal = removeHTMLTagFromString(this.inputVal); // remove all html tags

      return {
        message: this.inputVal.trim(),
        users: mentionUsers
      }
    },
    editMessage(e) {

      this.mentionsInstance.allowSpaces = !!(this.mentionsInstance.current && this.mentionsInstance.current?.mentionText?.length);

      if ((e.key === " " || e.code === "Space") && this.mentionsInstance.isActive) {
        this.mentionsInstance.selectItemAtIndex(this.mentionsInstance.menuSelected)
        this.mentionsInstance.hideMenu()
      }

      this.inputVal = this.$refs.chat_input.innerHTML.replaceAll('<div>', '<br>').replaceAll('</div>', '').replaceAll('<br>', '\n');
      this.$emit('editMessage', this.inputVal);
    },
    pasteHandler(event) {
      // For some reason the copied text is pasted with html tags, so we must remove them before pasting

      event.stopPropagation();
      event.preventDefault();

      const clipboardData = event.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData('text').trim().replace(/\r?\n|\r/g, " ");

      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, pastedData);
      } else {
        document.execCommand('paste', false, pastedData);
      }
    },
    setValue(value) {
      this.$refs.chat_input.innerHTML = value.trim();
      this.$nextTick(() => {
        this.inputVal = this.$refs.chat_input.textContent;
      });
    },
    focus(){

      this.$refs.chat_input.focus();

      const range = document.createRange();//Create a range (a range is a like the selection but invisible)
      range.selectNodeContents(this.$refs.chat_input);//Select the entire contents of the element with the range
      range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
      const selection = window.getSelection();//get the selection object (allows you to change selection)
      selection.removeAllRanges();//remove any selections already made
      selection.addRange(range);//make the range you have just created the visible selection
    },
  },
}
</script>

<style scoped lang="scss">
.ui-mention-input {
  outline: none;
  word-break: break-word;

  &:empty::before {
    content: attr(placeholder);
    display: block;
    color: #666;
    cursor: text;
  }
}
</style>
