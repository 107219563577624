<template>
  <v-layout column>
    <AdminMainHeader v-if="getAdminToken"/>
    <AdminSideMenu v-if="getAdminToken"/>
    <v-main>
      <router-view :key="$route.params.id"/>
    </v-main>
  </v-layout>
</template>

<script>
import AdminMainHeader from "@/components/layout/AdminMainHeader.vue";
import {mapGetters} from "vuex";
import AdminSideMenu from "@/components/layout/AdminSideMenu.vue";

export default {
  name: 'AdminLayout',
  components: {AdminSideMenu, AdminMainHeader},
  computed: {
    ...mapGetters(['getAdminToken'])
  }
}
</script>
