import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
  projectsLoading: false,
  projects: [],
  filters: {
    category: [],
    status: [],
    workflow_id: [],
  },
  userColumnSettings: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
