import {defaultSideDrawerFilters} from "./index";

export default {

  /**
   *
   * @param state
   * @returns Boolean
   */
  getProjectSideDrawer(state) {
    return state;
  },

  /**
   *
   * @param state
   * @returns Boolean
   */
  showProjectSideDrawer(state) {
    return state.showProjectSideDrawer;
  },

  /**
   *
   * @returns {*}
   */
  getProjectSideDrawerDefaultFilters() {
    return defaultSideDrawerFilters();
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectSideDrawerFilters(state) {
    return state.filters;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectSideDrawerActivities(state) {
    return state.activities;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectSideDrawerTasks(state) {
    return state.tasks;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getActiveTask(state) {
    return state.tasks.activeTask;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectSideDrawerDesignFiles(state) {
    return state.files;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectSideDrawerLogHistory(state) {
    return state.logs;
  },

}
