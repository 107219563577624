export default {
  getSpaces (state) {
    return [...state.spaces].sort((a, b) => a.title.localeCompare(b.title))
  },
  getActiveSpace(state) {
    return state.activeSpace
  },
  getSpaceById(state) {
    return id => {
      return state.spaces.find(space => space.id === +id) || null
    }
  }
}
