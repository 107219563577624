<template>
  <v-layout column>
    <AppMenu/>
    <v-main>
      <router-view/>
    </v-main>
    <AppFooter/>
  </v-layout>
</template>

<script>
import AppMenu from "@/components/landings/AppMenu";
import AppFooter from "@/components/landings/AppFooter";

export default {
  name: 'LandingLayout',
  components: {
    AppMenu,
    AppFooter
  }
}
</script>
