<template>
  <img v-bind="$attrs"
       v-on="$listeners"
       :src="require(`@/assets/images/${$vuetify.theme.dark ? $config.theme.dark : $config.theme.light}/${namePath}`)"
       alt="">
</template>

<script>
export default {
  name: 'UIImage',
  props: {
    namePath: {
      type: String,
      default: '',
    }
  },
}
</script>
