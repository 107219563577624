<template>
  <ValidationObserver tag="div" ref="form">
    <ValidationProvider
      mode="eager"
      tag="div"
      name="name"
      vid="name"
      :rules="rules"
      v-slot="{ errors }"
    >
      <v-text-field
        v-bind="$attrs"
        v-on="$listeners"
        dense
        outlined
        autofocus
        hide-details
        @blur="onBlur"
        @keyup.esc="$emit('onClose')"
        @keyup.enter="updateName"
        @click.stop
        class="rename-input"
      >
        <template #append>
          <div class="ml-2 cursor-pointer" @click.stop="updateName">
            <IconSave width="14"/>
          </div>
        </template>
      </v-text-field>
      <div class="rename-input-error text-captions-1 error--text" v-if="errors.length">
        {{ errors[0] | capitalize }}
      </div>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'UiChangeNameInput',
  components: {
    IconSave: () => import('@/components/icons/IconSave'),
  },
  props: {
    file: {
      type: Object,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
    saveOnBlur: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    updateName() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.$emit('onSave');
      })
    },
    onBlur() {
      if (this.saveOnBlur) {
        this.updateName();
      } else {
        this.$emit('onClose')
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .rename-input {
  .v-input__slot {
    padding: 0 8px!important;
  }
  .v-input__append-inner {
    margin-top: 6px !important;
  }
}
</style>
