<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :disabled="disabled" depressed class="ui-btn" :ripple="ripple">
    <slot/>
    <template #loader>
      <v-progress-circular indeterminate size="15" width="2"/>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: "UiBtn",
  inheritAttrs: false,
  props: {
    ripple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss">
.v-application--is-ltr {
  .v-btn__content {
    .v-icon--left {
      margin-left: 0 !important;
    }
    .v-icon--right{
      margin-right: 0 !important;
    }
  }
}
</style>
<style scoped lang="scss">
::v-deep {
  &.ui-btn{
    &.theme--light.v-btn--disabled{
      color: var(--v-gray-50-base) !important;
      .v-btn__loading{
        color: var(--v-gray-60-base) !important;
      }
      .v-btn--has-bg{
        background-color: var(--v-gray-50-base) !important;
        color: var(--v-gray-60-base) !important;
      }
    }
    &.accent:not(.v-btn--outlined){
      transition-property: background-color, color, opacity !important;
      &:before{
        opacity: 0 !important;
      }
      &:hover{
        background-color: var(--v-accent-60-base) !important;
      }
      &:active, &[aria-expanded=true]{
        background-color: var(--v-accent-100-base) !important;
      }
    }
    &.accent--text.v-btn--outlined{
      &:before{
        background-color: var(--v-accent-10-base) !important;
      }
      &:hover{
        &:before {
          opacity: 1 !important;
        }
      }
      &:active{
        color: var(--v-accent-100-base) !important;
        &:before{
          opacity: 0;
        }
      }
    }
    &.v-btn--icon.v-size--default .v-icon, &.v-btn--fab.v-size--default .v-icon{
      width: 1em;
      height: 1em;
    }
    &.v-btn--plain {
      .v-btn__content {
        opacity: 1 !important;
      }
    }
    .v-btn__content {
      .v-icon {
        font-size: 15px;
        width: 1em;
        height: 1em;
      }
    }
  }
}
</style>
