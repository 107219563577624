import config from '../../../config';

export default {

  /**
   * @param commit
   * @param payload
   */
  setProject({ commit, rootState }, payload) {
    commit('SET_PROJECT', payload)
    commit('SET_ACTIVE_SPACE', payload?.space_id || null)

    if(payload?.folder_id) {
      const activeFolder = rootState.Spaces.spaces.reduce((acc, el) => {
        const folder = el.folders.find(f => f.id.toString() === payload.folder_id.toString())
        if(folder) {
          acc = folder
        }
        return acc
      }, null)

      commit('SET_ACTIVE_FOLDER', activeFolder)
    }

  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateProject({ commit }, payload) {
    commit('UPDATE_PROJECT', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setDefaultProjectData({ commit }) {
    commit('SET_DEFAULT_PROJECT_DATA')
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setTemplates ({ commit }, payload) {
    commit('SET_TEMPLATES', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setCategories({ commit }, payload) {
    commit('SET_CATEGORIES', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setUserTags({ commit }, payload) {
    commit('SET_USER_TAGS', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setDesignModules({commit}, payload) {
    const modules = payload.filter(module => module.type !== config.project.moduleTypes.moodBoard);
    commit('SET_DESIGN_MODULES', modules);
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateDesignModule({commit}, payload) {
    commit('UPDATE_DESIGN_MODULE', payload);
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setPermissions({ commit }, payload) {
    const permissions = payload.reduce((acc, project) => {
      const newProjectObj = Object.create({});
      newProjectObj.project_id = project.project_id;
      newProjectObj.roles = Object.keys(project.roles);

      newProjectObj.availableModules = [];
      newProjectObj.roles.forEach(role => {
        newProjectObj.availableModules.push(...config.project.userRoleByDesignModules[role]);
      });

      newProjectObj.roles.forEach(role => {
        if (!newProjectObj.permissions) {
          newProjectObj.permissions = project.roles[role].permissions;
        } else {
          const permissionsGroupsKeys = Object.keys(project.roles[role].permissions);
          permissionsGroupsKeys.forEach(groupKey => {
            const itemKeys = Object.keys(newProjectObj.permissions[groupKey]);
            itemKeys.forEach(itemKey => {
              newProjectObj.permissions[groupKey][itemKey] = newProjectObj.permissions[groupKey][itemKey] || project.roles[role].permissions[groupKey][itemKey]
            });
          });
        }
      });
      acc.push(newProjectObj);
      return acc;
    }, []);
    commit('SET_PERMISSIONS', permissions);
  },
  /**
   * Saved Project Params
   * @returns {string}
   */
  async getProjectsTagsList() {
    let tagsStorage = localStorage.getItem('tagsList')
    if(tagsStorage) {
      try {
        const parsedValue = JSON.parse(tagsStorage)
        tagsStorage = typeof parsedValue === "object" && !Array.isArray(parsedValue) ? parsedValue : {}
      } catch {
        tagsStorage = {}
      }
    }
    return tagsStorage
  }
}
