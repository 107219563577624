export default {

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_DASHBOARD_PROJECTS_LOADING(state, payload) {
    state.projectsLoading = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PROJECTS(state, payload) {
    state.projects = payload
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_DASHBOARD_PROJECT(state, payload) {
    if (!payload.id) {
      throw new Error('"UPDATE_DASHBOARD_PROJECT" - project id is required');
    }
    state.projects = state.projects.map(project => project.id === payload.id ? {...project, ...payload} : project);
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_DASHBOARD_PROJECT_FOLDER(state, payload) {
    if (!payload.id) {
      throw new Error('"UPDATE_DASHBOARD_PROJECT_FOLDER" - folder id is required');
    }
    state.projects = state.projects.map(project => project.folder_id === payload.id ? {...project, checked: payload.checked} : project);
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_DASHBOARD_FILTERS(state, payload) {
    state.filters = {...state.filters, ...payload};
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_USER_COLUMN_SETTINGS(state, payload) {
    state.userColumnSettings = payload
  }
}
