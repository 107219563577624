import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export const defaultSideDrawerFilters = () => ({
  project_tag_id: null,
  project_style_id: null,
  assignee: [],
  priority: null,
  status: null,
  owner: [],
  file_type: [],
  event_type: null,
  search: '',
  order: 'asc',
  project_module: null
});

const state = {
  showProjectSideDrawer: false,
  isSideDrawerPermanent: false,
  initialRender: true,
  tabs: null,
  activities: {
    data: [],
    total: 0,
  },
  tasks: {
    data: [],
    total: 0,
    nextLink: null,
    activeTask: null
  },
  files: {
    data: [],
    total: 0,
    nextLink: null,
  },
  logs: {
    data: [],
    total: 0,
    nextLink: null,
  },
  filters: {
    ...defaultSideDrawerFilters(),
  },
  filtersByTab: {
    activities: ['search', 'project_tag_id', 'project_style_id', 'order', 'project_module'],
    tasks: ['search', 'project_tag_id', 'project_style_id', 'assignee', 'priority', 'status', 'project_module'],
    files: ['search', 'project_tag_id', 'project_style_id', 'order', 'owner', 'file_type', 'project_module'],
    logs: ['search', 'project_tag_id', 'project_style_id', 'order', 'event_type', 'assignee', 'project_module'],
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
