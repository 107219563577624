<template>
  <div>
    <UiMenu
      offset-y
      allow-overflow
      :nudge-bottom="$vuetify.breakpoint.xsOnly ? 0 : 10"
      :min-width="minWidth"
      v-model="menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <UiBtn
          :color="isActive || menu ? 'accent' : 'gray-60'"
          icon width="auto"
          class="px-2"
          :plain="isActive || menu"
          v-bind="attrs"
          v-on="on"
        >
          <slot name="icon" :active="isActive" :menu="menu">
            <IconSort width="15" class="icon-transition"/>
          </slot>
          <slot name="sub_icon" :active="isActive" :menu="menu">
            <IconCaretDown v-if="isActive || menu" width="6" :class="['ml-2 icon-transition', {'rotate-180': menu}]"/>
          </slot>
        </UiBtn>
      </template>

      <v-list nav dense color="gray-10">
        <v-list-item-group
          v-model="selected1"
          :mandatory="mandatory1"
        >
          <template v-for="(item, i) in list">
            <v-hover v-slot="{hover}" :key="`item-${i}`">
              <v-list-item
                :value="item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action class="ma-0">
                    <UiCheckbox
                      :input-value="active"
                      color="accent-100"
                      :hovered="hover"
                      checkbox-form="radio"
                    />
                  </v-list-item-action>
                  <v-list-item-content class="py-1">
                    <v-list-item-title v-text="item.name"/>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-hover>

          </template>
        </v-list-item-group>

        <template v-if="list2.length">
          <v-divider class="my-1"/>
          <v-list-item-group
            v-model="selected2"
            :mandatory="mandatory2"
          >
            <template v-for="(item, i) in list2">
              <v-hover v-slot="{hover}" :key="`item-${i}`">
                <v-list-item
                  :value="item"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action class="ma-0">
                      <UiCheckbox
                        :input-value="active"
                        color="accent-100"
                        :hovered="hover"
                        checkbox-form="radio"
                      />
                    </v-list-item-action>
                    <v-list-item-content class="py-1">
                      <v-list-item-title v-text="item.name"/>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-hover>
            </template>
          </v-list-item-group>
        </template>
      </v-list>
      <slot name="bottom-action" />
    </UiMenu>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UiCheckbox from "@/components/UI/UiCheckbox";

export default {
  name: 'UiSort',
  components: {
    IconSort: () => import('@/components/icons/IconSort'),
    IconCaretDown: () => import('@/components/icons/IconCaretDown'),

    UiCheckbox,
    UiMenu,
    UiBtn
  },
  props: {
    list: {
      type: Array,
      // example data
      default: () => [
        {
          name: 'Example',
          slug: 'example'
        }
      ]
    },
    list2: {
      type: Array,
      default: () => []
    },
    value1: {
      type: Object,
      default: () => {
      }
    },
    value2: {
      type: Object,
      default: () => {
      }
    },
    mandatory1: {
      type: Boolean,
      default: false
    },
    mandatory2: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: [Number, String],
      default: 136,
    }
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    isActive() {
      return !!this.selected1 || !!this.selected2;
    },
    selected1: {
      get() {
        return this.value1
      },
      set(v) {
        this.$emit('setSort1', v)
      }
    },
    selected2: {
      get() {
        return this.value2
      },
      set(v) {
        this.$emit('setSort2', v)
      }
    }
  },
  methods: {
    selectItem(v, event) {
      this.$emit(event, v)
    },
  }
}
</script>
