<template>
  <UITextModal modal-name="fileUploadErrorModal" max-width="500" @hideModal="getModal('fileUploadErrorModal').handlers.onClose()">
    <div class="py-12 px-6 px-sm-12 text-center">
      <h3 class="mb-6 font-weight-semi-bold text-header">Error</h3>

      <p v-if="getModalData.type === 'quantityError'" class="text-body">
        File amount limit reached. <br>
        You can upload up to {{ getModalData.maxFilesQuantity }}
        {{ getModalData.maxFilesQuantity == '1' ? 'file' : 'files'}}.
      </p>
      <p v-if="getModalData.type === 'limitError'" class="text-body">
        File amount limit reached. <br>
        Only 1 - 3d CAD file can be uploaded
      </p>

      <p
        v-if="getModalData.filesNames.length"
        class="text-body"
        style="word-break: break-word">
        Sorry, <strong>{{ getModalData.filesNames.join(', ') }}</strong> {{ getModalData.filesNames.length > 1 ? 'files are' : 'file is' }}
        not allowed by size or format.
        <br>
        <br>
        File size should not exceed {{ getModalData.maxFileSizeLabel }}.
        <template v-if="getModalData.allowedFilesFormats">Allowed file formats are {{ getModalData.allowedFilesFormats }}</template>
      </p>

    </div>
  </UITextModal>
</template>

<script>
import { mapGetters } from "vuex";
import UITextModal from "../UI/UITextModal";

export default {
  name: 'FileUploadErrorModal',
  components: {
    UITextModal,
  },
  computed: {
    ...mapGetters([
      'getModal',
    ]),
    getModalData() {
      return this.getModal('fileUploadErrorModal').data;
    }
  },
}
</script>
