import {defaultSideDrawerFilters} from "./index";

export default {

  /**
   *
   * @param state
   * @param payload
   */
  TOGGLE_PROJECT_SIDE_DRAWER(state, payload) {
    state.showProjectSideDrawer = payload;
  },

  /**
   *
   * @param state
   * @param payload
   */
  SET_PROJECT_SIDE_DRAWER_TAB_DATA(state, payload) {
    state[payload.tab] = {...state[payload.tab], ...payload};
  },

  /**
   *
   * @param state
   * @param payload
   */
  SET_PROJECT_SIDE_DRAWER_FILTERS(state, payload) {
    state.filters = {...state.filters, ...payload};
  },

  /**
   *
   * @param state
   */
  CLEAR_PROJECT_SIDE_DRAWER_FILTERS(state) {
    state.filters = {...defaultSideDrawerFilters()};
  },

  /**
   *
   * @param state
   */
  CLEAR_PROJECT_SIDE_DRAWER_DATA(state) {
    state.initialRender = true;
    state.activities = {
      data: [],
      total: 0,
    };
    state.tasks = {
      data: [],
      total: 0,
      nextLink: null,
      activeTask: null
    };
    state.files = {
      data: [],
      total: 0,
      nextLink: null,
    };
    state.logs = {
      data: [],
      total: 0,
      nextLink: null,
    };
    state.filters = {...defaultSideDrawerFilters()};
  },

  /**
   *
   * @param state
   * @param payload
   */
  SET_ACTIVE_TASK_IN_TASK_TAB(state, payload) {
    state.tasks.activeTask = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  SET_ACTIVE_TAB(state, payload) {
    state.tabs = payload
  },

}
