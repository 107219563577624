export default {

  /**
   *
   * @param state
   * @returns {{template: number, id: null, title: string, category: null, modules: *[], status: number, tags: *[]}}
   */
  getProject (state) {
    return state.project
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  getTemplates (state) {
    return state.templates
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  getCategories(state) {
   return state.categories
  },

  /**
   *
   * @param state
   * @returns [{*}]
   */
  getDesignModules(state) {
    return state.designModules
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getPermission(state) {
    return project_id => state.permissions.find(project => project.project_id === parseInt(project_id, 10))?.permissions
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectPermissionData(state) {
    return project_id => state.permissions.find(project => project.project_id === parseInt(project_id, 10))
  },
}
