<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 45" fill="white">
    <path
      d="M28.5714 1H12.8571C11.2792 1 10 2.27918 10 3.85714V41C10 42.578 11.2792 43.8571 12.8571 43.8571H37.1429C38.7208 43.8571 40 42.578 40 41V12.4286M28.5714 1L40 12.4286M28.5714 1V9.57143C28.5714 11.1494 29.8506 12.4286 31.4286 12.4286H40"
      stroke="#81848B" stroke-width="1.42857"/>
    <rect ref="rect" x="0.714286" y="14.7143" width="27.1429" height="15.7143" rx="2.14286" fill="white"
          stroke="#81848B" stroke-width="1.42857"/>
    <text ref="text" fill="#81848B" font-family="Inter" y="23.5" font-size="10px"
          font-weight="500" letter-spacing="-0.01em" dominant-baseline="middle" text-anchor="middle">{{
        extension
      }}</text>
    <path d="M33.571 33.8572H32.1424V37.4286H30.7139L32.8567 39.5715L34.9996 37.4286H33.571V33.8572Z" fill="#7B61FF"
          stroke="#7B61FF" stroke-width="1.42857"/>
  </svg>
</template>

<script>
export default {
  name: 'UiFileIcon',
  props: {
    extension: {
      type: String,
      required: true
    }
  },
  mounted() {
      setTimeout(() => {
        if(!this.$refs.rect) return
        const scaleCoeff = 0.68
        const rectWidth = this.$refs.rect.getBBox().width
        const textWidth = this.$refs.text.getBBox().width;

        this.$refs.text.setAttribute('x', (this.$refs.rect.getBBox().x + rectWidth / 2).toString())

        const scale = (rectWidth) / (textWidth / scaleCoeff)

        this.$refs.text.style.transform = `scale(${Math.min(1, scale)})`
        this.$refs.text.style.transformOrigin = '14px center'
      }, 0)
  }
}
</script>
