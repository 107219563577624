<template>
  <v-dialog
    v-model="getModal('editModuleAccessModal').isOpen"
    max-width="686"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
  >
    <div class="gray-0 pt-13 px-4 sm:px-7 pb-6">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Sharing</h5>
        <UiBtn fab color="gray-0" @click="hideModal">
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <v-divider class="mt-5 mb-8"/>

      <div class="relative">
        <v-flex
          v-if="loading"
          class="absolute loading-wrapper white z-10 d-flex justify-center align-center"
        >
          <v-progress-circular :size="100" color="accent" indeterminate/>
        </v-flex>
        <div class="mb-4 text-body font-weight-light gray-100--text">
          Module selected for sharing:
        </div>
        <v-card class="card relative overflow-hidden mb-4" flat>
          <div
            class="white p-1 d-flex align-center justify-center module-title"
          >
            <UiProjectModuleIcon
              width="14"
              :icon="getModal('editModuleAccessModal').data.module.icon"
              class="module-icon mr-2 flex-shrink-0"
            />

            <span
              class="text-no-wrap text-overflow-ellipsis overflow-hidden gray-60--text"
            >
              {{ getModal("editModuleAccessModal").data.module.title }}
            </span>
          </div>
          <v-responsive :aspect-ratio="636 / 119">
            <div
              class="card__image fill-height absolute fill-width inset-0 d-flex"
            >
              <div
                class="card-image d-flex align-center justify-center flex-grow-1"
              >

                <UIImage
                  class="fill-width fill-height object-cover"
                  v-if="!modulePreview"
                  :name-path="`default-img.png`"
                />
                <img
                  v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(modulePreview.items[0].file.original_name))"
                  :src="modulePreview.items[0].file.url" alt="" class="fill-width fill-height object-cover">
                <UiFileIcon v-else width="30" :extension="getFileName(modulePreview.items[0].file.original_name).ext"
                            :class="{
                    'mt-n5':
                      getModal('editModuleAccessModal').data.project.status !==
                      null,
                  }"/>
              </div>

              <div class="card__image__status absolute fill-width">
                <UiProjectStatus
                  v-if="
                    getModal('editModuleAccessModal').data.project.status !==
                    null
                  "
                  :id="getModal('editModuleAccessModal').data.project.status"
                  block
                />
              </div>
            </div>
          </v-responsive>
          <div class="card__bottom pa-3">
            <div
              class="card-bottom__top d-flex align-center justify-space-between mb-1"
            >
              <h6 class="card-bottom__title font-weight-semi-bold">
                {{ getModal("editModuleAccessModal").data.project.title }}
              </h6>
              <div>
                <template
                  v-for="(member, idx) in getModal('editModuleAccessModal').data
                    .project.team"
                >
                  <v-tooltip color="accent" top :key="idx">
                    <template v-slot:activator="{ on, attrs }">
                      <UiAvatar
                        v-if="idx <= 1"
                        v-bind="attrs" v-on="on"
                        :key="idx"
                        size="22"
                        :text="member.user.first_name"
                        :src="(member.user.avatar && member.user.avatar.url) || ''"
                        class="card-bottom__actions__avatar mr-2"
                      />
                    </template>
                    <span>{{ member.user.first_name }} {{ member.user.last_name }}</span>
                  </v-tooltip>
                </template>
                <UiAvatar
                  v-if="
                    getModal('editModuleAccessModal').data.project
                      .total_users &&
                    getModal('editModuleAccessModal').data.project.total_users >
                      2
                  "
                  size="22"
                  :text="`${
                    getModal('editModuleAccessModal').data.project.total_users -
                    2
                  }`"
                  :src="null"
                />
              </div>
            </div>
            <div
              class="card-bottom__content d-flex justify-space-between flex-wrap"
            >
              <div class="card-bottom__content__left">
                Updated:
                {{
                  timeDistance(
                    getModal("editModuleAccessModal").data.project.updated_at
                  )
                }}
              </div>
              <div class="card-bottom__content__right">
                <span>Due:</span>
                <span class="dark"
                >{{
                    dueDaysLeft(
                      getModal("editModuleAccessModal").data.project.due_date
                    )
                  }}
                  {{
                    dueDaysLeft(
                      getModal("editModuleAccessModal").data.project.due_date
                    ) === 1
                      ? "day"
                      : "days"
                  }}</span
                >
              </div>
            </div>
          </div>
        </v-card>

        <SelectUsers
          :users="projectTeams"
          @onChange="setReviewers"
          class="mb-5"
        />

        <ValidationProvider
          class="mb-2"
          mode="eager"
          tag="div"
          name="note"
          vid="note"
          rules="max:128"
          v-slot="{ errors }"
        >
          <div class="mb-2 text-body font-weight-light gray-100--text">
            Notes (Optional)
          </div>
          <v-text-field
            v-model="note"
            dense
            height="35"
            outlined
            hide-details
            placeholder="Type description here..."
            :error="!!errors.length"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">
            {{ errors[0] | capitalize }}
          </div>
        </ValidationProvider>

        <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
          <UiBtn
            outlined
            width="117"
            color="accent"
            @click="hideModal"
            class="mt-4"
          >
            Cancel
          </UiBtn>
          <UiBtn
            width="117"
            color="accent"
            :disabled="disabledButton"
            @click="requestReview"
            class="mt-4"
          >
            Share
          </UiBtn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import SelectUsers from "@/components/common/SelectUsers";
import UIImage from "@/components/UI/UIImage.vue";
import UiAvatar from "@/components/UI/UiAvatar.vue";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import UiProjectStatus from "@/components/UI/UiProjectStatus.vue";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon.vue";
import {getFileExtension, getFileName} from "@/utils/helpers";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: "EditModuleAccessModal",
  components: {
    UiFileIcon,
    UiProjectModuleIcon,
    UiProjectStatus,
    UiAvatar,
    UIImage,
    IconCancel: () => import("@/components/icons/IconCancel"),
    UiBtn,
    SelectUsers,
  },
  props: {
    activeModuleId: {
      type: Number,
    },
  },
  data() {
    return {
      note: "",
      projectTeams: [],
      projectDesigns: [],
      selectedUsers: [],
      loading: false,
      reviewers: {
        existingUsers: [],
        invitedUsers: [],
      },
      sortList1: [
        {name: "Name", slug: "title"},
        {name: "Modified", slug: "updated_at"},
      ],
      sortList2: [
        {name: "Ascending", slug: "asc"},
        {name: "Descending", slug: "desc"},
      ],
      sort_1: {name: "Modified", slug: "updated_at"},
      sort_2: {name: "Ascending", slug: "asc"},
    };
  },
  computed: {
    ...mapGetters(["getProject", "getModal"]),
    reviewedFiles() {
      return this.getModal("editModuleAccessModal").data.files;
    },
    moduleId() {
      return this.getModal("editModuleAccessModal").data.module.id;
    },
    modulePreview() {
      return this.projectDesigns?.filter((item) => item.items.length)?.[0];
    },
    isXlsFile() {
      return this.$config.filesystem.fileTypes.xls.includes(getFileExtension(this.modulePreview.items[0].file.original_name));
    },
    isPdfFile() {
      return this.$config.filesystem.fileTypes.pdf.includes(getFileExtension(this.modulePreview.items[0].file.original_name));
    },
    disabledButton() {
      const users = this.reviewers.existingUsers.filter(
        (member) => member.selected
      );
      const emails = this.reviewers.invitedUsers.filter(
        (member) => member.selected
      );
      return Boolean([...users, ...emails].length <= 0 && !this.selectedUsers.length);
    },
  },
  mounted() {
    this.getSharedWithList();
  },
  methods: {
    getFileExtension,
    getFileName,
    async getSharedWithList() {
      this.loading = true;
      try {
        const {tags, sort_1, sort_2} = this.$route.query;
        if (sort_1 !== "" && sort_1 !== undefined) {
          this.sort_1 = this.sortList1.find((sort) => sort.slug === sort_1);
        }

        if (sort_2 !== "" && sort_2 !== undefined) {
          this.sort_2 = this.sortList2.find((sort) => sort.slug === sort_2);
        }
        const params = {
          tags: !tags
            ? this.getModal("editModuleAccessModal").data.project.tag[0] ? [this.getModal("editModuleAccessModal").data.project.tag[0].id]: []
            : tags.split(",").map((item) => +item),
          sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
        };
        const [res, projectDesigns] = await Promise.all([
          this.$api.project.getModuleUsers(this.moduleId),
          this.$api.projectModuleDesign.list(
            this.$route.params.project_id || this.getModal("editModuleAccessModal").data.project.id,
            this.moduleId,
            params
          ),
        ]);
        this.projectDesigns = projectDesigns.data;
        this.selectedUsers = res.data
        const {projectManager, projectOwner} = this.$config.project.userRole;
        this.projectTeams = this.getProject.team.reduce((acc, user) => {
          const isPMorPO = user.user_role.some(
            (role) => role === projectManager || role === projectOwner
          );
          const selected = res.data.find(
            (member) => member.id === user.user_id
          );

          const member = {
            ...user,
            ...user.user,
            text: `${user.user.first_name || ""} ${user.user.last_name || ""}`,
            active: selected, // always select projectManager and projectOwner by default
            selected: selected, // always select projectManager and projectOwner by default
            permanent: isPMorPO,
          };
          if (!isPMorPO) {
            acc.push(member);
          }

          if (selected || isPMorPO) {
            this.reviewers.existingUsers.push(member);
          }
          return acc;
        }, []);
      } catch (error) {
        console.error(error);
        this.hideModal();
      } finally {
        this.loading = false;
        const project = await this.$api.project.get(this.getProject.id);
        this.$store.dispatch("setProject", project.data);
        this.$store.dispatch("setDesignModules", project.data.modules);
      }
    },
    setReviewers(users) {
      this.reviewers = users;
    },
    timeDistance(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), "MM-dd-yyyy");
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace("about ", "");
      }
    },
    dueDaysLeft(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      return differenceInDays(new Date(date), now);
    },
    async requestReview() {
      if (this.disabledButton) return;
      this.loading = true;
      const users = this.reviewers.existingUsers
        .filter((member) => member.selected)
        .map((member) => member.email);
      const emails = this.reviewers.invitedUsers
        .filter((member) => member.selected)
        .map((member) => member.email);

      try {
        await this.$api.project.shareProjectAndModule(this.moduleId, {
          emails: [...users, ...emails],
          notes: this.note
        });
        this.$toast.open({
          message: "Success! Module shared",
          type: "success",
          position: "top-right",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = true;
        this.hideModal();
      }
    },
    hideModal() {
      this.$emit("hideModal");
      this.$store.dispatch("closeModal", "editModuleAccessModal");
      this.note = "";
    },
  },
};
</script>

<style scoped lang="scss">
.border {
  border: 1px solid var(--v-gray-30-base);
}

.selected-review-file {
  width: 174px;

  button {
    top: -8px;
    right: -10px;
    border-radius: 50%;
    background-color: var(--v-gray-0-base);
  }
}

.card {
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25) !important;

  &.selected {
    border-color: var(--v-accent-60-base);
  }

  &__header {
    z-index: 1;
    top: 0;
  }

  &__image {
    &__status {
      bottom: 0;
    }
  }

  &__hover {
    top: 0;
    left: 0;
    z-index: 0;

    display: flex;

    &::before {
      content: "";
      background: var(--v-gray-60-base);
      opacity: 0.75;
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 2px;
      z-index: -1;
    }
  }
}

.card-image {
  background: var(--v-gray-30-base);

  img {
    object-fit: cover;
  }
}

.card-bottom {
  &__title {
    font-size: 14px;
    color: var(--v-gray-100-base);
  }

  &__content {
    font-size: 12px;
    color: var(--v-gray-60-base);

    &__right {
      color: var(--v-gray-80-base);

      .dark {
        color: var(--v-gray-100-base);
      }
    }
  }

  &__actions {
    &__avatar {
      border-color: var(--v-accent-base) !important;

      &:nth-child(2) {
        border-color: var(--v-accent-60-base) !important;
      }
    }
  }
}

.module-title {
  font-size: 12px;
  height: 30px;
  border-bottom: 2px solid var(--v-accent-base) !important;
}

.module-icon {
  color: var(--v-gray-60-base);
}

.loading-wrapper {
  left: -16px;
  right: -16px;
  width: calc(100% + 32px);
  min-width: calc(100% + 32px);
  top: 0;
  bottom: 0;
}
</style>
