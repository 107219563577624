<template>
  <v-app-bar app flat color="gray-0" height="64">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown"
      @click="$store.dispatch('toggleSideMenu')"
      class="mr-2"
    />
    <v-spacer/>
    <v-menu
      offset-y
      left
      bottom
      nudge-bottom="8"
      min-width="222"
      content-class="user-menu">
      <template v-slot:activator="{ on }">
        <ui-btn v-on="on" icon width="24" color="gray-100" height="24" class="ml-3 rounded-circle" ripple>
          <ui-avatar size="24" text="Admin"/>
        </ui-btn>
      </template>

      <div class="d-flex flex-column align-center px-3 pt-6 pb-4 text-center">
        <ui-avatar size="40" text-class="text-body" text="Admin"/>

        <strong class="mt-3 text-capitalize text-body font-weight-semi-bold gray-100--text">
          Admin
        </strong>

        <span class="mt-1 text-captions-1 gray-60--text">
          Admin
        </span>
      </div>

      <v-divider/>

      <v-list dense nav color="gray-0">
        <v-list-item @click="logoutAdmin" class="rounded overflow-hidden">
          <v-list-item-title class="d-flex align-center text-captions-1 font-weight-semi-bold gray-80--text">
            <SignOutIcon width="16" class="mr-3"/>
            Sign Out
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapActions} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiAvatar from "@/components/UI/UiAvatar";

export default {
  name: "AdminMainHeader",
  components: {
    SignOutIcon: () => import('@/components/icons/IconSignOut'),

    UiAvatar,
    UiBtn,
  },
  methods: {
    ...mapActions(['logoutAdmin']
    ),
  }
}
</script>

<style scoped lang="scss">
.user-menu {
  border: 1px solid var(--v-gray-30-base);
  border-radius: 5px;
  box-shadow: 0 4px 31px rgba(0, 0, 0, 0.1);
  background-color: var(--v-gray-0-base);

  .v-list-item:hover {
    background-color: var(--v-gray-30-base);

    &::before {
      opacity: 0;
    }
  }
}

::v-deep .v-app-bar__nav-icon .v-icon {
  font-size: 18px;
}
</style>
