<template>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @drop.prevent="dropFiles"
    @dragover.prevent
    :class="['ui-form-uploader', {'is-disabled': disabled, 'active-dropzone': dragging && !disabled}]"
  >
    <input
      v-if="inputFileSelect && !disabled"
      type="file"
      :accept="filesFormats"
      :multiple="multiple"
      class="sr-only"
      ref="files_input"
      @change="uploadFilesInput"
    >
    <slot></slot>
  </component>
</template>

<script>
import {mapGetters} from "vuex";
import {getFileExtension, getFileName} from "@/utils/helpers";

export default {
  name: 'UIFilesUploader',
  props: {
    filesFormats: {
      type: [String, Array],
      default: '.jpg, .jpeg, .png, .gif'
    },
    maxFileSize: {
      type: Number,
      default: 1024 * 2 // 2MB
    },
    maxFilesQuantity: {
      type: Number,
      default: 20,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputFileSelect: {
      type: Boolean,
      default: true,
    },
    dragAndDrop: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'label'
    }
  },
  data() {
    return {
      dragging: false
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
    ])
  },
  methods: {
    toggleActive() {
      this.dragging = !this.dragging;
    },
    getFilesFormats() {
      if (typeof this.filesFormats === 'string') {
        return this.filesFormats.split(',').map(item => item.replace(/^[^/]*\//, '.')).join(', ');
      } else {
        return this.filesFormats.join(', ');
      }
    },
    uploadFilesInput(event) {
      if (!event.target.files.length) return;

      if (event.target.files.length > this.maxFilesQuantity) {
        this.$store.dispatch('openModal', {
          modalName: 'fileUploadErrorModal',
          data: {
            type: 'quantityError',
            maxFilesQuantity: this.maxFilesQuantity
          }
        });
        return
      }


      const filesNames = [];
      const files = [...event.target.files].reduce((acc, file) => {
        if ((this.filesFormats.length && !this.filesFormats.includes(getFileExtension(file.name))) || file.size > this.maxFileSize) {
          filesNames.push(file.name);
          return acc;
        } else {
          acc.push(file);
        }

        return acc;
      }, []);

      if (filesNames.length) {
        this.$store.dispatch('openModal', {
          modalName: 'fileUploadErrorModal',
          data: {
            filesNames,
            allowedFilesFormats: this.getFilesFormats(),
            maxFileSizeLabel: `${this.maxFileSize / 1024 / 1000}MB`
          }
        });
      }

      if (files.length) {
        this.$emit('onChange', files);
      }

      this.$refs.files_input.value = '';
    },
    dropFiles(event) {
      if (!this.dragAndDrop || this.disabled) return;


      let droppedItems = event.dataTransfer.items || event.dataTransfer.files;
      if (!droppedItems.length) {
        this.dragging = false;
        return;
      }
      const files = [];

      if (droppedItems.length > this.maxFilesQuantity || !this.multiple && droppedItems.length >= 2) {
        this.$store.dispatch('openModal', {
          modalName: 'fileUploadErrorModal',
          data: {
            type: 'quantityError',
            maxFilesQuantity: this.multiple ? this.maxFilesQuantity : 1
          }
        });
        return
      }

      const filesNames = [];
      for (let i = 0; i < droppedItems.length; i++) {
        if (droppedItems[i].kind === 'file') {
          let file = droppedItems[i].getAsFile();
          if ((this.filesFormats.length && !this.filesFormats.includes(getFileExtension(file.name))) || file.size > this.maxFileSize) {
            filesNames.push(file.name);
          } else {
            files.push(file);
          }
        }
      }

      if (filesNames.length) {
        this.$store.dispatch('openModal', {
          modalName: 'fileUploadErrorModal',
          data: {
            filesNames,
            allowedFilesFormats: this.getFilesFormats(),
            maxFileSizeLabel: `${this.maxFileSize / 1024 / 1000}MB`
          }
        });
      }

      if (files.length) {
        this.$emit('onChange', files);
      }
    },
  }
}
</script>

<style lang="scss">
.ui-form-uploader {
  position: relative;
  overflow: hidden;

  &.active-dropzone {
    border-color: var(--v-accent-base) !important;
    background-color: var(--v-accent-10-base) !important;
  }
}
</style>
