<template>
  <v-simple-table class="side-drawer-table gray-80--text">
    <template v-slot:default>
      <thead>
      <tr>
        <th v-for="(header, idx) in headers" :key="idx" class="pb-3" :class="header.classesTh"
            :style="minWidthStyle(header.width)">
          <div :class="header.classes" class="fill-height text-captions-2 gray-80--text py-1">
            {{ header.title ? header.title : '' }}
          </div>
        </th>
      </tr>
      </thead>
      <tbody class="text-captions-2 gray-60--text">
      <slot/>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  /*
  * headers item
  *
  * {
  *   title: '',
  *   classes: '',
  *   classesTh: '',
  *   width: ''
  * }
  *
  * */
  name: "SideDrawerTable",
  props: {
    headers: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    minWidthStyle() {
      return width => {
        return {
          width: width,
          minWidth: width
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-data-table.side-drawer-table {
  background: transparent;

  ::v-deep table {
    border-spacing: 0 4px;
    border: 1px solid transparent;
  }

  thead {
    th {
      border: none !important;
      height: 24px;

      > div {
        display: flex;
        border-bottom: 1px solid var(--v-gray-30-base);
        align-items: center;
        padding-right: 16px;
      }
    }
  }

  ::v-deep tbody {
    td {
      font-size: inherit;
      border: none !important;
      padding-left: 0;
      padding-right: 16px;
      height: 44px;
    }

    > tr {

      background: var(--v-gray-0-base);

      &:hover {
        background: var(--v-gray-30-base) !important;

        td {
          border-radius: 0 !important;
        }
      }
    }
  }
}
</style>
