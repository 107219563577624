import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import config from './config'
import WS from './utils/websocket'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './registerServiceWorker'

import '@/styles/main.scss'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.config.productionTip = false

// Prototype vue block
Vue.prototype.$api = api
Vue.prototype.$config = config
Vue.prototype.$ws = new WS();
Vue.prototype.$filesUrl = `${process.env.VUE_APP_API_URL}/files/link`;
Vue.prototype.$eventBus = new Vue();

Vue.filter('capitalize', value => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

// Use block
Vue.use(VueToast);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
