export default axios => ({

  /**
   * Get template list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-GetApiV1Users
   * @returns {*}
   */
  list () {
    return axios.get(`/templates`)
  },

  /**
   * Create new user
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-PostApiV1Users
   * @param data
   * @returns {*}
   */
  create (data) {
    return axios.post(`/templates`, data)
  },

  /**
   * Update user by user id
   *
   * @link http://localhost:63342/upsway_restapi/public/apidoc/index.html?_ijt=dda2gkhkqm6dsrqq184k36dgh0#api-2._Users-PutApiV1UsersId
   * @param templateId
   * @param data
   * @returns {*}
   */
  update (templateId, data) {
    return axios.put(`/templates/${templateId}`, data)
  },

  /**
   * Delete user by user id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-DeleteApiV1UsersId
   * @param templateId
   * @returns {*}
   */
  delete (templateId) {
    return axios.delete(`/users/${templateId}`)
  },
})
