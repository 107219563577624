import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// /**
//  * This method use when we need clear project state to default data
//  * Example: When we open a project by id, we write the data received from the api to state.project
//  * and when we get to the project creation page we see the data from the api.
//  * At this point, we need to clean up the project by default values.
//  *
//  * @returns {{template: number, workflow: {scheme_id: null, scheme_design: *[]}, id: null, title: string, category: null, modules: *[], status: number, tags: *[]}}
//  */
// export const getDefaultProjectState = () => {
//   return {
//     id: null,
//     title: 'My project',
//     status: 0,
//     category: 1, // can be Number or an Array when project already created
//     modules: [],
//     template: 1,
//     tags: [],
//     attachments: [],
//   }
// }

const state = {
  activeFolder: null,
}

export default {
  state,
  getters,
  actions,
  mutations
}
