export default {

  /**
   *
   * @param commit
   * @param payload
   */
  setWorkflowModules({ commit }, payload) {
    commit('SET_WORKFLOW_MODULES', payload)
  },
}
