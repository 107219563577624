<template>
  <div class="fill-width fill-height relative z-1 overflow-hidden">
    <iframe
      :src="`https://view.officeapps.live.com/op/embed.aspx?src=${url}&wdAllowInteractivity=False&wdDownloadButton=False&wdInConfigurator=False&wdInConfigurator=False&edesNext=False&resen=false&ed1JS=false&ActiveCell=A1`"
      @load="$emit('load', $event)"
      frameborder="0"
      scrolling="no"
      class="xls-viewer fill-width"
    />
  </div>
</template>

<script>
export default {
  name: "XlsView",
  props: {
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.xls-viewer {
  height: calc(100% + 26px);
}
</style>
