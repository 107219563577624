export default {
  setSpaces({commit, state}, payload) {
    commit('SET_SPACES', payload.map(space => {
      return {
        ...space,
        isOpenSettings: false
      }
    }))


    const newActiveSpace = payload?.find(space => space.id === state.activeSpace?.id)
    if(newActiveSpace?.id) {
      commit('SET_ACTIVE_SPACE', newActiveSpace.id)
    }
  },
  setActiveSpace({commit}, payload) {
    commit('SET_ACTIVE_SPACE', payload)
  },
}
