export default axios => ({

  /**
   * Get user links
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-GetApiV1Users
   * @returns {*}
   */
  list (params) {
    return axios.get('/users', {params: params})
  },

  /**
   * Get user by user id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-GetApiV1UsersId
   * @param userId
   * @returns {*}
   */
  get (userId) {
    return axios.get(`/users/${userId}`)
  },

  /**
   * Create new user
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-PostApiV1Users
   * @param data
   * @returns {*}
   */
  create (data) {
    return axios.post('/users', data)
  },

  /**
   * Update user by user id
   *
   * @link http://localhost:63342/upsway_restapi/public/apidoc/index.html?_ijt=dda2gkhkqm6dsrqq184k36dgh0#api-2._Users-PutApiV1UsersId
   * @param userId
   * @param data
   * @returns {*}
   */
  update (userId, data) {
    return axios.put(`/users/${userId}`, data)
  },

  /**
   * Delete user by user id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-DeleteApiV1UsersId
   * @param userId
   * @returns {*}
   */
  delete (userId) {
    return axios.delete(`/users/${userId}`)
  },

  saveSettings(userId, data) {
    return axios.put(`/users/${userId}/settings`, data)
  },

  /**
   * Resend email verification
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Users-PostApiV1UsersSendEmailVerificationNotification
   * @returns {*}
   */
  resendEmailVerification () {
    return axios.post(`/users/send-email-verification-notification`)
  },
})
