export default function auth ({ to, from, next }) {

  if (typeof to.query.token === 'undefined') {
    return next({
      name: 'Login'
    })
  }

  return next()
}
