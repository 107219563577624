<template>
  <v-app :class="{'side-drawer-is-open' : showProjectSideDrawer}">
    <v-flex v-if="loading" class="d-flex justify-center align-center">
      <v-progress-circular
        :size="100"
        color="accent"
        indeterminate
      />
    </v-flex>

    <template v-else>
      <component :is="$route.meta.layout"/>
      <NotificationsBar v-if="getAuthId !== null"/>
      <v-alert
        class="impersonate-alert"
        v-if="isTempAuth"
      >
        You are impersonating <span class="text-decoration-underline">{{ getUser.email }}</span>
        <UiBtn color="error" class="ml-3" @click="closeSession">Close session</UiBtn>
      </v-alert>

      <FileUploadErrorModal/>
      <FilePreviewModal/>
      <TextModal/>
      <ConfirmModal/>
      <SaveFormChangesModal/>

      <CreateNewTaskModal v-if="$store.getters.getModal('createNewTaskModal').isOpen"/>
      <CreateEditFolderNameModal v-if="$store.getters.getModal('createEditFolderNameModal').isOpen"/>
      <MoveProjectFolderModal v-if="$store.getters.getModal('moveProjectFolderModal').isOpen"/>
      <EditModuleAccessModal v-if="$store.getters.getModal('editModuleAccessModal').data.project"/>

      <UiBtn v-if="isDevMode" color="accent" target="_blank"
             fixed bottom right small height="30" :to="{name: 'UI'}" style="z-index: 999">
        <IconMoodboard width="20" class="white--text"/>
      </UiBtn>
    </template>
  </v-app>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapGetters} from "vuex";
import WS from "./utils/websocket";
import NotificationsBar from "./components/notifications/NotificationsBar";
import {pushSubscribe} from "@/utils/pushNotificationUtils";
import FileUploadErrorModal from "./components/modals/FileUploadErrorModal";
import FilePreviewModal from "./components/modals/FilePreviewModal";
import TextModal from "./components/modals/TextModal";
import ConfirmModal from "./components/modals/ConfirmModal";
import SaveFormChangesModal from "@/components/modals/SaveFormChangesModal";
import CreateNewTaskModal from "@/components/modals/CreateNewTaskModal";
import CreateEditFolderNameModal from "@/components/modals/CreateEditFolderNameModal";
import UiBtn from "@/components/UI/UiBtn";
import MoveProjectFolderModal from "@/components/modals/MoveProjectFolderModal.vue";
import EditModuleAccessModal from "@/components/modals/EditModuleAccessModal.vue";

export default {
  name: 'App',
  components: {
    EditModuleAccessModal,
    MoveProjectFolderModal,
    IconMoodboard: () => import('@/components/icons/IconMoodboard'),
    NotificationsBar,
    FileUploadErrorModal,
    FilePreviewModal,
    TextModal,
    ConfirmModal,
    SaveFormChangesModal,
    CreateNewTaskModal,
    UiBtn,
    CreateEditFolderNameModal
  },
  data() {
    return {
      loading: true,
      isDevMode: process.env.NODE_ENV === 'development'
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getUser',
      'showProjectSideDrawer',
      'getNotifications',
      'getGridView',
      'isTempAuth',
      'getActiveSpace'
    ]),
  },
  watch: {
    getAuthId: {
      // after login we have to load permissions data
      async handler(nv) {
        if (nv === null) {
          this.$ws.closeConnection();
        }

        await this.loadData();
        if (this.getAuthId !== null) {
          Vue.prototype.$ws = new WS();
          this.WSNotificationHandler();
          await this.subscribePush()
        }
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.closeProjectWsConnect);

    await this.loadData();
    if (this.getAuthId !== null) {
      await this.subscribePush()
    }
  },
  mounted() {
    // Check selected theme type

    // TODO uncomment for dark mode
    // this.$vuetify.theme.dark = localStorage.getItem("dark_theme") === 'true';
    //
    // if (this.$vuetify.theme.dark) {
    //   document.documentElement.classList.add('dark')
    // }

    this.WSNotificationHandler();
    const defaultGridKeys = Object.keys(this.$config.defaultGridViews)
    const storageKeys = Object.keys(this.getGridView)
    const correctGridStorage = defaultGridKeys.every(key => storageKeys.includes(key))
    if (!this.getGridView || !correctGridStorage) {
      this.setDefaultGridView(this.$config.defaultGridViews)
    }
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.closeProjectWsConnect);
  },
  methods: {
    ...mapActions([
      'setDefaultGridView',
      'logoutTempAuth'
    ]),

    closeSession() {
      this.logoutTempAuth()
      window.location.href = '/admin'
    },
    async subscribePush() {
      if (this.isTempAuth) return
      try {
        if (Notification.permission === 'default') {
          await pushSubscribe()
        }
        if (Notification.permission === 'granted') {
          await this.$store.dispatch('setPushNotification', true)
        }
      } catch (e) {
        console.warn('Notifications are not supported by this browser');
      }
    },

    async loadData() {
      this.loading = true;

      if (this.getAuthId === null) {
        this.loading = false;
        return;
      }

      try {
        const [permissions, user, spaces, roles] = await Promise.all([this.$api.project.permissions(this.getActiveSpace?.id), this.$api.user.get(this.getAuthId), this.$api.spaces.list(), this.$api.auth.rolesList()])
        await this.$store.dispatch('setPermissions', permissions.data);
        await this.$store.dispatch('setUser', user.data);
        await this.$store.dispatch('setSpaces', spaces.data);
        await this.$store.dispatch('setRolesList', roles.data);

        this.$vuetify.theme.dark = Boolean(this.getUser.settings.user_theme);
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false;
      }
    },
    closeProjectWsConnect() {
      if (this.$route.params.project_id === undefined) return;
      this.$ws.sendMessage(this.$config.ws.methods.exitFromProject, {project_id: this.$route.params.project_id});
      this.$store.state.Project.isProjectWsConnect = false;
      this.$ws.updateOnMessageCallback('projectSideDrawerData', null);
    },
    WSNotificationHandler() {
      if (this.getAuthId === null) return;

      this.$ws.onMessage();

      this.$ws.addOnMessageCallback('globalNotifications', data => {
        if (data.method_id === this.$config.ws.methods.notification) {

          const messageTypes = [
            this.$config.notifications.types.mentionInComment,
            this.$config.notifications.types.mentionInTask,
            this.$config.notifications.types.userTaggedInMessage,
            this.$config.notifications.types.newComment,
            this.$config.notifications.types.taskAssigned,
            this.$config.notifications.types.mentionInTaskFile,
          ]

          if(messageTypes.includes(data.data.type)) {
            this.$store.dispatch('setNotifications', {
              data: [data.data, ...this.getNotifications(this.$config.notifications.notificationType.messages).data],
              type: this.$config.notifications.notificationType.messages
            })
          } else {
            this.$store.dispatch('setNotifications', {
              data: [data.data, ...this.getNotifications(this.$config.notifications.notificationType.notifications).data],
              type: this.$config.notifications.notificationType.notifications
            })
          }

          this.$toast.open({
            message: data.data.message,
            type: 'success',
            position: 'top-right'
          });
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.impersonate-alert {
  position: fixed;
  z-index: 999;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid var(--v-error-base);
  color: var(--v-error-base);
  background: #FFECEB;
  font-weight: 300;
  font-size: 14px;
  padding: 8px 8px 8px 16px;

  span {
    font-weight: 700;
  }


}
</style>
