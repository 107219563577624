<template>
  <div class="chat d-flex flex-column justify-end fill-height overflow-hidden">
    <div class="d-flex flex-column flex-column-reverse fill-height px-6 overflow-y-auto" ref="chat_body"
         @scroll="loadMessages">
      <div v-for="day in Object.keys(messagesByDay)" :key="day" class="d-flex flex-column flex-column-reverse">
        <div
          v-for="message in messagesByDay[day]"
          :key="message.id"
          :class="['chat-message-wrap relative d-flex align-start mb-7', {'own-message': message.created_by_id === getAuthId}]"
        >
          <UiAvatar
            :src="message.created_by_avatar_id !== null ? `${$filesUrl}/${message.created_by_avatar_id}` : ''"
            :text="message.created_by_name"
            size="22"
            class="chat-message-user"
          />
          <div class="overflow-hidden">
            <div class="chat-message pa-3 rounded text-captions-1">
              <div v-if="message.replied_message"
                   class="replied-message d-flex align-center mb-3 pl-2 pr-3 text-captions-2 rounded-tr rounded-br gray-10">
                <div v-if="message.replied_message.files.length"
                     class="chat-message-img mr-2 flex-shrink-0 overflow-hidden rounded"
                >
                  <div class="fill-width fill-height d-flex justify-center align-center"
                       v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(message.replied_message.files[0]?.original_name || ''))">
                    <UiFileIcon
                      :extension="getFileName( message.replied_message.files[0].original_name).ext" width="14"/>
                  </div>

                  <img v-else
                       :src="getFileThumbnails( message.replied_message.files[0].thumbnails) ||  message.replied_message.files[0].url"
                       alt="" class="fill-width fill-height object-cover">
                </div>

                <span
                  v-html="formatToHTMLMentionMessage(decodeMessage(message.replied_message.message_text), message.replied_message.tagged_users)"
                  class="overflow-hidden text-overflow-ellipsis text-no-wrap"/>
              </div>

              <div class="d-flex flex-column">
                <p class="chat-message-text mb-0">
                <span class="d-block"
                      v-html="formatToHTMLMentionMessage(decodeMessage(message.message_text), message.tagged_users)" @click="keywordAction($event, message)"/>
                  <span v-if="message.project_tag_title"
                        class="d-block accent--text">#{{ message.project_tag_title }}</span>
                </p>

                <div v-if="message.files?.length" class="d-flex flex-wrap gap-2 mt-3">
                  <div
                    v-for="file in message.files"
                    :key="file.id"
                    class="chat-message-img relative flex-shrink-0 overflow-hidden rounded cursor-pointer"
                    @click="showImagePreview(file)"
                  >
                    <div class="fill-width fill-height d-flex justify-center align-center"
                         v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file?.original_name || ''))">
                      <UiFileIcon
                        :extension="getFileName(file.original_name).ext" width="24"/>
                    </div>

                    <img v-else :src="getFileThumbnails(file.thumbnails) || file.url" alt=""
                         class="fill-width object-cover fill-height">

                    <UiMenu
                      allow-overflow
                      offset-y
                      max-width="142"
                      min-width="142"
                      :nudge-right="18"
                      z-index="12"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <UiBtn v-bind="attrs" v-on="on" @click.stop absolute text plain width="auto" min-width="auto"
                               height="auto"
                               class="px-0 mt-1 chat-message-img-action ">
                          <IconDotsH width="14"
                                     :class="attrs['aria-expanded'] === 'true' ? 'accent--text' : 'gray-60--text'"/>
                        </UiBtn>
                      </template>

                      <v-list nav dense color="gray-10" class="py-2 px-0">
                        <v-list-item class="px-4"
                                     @click="copyTextToClipboard(file.download_url || file.url)">
                          <v-list-item-title class="text-captions-1">
                            <IconPaperclip width="16" class="mr-2 gray-60--text"/>
                            Copy Link
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item class="px-4" @click="download(file)">
                          <v-list-item-title class="text-captions-1">
                            <IconDownload width="16" class="mr-2 gray-60--text"/>
                            Download File
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </UiMenu>
                  </div>
                </div>
              </div>
            </div>
            <div class="chat-message-controls d-flex align-center">
              <span class="text-captions-2 gray-100--text">{{ formatDate(message.created_at, 'hh:mm aa') }}</span>
              <div v-if="message.message_type === $config.chat.messageTypes.textMessage" class="space-x-2">
                <UiBtn
                  text plain width="auto" height="auto" icon @click="reply(message)"
                >
                  <IconMessagePlusSquare width="13"/>
                </UiBtn>
                <UiBtn
                  v-if="message.created_by_id === getAuthId"
                  text plain width="auto" height="auto" icon @click="editMessage(message)"
                >
                  <IconPencil width="13"/>
                </UiBtn>
                <UiBtn
                  v-if="message.created_by_id === getAuthId"
                  text plain width="auto" height="auto" icon @click="deleteMessage(message)"
                >
                  <IconTrash width="13"/>
                </UiBtn>
              </div>
            </div>
          </div>
        </div>

        <div class="font-weight-semi-bold text-captions-1 mb-5">
          {{ formatDate(day, 'MMMM d') }}
          <v-divider class="mt-1"/>
        </div>
      </div>
    </div>

    <div v-if="repliedMessage !== null"
         class="replied-message d-flex align-center flex-shrink-0 mt-5 mb-n5 pl-5 pr-4 text-captions-1 gray-0">
      <div v-if="repliedMessage.files?.length"
           class="chat-message-img flex-shrink-0 mr-2 overflow-hidden rounded"
      >
        <div class="fill-width fill-height d-flex justify-center align-center"
             v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(repliedMessage.files[0]?.original_name))">
          <UiFileIcon
            :extension="getFileName(repliedMessage.files[0].original_name).ext" width="24"/>
        </div>

        <img v-else :src="getFileThumbnails(repliedMessage.files[0].thumbnails) || repliedMessage.files[0].url" alt=""
             class="fill-width fill-height object-cover">
      </div>

      <span class="mr-auto overflow-hidden text-overflow-ellipsis text-no-wrap"
            v-html="formatToHTMLMentionMessage(decodeMessage(repliedMessage.message_text), repliedMessage.tagged_users)"/>

      <UiBtn icon plain class="ml-16" @click="repliedMessage = null">
        <IconCancelCircle width="16" class="gray-60--text"/>
      </UiBtn>
    </div>

    <div :class="['chat-input-wrap relative pb-4 mt-5 gray-30', {
      'is-focused' : inputFocus,
      'is-dirty' : messageFiles.length || messageText.trim().length || repliedMessage !== null,
      'has-image': messageFiles.length,
    }]">
      <div v-if="editingMessage" class="px-6 d-flex justify-space-between text-captions-1 gray-60--text pt-3">
        Editing
        <UiBtn icon small width="15" height="15" @click="closeEditing">
          <IconCancel width="14"/>
        </UiBtn>
      </div>
      <v-form lazy-validation @submit.prevent="sendMessage" class="relative chat-input-form mt-3 mx-6 rounded">
        <UiMentionInput
          ref="chat_input"
          :content-class="'chat-input text-captions-1 overflow-y-auto'"
          :users="users"
          :disabled="!permissions.canCreateMessage"
          :menu-container-selector="'.chat-input-wrap'"
          @editMessage="val => this.messageText = val"
          @focus="inputFocus = true"
          @blur="onBlur"
          @keydown.enter.exact.prevent="sendMessage"
        />


        <div class="chat-input-image-wrapper pt-2 pb-4 d-flex flex-wrap gap-2" v-if="messageFiles.length">
          <div
            class="chat-input-image-preview rounded relative flex-shrink-0 d-flex cursor-pointer"
            @click="showImagePreview(file)"
            v-for="(file, idx) in messageFiles" :key="idx">
            <div class="fill-width fill-height d-flex justify-center align-center"
                 v-if="$config.filesystem.fileTypes.pdf.includes(getFileExtension(file?.original_name || ''))">
              <UiFileIcon
                :extension="getFileName(file.original_name).ext" width="24"/>
            </div>

            <img v-else :src="getFileThumbnails(file.thumbnails) || file.url" alt=""
                 class="fill-width fill-height object-cover rounded">
            <UiBtn absolute icon plain width="auto" height="auto" @click.stop="deleteMessageImage(idx)"
                   class="chat-input-image-preview-delete-btn rounded-circle">
              <IconCancelCircle width="14" class="gray-60--text"/>
            </UiBtn>
          </div>
        </div>


        <div class="chat-input-controls d-flex align-center" @click="toggleInputFocus(true)">
          <UiBtn icon :disabled="!permissions.canCreateMessage" class="mr-auto" @click.stop="callMention">
            <IconMention width="18" class="gray-60--text"/>
          </UiBtn>

          <UiBtn icon :disabled="!permissions.canCreateMessage" @click.stop>
            <UIFilesUploader
              v-if="permissions.canCreateMessage"
              :files-formats="$config.chat.file.acceptedUploadFormats"
              :max-file-size="$config.chat.file.maxUploadFileSize"
              :drag-and-drop="false"
              multiple
              @onChange="createMessageImage"
              class="d-flex justify-center align-center fill-width cursor-pointer"
              style="height: 30px;"
            >
              <IconPaperclip width="18" class="gray-60--text"/>
            </UIFilesUploader>
          </UiBtn>

          <UiBtn type="submit" icon :disabled="!permissions.canCreateMessage" @click.stop>
            <IconSendMessage width="18" class="gray-60--text"/>
          </UiBtn>
        </div>
      </v-form>

      <div v-if="inputError" class="mt-1 mx-6 text-captions-1 error--text">
        Message must have max {{ $config.chat.maxMessageLength }} symbols.
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {format} from "date-fns";
import {
  copyTextToClipboard,
  formatToHTMLMentionMessage,
  getFileExtension,
  getFileName,
  getFileThumbnails
} from "@/utils/helpers";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UiMenu from "@/components/UI/UiMenu";
import UiMentionInput from "@/components/UI/UiMentionInput";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: 'Chat',
  components: {
    UiFileIcon,
    IconMessagePlusSquare: () => import('@/components/icons/IconMessagePlusSquare'),
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconPaperclip: () => import('@/components/icons/IconPaperclip'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconSendMessage: () => import('@/components/icons/IconSendMessage'),
    IconMention: () => import('@/components/icons/IconMention'),
    IconTrash: () => import('@/components/icons/IconTrash'),
    IconPencil: () => import('@/components/icons/IconPencil'),
    IconCancel: () => import("@/components/icons/IconCancel"),

    UIFilesUploader,
    UiAvatar,
    UiBtn,
    UiMenu,
    UiMentionInput,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Object,
      default: () => ({
        canCreateMessage: true,
        canDeleteMessage: true,
      })
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      inputFocus: false,
      messageText: '',
      messageImage: null,
      messageFiles: [],
      repliedMessage: null,
      editingMessage: null
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProjectSideDrawer',
    ]),
    messagesByDay() {
      return this.messages.reduce((acc, message) => {
        const date = this.formatDate(message.created_at, 'yyyy MM dd');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(message);
        return acc;
      }, {});
    },
    inputError() {
      return this.messageText.trim().length > this.$config.chat.maxMessageLength
    }
  },
  methods: {
    getFileThumbnails,
    getFileName,
    getFileExtension,
    copyTextToClipboard,
    formatToHTMLMentionMessage,

    deleteMessageImage(file_idx) {
      this.messageFiles = this.messageFiles.filter((file, idx) => idx !== file_idx)
    },
    callMention() {
      this.$refs.chat_input.callMention();
    },
    decodeMessage(msg) {
      return decodeURIComponent(msg);
    },
    formatDate(date, formatStr) {
      return format(new Date(date), formatStr);
    },
    toggleInputFocus(val) {
      if (val) {
        this.$refs.chat_input.$el.querySelector('div').focus();
      } else {
        this.$refs.chat_input.$el.querySelector('div').blur();
      }
    },
    createMessageImage(files) {
      files.forEach((file) => {
        const reader = new FileReader();
        const newFile = {
          type: file.type,
          original_name: file.name,
          url: '',
          file
        }

        reader.onload = () => {
          newFile.url = reader.result;
          if (this.editingMessage) {
            this.editingMessage.message_file_id = null
          }
          this.messageFiles.push(newFile);
          this.toggleInputFocus(true);
        }

        reader.readAsDataURL(file);
      });
    },
    reply(message) {
      this.closeEditing()
      this.repliedMessage = message;

      this.toggleInputFocus(true);
    },
    deleteMessage(message) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Delete comment?',
          confirmBtnText: 'Delete',
          confirmBtnIcon: '',
          confirmBtnColor: "primary"
        },
        handlers: {
          onCancel: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');
            this.getProjectSideDrawer.isSideDrawerPermanent = false;

          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
            this.$emit('deleteMessage', message)
          }
        }
      });
    },
    editMessage(message) {
      this.repliedMessage = null
      this.editingMessage = JSON.parse(JSON.stringify(message))
      this.$refs.chat_input.focus()

      const text = formatToHTMLMentionMessage(this.decodeMessage(message.message_text), message.tagged_users).trim()

      this.messageText = text
      this.messageFiles = Array.isArray(this.editingMessage.files) ? this.editingMessage.files : []

      this.$refs.chat_input.setValue(text)
      this.$nextTick(() => {
        this.$refs.chat_input.focus()
      })
    },
    closeEditing() {
      this.messageText = '';
      this.messageImage = null;
      this.repliedMessage = null;
      this.editingMessage = null;
      this.messageFiles = [];

      this.$nextTick(() => {
        this.$refs.chat_input.setValue('')
        this.$refs.chat_body.scrollTop = this.$refs.chat_body.scrollHeight;
        this.toggleInputFocus(false);
      })

    },
    sendMessage() {
      if (!this.$refs.chat_input.mentionsInstance.isActive) {
        if (!this.permissions.canCreateMessage || !this.messageText.trim().length && !this.messageImage || this.messageText.trim().length > this.$config.chat.maxMessageLength) {
          return;
        }
        const {message, users} = this.$refs.chat_input.getFormattedMessageData();
        if (this.editingMessage) {
          if (message.trim().length || this.messageFiles.length) {
            this.$emit('editMessage', {
              message_text: message,
              message_id: this.editingMessage.id,
              file_id: this.messageFiles.reduce((acc, file) => {
                if (file.id) {
                  acc.push(file.id)
                }
                return acc
              }, []),
              message_images: this.messageFiles.filter(file => !file.id),
              tagged_users: users.map(user => ({"user_id": user.id})),
              // replied_message_id: this.repliedMessage ? this.repliedMessage.id : null
            });
          }

        } else {
          if (message.trim().length || this.messageFiles.length) {
            this.$emit('sendMessage', {
              message_text: message,
              message_images: this.messageFiles,
              tagged_users: users.map(user => ({"user_id": user.id})),
              replied_message_id: this.repliedMessage ? this.repliedMessage.id : null
            });
          }
        }


        this.messageText = '';
        this.messageFiles = [];
        this.repliedMessage = null;
        this.editingMessage = null

        this.$refs.chat_input.clearInput();
        this.$refs.chat_body.scrollTop = this.$refs.chat_body.scrollHeight;
        this.toggleInputFocus(false);
      }
    },
    loadMessages() {
      const chatBody = this.$refs.chat_body;
      if (chatBody.scrollHeight - Math.abs(chatBody.scrollTop) === chatBody.clientHeight) {
        this.$emit('loadMessages');
      }
    },
    showImagePreview(file) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;
      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type: file.type || 'png/image',
          url: file.url,
          original_name: file.original_name || 'temp.png'
        },
        handlers: {
          onClose: () => {
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          }
        }
      });
    },
    async download(file) {
      if (!file) return;

      const image = await fetch(file.download_url || file.url);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);

      const anchor = document.createElement('a');
      anchor.href = imageURL;
      anchor.download = file.download_file_name || 'file';

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(imageURL);
    },

    onBlur() {
      setTimeout(() => {
        this.inputFocus = false
        const {message} = this.$refs.chat_input.getFormattedMessageData();
        if (!message.trim().length) {
          this.editingMessage = null
        }
      }, 0)
    },
    keywordAction($event, notification) {
      const path = $event.target.getAttribute('data-action');

      if (!path) return;

      this.$store.dispatch('toggleProjectSideDrawer', false)

      switch (path) {
        case 'to_project':
          this.$router.replace({
            name: 'ProjectDesign',
            params: {project_id: notification.project_id},
            query: {module: notification.project_module_id}
          });
          break;
        case 'to_style':
          this.$router.replace({
            name: 'DesignViewPage',
            params: {project_id: notification.project_id},
            query: {
              module_id: notification.project_module_id,
              style_id: notification.project_style_id,
            }
          });
          break;
        case 'to_version':
          this.$router.replace({
            name: 'DesignViewPage',
            params: {project_id: notification.project_id},
            query: {
              module_id: notification.project_module_id,
              style_id: notification.project_style_id,
              file_id: notification.project_file_id,
            }
          });
          break;
        case 'to_task': {
          if(notification.project_task_id) {
            this.$router.replace({
              name: 'DesignViewPage',
              params: {project_id: notification.project_id},
              query: this.$route.name === 'DesignViewPage' ? {
                ...this.$route.query,
                modal_task_id: notification.project_task_id,
                style_id: notification.project_style_id
              } : {modal_task_id: notification.project_task_id, style_id: notification.project_style_id}
            });
          }
          break;
        }

      }
    },
  }
}
</script>

<style scoped lang="scss">
.chat {
  .chat-message-wrap {
    max-width: 258px;
    margin-right: auto;

    &:last-child {
      margin-top: auto;
    }

    .chat-message-user {
      margin: 0 8px 0 0;
    }

    .chat-message {
      min-width: 100px;
      border: 1px solid var(--v-accent-base);
      background-color: var(--v-gray-0-base);
    }

    .replied-message {
      height: 28px;

      .chat-message-img {
        width: 24px;
        height: 24px;
        border: none;
      }
    }

    .chat-message-text {
      word-break: break-word;
    }

    .chat-message-text :deep([data-action]) {
      cursor: pointer;
    }

    .chat-message-img {
      width: 56px;
      height: 56px;
      border: 1px solid var(--v-gray-30-base);
    }

    .chat-message-img-action {
      top: 0;
      right: 4px;
      z-index: 1;
    }

    .chat-message-controls {
      left: 30px;
      right: 0;
      bottom: -20px;
      height: 20px;

      & > span {
        margin-right: auto;
        padding: 0 16px 0 0;
      }
    }

    &.own-message {
      margin-left: auto;
      margin-right: 0;

      .chat-message-user {
        margin: 0 0 0 8px;
        order: 2;
      }

      .chat-message-controls {
        left: 0;
        right: 30px;


        & > span {
          order: 1;
          padding: 0 0 0 16px;
          margin: 0 0 0 auto;
        }
      }
    }
  }

  .replied-message {
    height: 48px;
    border-left: 4px solid var(--v-accent-base) !important;

    .chat-message-img {
      width: 30px;
      height: 30px;
      border: none;
    }
  }

  ::v-deep .chat-input-wrap {
    .chat-input-form {
      border: 1px solid var(--v-accent-base);
      background-color: var(--v-gray-0-base);
    }


    &.is-focused,
    &.is-dirty {
      .chat-input {
        height: auto;
        padding: 17px 22px 16px;
        opacity: 1;
      }

      .chat-input-controls {
        border-top: 1px solid var(--v-gray-30-base);
      }
    }

    .chat-input {
      height: 0;
      opacity: 0;
      max-height: 130px;
    }

    .chat-input-controls {
      padding: 6px 16px;
      cursor: text;
    }

    .input-error {
      position: absolute;
      bottom: -26px;
      padding-left: 32px;
      font-size: 14px;
    }

    .chat-input-image-wrapper {
      bottom: 58px;
      left: 0;
      padding: 0 22px;
      width: 100%;
    }

    .chat-input-image-preview {
      width: 56px;
      height: 56px;
      border: 1px solid var(--v-gray-30-base);

      .chat-input-image-preview-delete-btn {
        top: -6px;
        right: -6px;
        background-color: var(--v-gray-0-base);
      }
    }

    &.has-image {
      .chat-input {
        max-height: 84px;
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .chat-input-wrap {
      .chat-input {
        max-height: 159px;
        padding: 15px 78px 15px 16px;
      }

      &.has-image {
        .chat-input {
          padding-top: 88px;
          max-height: 84px;
        }
      }
    }
  }
}
</style>
