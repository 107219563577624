export default {

  /**
   *
   * @param state
   * @param payload
   */
  OPEN_MODAL(state, payload) {
    if (typeof payload === "string") {
      state[payload].isOpen = true;
      return;
    }

    const {modalName, data, handlers} = payload;

    if (data !== undefined && state[modalName].defaultData === undefined) {
      state[modalName].defaultData = JSON.parse(JSON.stringify(state[modalName].data));
    }

    if (handlers !== undefined && state[modalName].defaultHandlers === undefined) {
      state[modalName].defaultHandlers = JSON.parse(JSON.stringify(state[modalName].handlers));
    }

    state[modalName].isOpen = true;
    state[modalName].data = {...state[modalName].data, ...data};
    state[modalName].handlers = {...state[modalName].handlers, ...handlers};
  },

  /**
   *
   * @param state
   * @param modalName
   */
  CLOSE_MODAL(state, modalName) {
    state[modalName].isOpen = false;

    if (state[modalName].defaultData !== undefined) {
      state[modalName].data = {...state[modalName].defaultData};
    }

    if (state[modalName].defaultHandlers !== undefined) {
      state[modalName].handlers = {...state[modalName].defaultHandlers};
    }
  },

  /**
   *
   * @param state
   * @param payload
   */
  UPDATE_MODAL_DATA(state, payload) {
    const {modalName, data} = payload;

    if (data !== undefined && state[modalName].defaultData === undefined) {
      state[modalName].defaultData = JSON.parse(JSON.stringify(state[modalName].data));
    }

    state[modalName].data = {...state[modalName].data, ...data};
  },

  /**
   *
   * @param state
   * @param payload
   */
  UPDATE_MODAL_HANDLERS(state, payload) {
    const {modalName, handlers} = payload;

    if (handlers !== undefined && state[modalName].defaultHandlers === undefined) {
      state[modalName].defaultHandlers = JSON.parse(JSON.stringify(state[modalName].handlers));
    }

    state[modalName].handlers = {...state[modalName].handlers, ...handlers};
  },

}
