<template>
  <div class="d-flex flex-column fill-height overflow-hidden">
    <div ref="files_body" class="fill-height rounded-scroll-track overflow-auto py-3" @scroll="loadFiles">
      <div class="px-6 mb-3">
        <div class="text-captions-1 gray-80--text mb-2">Design Files ({{ getProjectSideDrawerDesignFiles.total }})</div>
        <v-divider/>
      </div>

      <SideDrawerTable :headers="headers">
        <DesignFileItem v-for="file in getProjectSideDrawerDesignFiles.data" :key="file.id" :file="file"
                        @deleteFile="deleteFile" @viewFile="viewFile"/>
      </SideDrawerTable>
      <div v-if="loadingData" class="d-flex justify-center align-center pt-2">
        <v-progress-circular :size="24" width="3" color="accent" indeterminate/>
      </div>
    </div>
    <v-footer height="70" color="gray-30" class="d-flex justify-end flex-shrink-0 px-6" v-if="false">
      <UiBtn color="accent">
        <IconUpload width="16" class="mr-2"/>
        Upload File
      </UiBtn>
    </v-footer>
  </div>
</template>

<script>
import DesignFileItem from "@/components/project/side-drawer/DesignFileItem";
import {mapGetters} from "vuex";
import SideDrawerTable from "@/components/project/side-drawer/SideDrawerTable";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'FilesTab',
  components: {
    IconUpload: () => import('@/components/icons/IconUpload'),

    UiBtn,
    SideDrawerTable,
    DesignFileItem,
  },
  data() {
    return {
      loadingData: false,
      headers: [
        {title: '', classes: 'border-b', classesTh: 'pl-6 pr-0 text-no-wrap', width: '1%'},
        {title: 'File Name', classesTh: 'px-0 text-no-wrap', width: '50%'},
        {title: 'Last Updated', classesTh: 'px-0 text-no-wrap', width: '30%'},
        {title: 'Version', classesTh: 'px-0 text-no-wrap', width: '18%'},
        {title: '', classesTh: 'pr-6 pl-0 text-no-wrap'},
      ]
    }
  },
  watch: {
    showProjectSideDrawer(val) {
      if (val) {
        this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'files', data: []});
        this.loadDesignFiles(`/projects/${this.$route.params.project_id}/items`)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectSideDrawer',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerDesignFiles'
    ]),
  },
  created() {
    this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'files', data: []});
    this.loadDesignFiles(`/projects/${this.$route.params.project_id}/items`)
  },
  mounted() {
    this.$eventBus.$on('updateSidDrawerFilters', async data => {
      const tabFilters = this.getProjectSideDrawer.filtersByTab.files;
      const updatedFilterItems = Object.keys(data);
      const updateNeeded = updatedFilterItems.some(filterItem => tabFilters.includes(filterItem) && JSON.stringify(data[filterItem]) !== JSON.stringify(this.getProjectSideDrawerFilters[filterItem]));
      if (updateNeeded) {
        await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'files', data: []});
        this.loadDesignFiles(`/projects/${this.$route.params.project_id}/items`, {
          ...this.getProjectSideDrawerFilters,
          ...data,
        })
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('updateSidDrawerFilters');
  },
  methods: {
    loadFiles() {
      const filesBody = this.$refs.files_body;
      if (!this.getProjectSideDrawerDesignFiles.nextLink || this.loadingData) return
      if (filesBody.scrollTop + filesBody.clientHeight >= filesBody.scrollHeight) {
        this.loadDesignFiles(this.getProjectSideDrawerDesignFiles.nextLink)
      }
    },
    viewFile({type, url, original_name}) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;

      this.$store.dispatch('openModal', {
        modalName: 'previewFileModal',
        data: {
          type, url, original_name
        },
        handlers: {
          onClose: () => {
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          }
        }
      });
    },
    deleteFile(deletingFile) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: `Are you sure you want to delete “${deletingFile.original_name}“?`,
          confirmBtnText: 'Delete',
        },
        handlers: {
          onConfirm: () => {
            deletingFile.loading = true

            const files = this.getProjectSideDrawerDesignFiles.data.filter(file => file.id !== deletingFile.id)
            this.$store.dispatch('closeModal', 'confirmModal');

            this.$api.project.deleteProjectDesignFiles(this.$route.params.project_id, deletingFile.id).then(() => {
              this.$store.dispatch('setProjectSideDrawerTabData', {
                tab: 'files',
                data: files,
                total: this.getProjectSideDrawerDesignFiles.total -= 1,
                nextLink: this.getProjectSideDrawerDesignFiles.nextLink
              });
              this.getProjectSideDrawer.isSideDrawerPermanent = false;
            })
              .catch(err => {
                console.error(err);
              })
              .finally(() => {
                deletingFile.loading = false;
              });
          },
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
        },
      });
    },
    loadDesignFiles(url, data = null) {
      this.loadingData = true
      const {
        search,
        project_tag_id,
        project_style_id,
        owner,
        order,
        file_type,
        project_module
      } = data || this.getProjectSideDrawerFilters
      this.$api.project.getProjectDesignFiles(url, {
        order,
        search,
        project_style_id,
        project_tag_id,
        owner,
        file_type,
        project_module: project_module ? [project_module] : null
      })
        .then(res => {
          const files = [...this.getProjectSideDrawerDesignFiles.data, ...res.data].map(file => {
            return {
              ...file,
              loading: false
            }
          })
          this.$store.dispatch('setProjectSideDrawerTabData', {
            tab: 'files',
            data: files,
            total: res.total,
            nextLink: res.links.next,
          })
        }).finally(() => {
        this.loadingData = false
      })
    }
  },
}
</script>
