import getters from './getters'
import mutations from './mutations'
import actions from "./actions";

const state = {
  textModal: {
    isOpen: false,
    data: {
      title: '',
      text: '', // String or Array of strings
    },
  },

  confirmModal: {
    isOpen: false,
    data: {
      title: '',
      description: '',
      descriptionColor: '',
      descriptionAlign: 'center', // center|left
      descriptionContainerClasses: '',
      descriptionQuoteColor: '',
      cancelBtnText: 'Cancel',
      cancelBtnColor: 'accent',
      confirmBtnText: 'Confirm',
      confirmBtnIcon: 'IconDelete',
      confirmBtnColor: 'error',
      actionBtnsPosition: 'center', // center|left|right
      items: [],
      loading: false,
      loadingContent: false,
      showIconCross: false,
      modalMaxWidth: '382',
    },
    handlers: {
      onCancel: () => {},
      onConfirm: () => {},
      dynamicDescription: () => {},
    }
  },

  fileUploadErrorModal: {
    isOpen: false,
    data: {
      type: '',
      filesNames: [],
      maxFileSizeLabel: '',
      maxFilesQuantity: 0,
      allowedFilesFormats: '',
    },
    handlers: {
      onClose: () => {},
    }
  },

  previewFileModal: {
    isOpen: false,
    data: {
      file_type: null,
      url: null,
    },
    handlers: {
      onClose: () => {},
    }
  },

  shareProjectModal: {
    isOpen: false,
    data: {
      projects: [], // array of projects id
    },
  },

  pageLeaveModal: {
    isOpen: false,
    dataSavedStatus: true,
    handlers: {
      onLeave: () => {},
    }
  },

  createTemplateModal: {
    isOpen: false,
  },

  editWorkflowModal: {
    isOpen: false,
  },
  createWorkflowModal: {
    isOpen: false,
  },

  requestReviewModal: {
    isOpen: false,
    data: {
      files: [],
      module_id: null,
    }
  },

  editModuleAccessModal: {
    isOpen: false,
    data: {
      user: [],
      module_id: null,
    }
  },

  approveRejectModal: {
    isOpen: false,
    data: {
      files: [],
      status: null,
    }
  },

  moodBoardVideoUrlModal: {
    isOpen: false,
    data: {
      file: null,
    }
  },

  renameMoodBoardModal: {
    isOpen: false,
  },

  createNewTaskModal: {
    isOpen: false,
    projectList: [],
    styleList: [],
    taskList: [],
    data: {
      type: 'create', // create or edit
      taskData: { // required task fields
        id: null,
        project_id: null,
        project_tag_id: null,
        project_style_id: null,
        project_module_id: null,
        parent: {id: null},
      },
      formFocusElements: null
    },
    handlers: {
      onCreate: () => {},
      onUpdate: () => {},
      onClose: () => {},
    }
  },

  createEditFolderNameModal: {
    isOpen: false,
    data: {
      type: 'create', // create or edit
      folderData: {
        title: '',
        id: null,
        space: null
      }
    },
    handlers: {
      onCreate: () => {},
      onUpdate: () => {},
      onClose: () => {},
    }
  },

  moveProjectFolderModal: {
    isOpen: false,
    data: {
      type: 'project', // project or folder
      ids: [],
      folderId: null,
      spaceId: null,
    },
    handlers: {
      onCreate: () => {},
      onUpdate: () => {},
      onClose: () => {},
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
