export default axios => ({

  /**
   * Get modules list
   *
   * @link https://devapi.upsway.io/apidoc/#api-5._Modules
   * @returns {*}
   */
  list () {
    return axios.get('/modules')
  },

  /**
   * Get module
   *
   * @link https://devapi.upsway.io/apidoc/#api-5._Modules-GetApiV1ModulesId
   * @param moduleId
   * @returns {*}
   */
  get (moduleId) {
    return axios.get(`/modules/${moduleId}`)
  },

})
