<template>
  <UITextModal modalName="previewFileModal" max-width="1200" @hideModal="getModal('previewFileModal').handlers.onClose">
    <div v-if="getModal('previewFileModal').data.type && getModal('previewFileModal').data.url"
         class="d-flex align-center justify-center py-12 px-6 px-sm-12" style="height: 90vh;">
      <img v-if="isImageFile" :src="getModal('previewFileModal').data.url" alt=""
           style="max-width: 100%; max-height: 100%; object-fit: contain">
      <iframe v-else-if="isPdfFile" :src="getModal('previewFileModal').data.url" frameborder="0"
              style="max-width: 100%; max-height: 100%; width: 100%; height: 100%"></iframe>
      <XlsView v-else-if="isXlsFile" :url="getModal('previewFileModal').data.url" :name="getModal('previewFileModal').data.original_name"/>
      <UiFileIcon :extension="getFileName(getModal('previewFileModal').data.original_name).ext" v-else width="150"/>
    </div>
  </UITextModal>
</template>

<script>
import {mapGetters} from "vuex";
import UITextModal from "../UI/UITextModal";
import XlsView from "@/components/project/XlsView.vue";
import {getFileExtension, getFileName} from "@/utils/helpers";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: 'FilePreviewModal',
  methods: {getFileName},
  components: {
    UiFileIcon,
    XlsView,
    UITextModal,
  },
  computed: {
    ...mapGetters([
      'getModal'
    ]),
    isImageFile() {
      return this.$config.filesystem.fileTypes.image.includes(getFileExtension(this.getModal('previewFileModal').data.original_name)) || this.getModal('previewFileModal').data.type.includes('image');
    },
    isPdfFile() {
      return this.$config.filesystem.fileTypes.pdf.includes(getFileExtension(this.getModal('previewFileModal').data.original_name))
    },
    isXlsFile() {
      return this.$config.filesystem.fileTypes.xls.includes(getFileExtension(this.getModal('previewFileModal').data.original_name));
    },
  }
}
</script>
