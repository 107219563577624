<template>
  <v-app-bar
    app
    flat
    color="gray-0"
    class="app-menu"
    :height="$vuetify.breakpoint.xsOnly ? 78 : 94"
  >
    <div class="fill-width px-5 px-sm-8 px-md-16">
      <div class="app-menu-grid d-flex align-center justify-space-between fill-width mx-auto">
        <router-link :to="{name: 'WelcomeLanding'}" class="app-menu-logo d-flex">
          <img :src="require('@/assets/images/light/logo.svg')" alt="">
        </router-link>

        <v-list v-if="$vuetify.breakpoint.smAndUp" nav dense class="d-flex pa-0">
          <v-list-item
            v-for="(link, idx) in menuLinks"
            :key="idx"
            :to="{name: link.routeName}"
            class="mb-0 px-3 px-md-7 text-body-md black--text">
            {{ link.label }}
          </v-list-item>
          <v-list-item class="pl-3 pl-md-7 pr-0">
            <UiBtn color="accent" width="108" height="34" class="text-body-md" :to="{name: 'Login'}">Sign In</UiBtn>
          </v-list-item>
        </v-list>

        <v-menu
          v-if="$vuetify.breakpoint.xsOnly"
          v-model="menu"
          tile
          min-width="100%"
          content-class="app-menu-mobile"
        >
          <template #activator="{on, attrs}">
            <UiBtn icon v-bind="attrs" v-on="on" width="30" height="30">
              <div class="hamburger-icon"/>
            </UiBtn>
          </template>

          <div class="d-flex justify-space-between align-center fill-width px-5 gray-100" style="height: 78px;">
            <router-link :to="{name: 'WelcomeLanding'}" class="d-flex">
              <img width="126" :src="require('@/assets/images/dark/logo.svg')" alt="">
            </router-link>

            <UiBtn icon @click="menu = false">
              <div class="hamburger-icon is-open"/>
            </UiBtn>
          </div>

          <v-list nav dense class="pa-0 gray-100">
            <v-list-item
              v-for="(link, idx) in menuLinks"
              :key="idx"
              :to="{name: link.routeName}"
              class="bdt mb-0 py-6 d-flex justify-center text-body-lg font-weight-medium white--text">
              {{ link.label }}
            </v-list-item>
            <v-list-item class="bdt py-6">
              <UiBtn color="accent" width="100%" height="50" class="text-body-lg" :to="{name: 'Login'}">Sign In</UiBtn>
            </v-list-item>
          </v-list>

        </v-menu>
      </div>

      </div>
  </v-app-bar>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "AppMenu",
  components: {
    UiBtn,
  },
  data() {
    return {
      menu: false,
      menuLinks: [
        {label: "Features", routeName: "FeaturesLanding"},
        {label: "Applications", routeName: "ApplicationsLanding"},
        {label: "Pricing", routeName: "PricingLanding"},
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.app-menu {
  ::v-deep .v-toolbar__content {
    padding: 0;
  }

  ::v-deep .v-list-item--active {
    &::before {
      opacity: 0;
    }
    &:hover::before {
      opacity: 0.04;
    }
  }

  .app-menu-grid {
    max-width: 1384px;
  }

  .app-menu-logo img {
    width: 162px;
    @media screen and (max-width: 620px) {
      width: 126px;
    }
  }
}

.app-menu-mobile {
  top: 0!important;
  box-shadow: 0 4px 27px var(--v-accent-base);

  .bdt {
    border-top: 1px solid var(--v-gray-60-base);
  }
}

.hamburger-icon {
  position: relative;
  height: 2px;
  width: 100%;
  margin: 0 2px;
  border-radius: 2px;
  background-color: var(--v-gray-100-base);

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 2px;
    border-radius: 2px;
    background-color: inherit;
  }

  &::after {
    top: auto;
    bottom: -10px;
  }

  &.is-open {
    transform: rotate(45deg);
    background-color: var(--v-gray-0-base);

    &::before {
      top: 0;
      transform: rotate(90deg);
    }

    &::after {
      display: none;
    }
  }
}

</style>
