export default {

  /**
   *
   * @param commit
   * @param payload
   */
  setProjectDesignsLoading({commit}, payload) {
    commit('SET_PROJECT_DESIGNS_LOADING', payload)
  },


  /**
   *
   * @param commit
   * @param payload
   */
  setProjectDesigns({commit}, payload) {
    commit('SET_PROJECT_DESIGNS', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setProjectDesignsFilters({commit}, payload) {
    commit('SET_PROJECT_DESIGNS_FILTERS', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  toggleFilesCarousel({commit}, payload) {
    commit('TOGGLE_FILES_CAROUSEL', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setActiveDesignModule({commit}, payload) {
    commit('SET_ACTIVE_DESIGN_MODULE', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setActiveStyle({commit}, payload) {
    commit('SET_ACTIVE_STYLE', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setActiveFile({commit}, payload) {
    commit('SET_ACTIVE_FILE', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setAllProjectStyles({commit}, payload) {
    commit('SET_ALL_PROJECT_STYLES', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateDrawModeData({commit}, payload) {
    commit('UPDATE_DRAW_MODE_DATA', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateDrawModeHistory({commit}, payload) {
    commit('UPDATE_DRAW_MODE_HISTORY', payload)
  },
}
