import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import config from "@/config";
import routes from "@/router/routes";
import middlewarePipeline from "@/router/middlewarePipeline";
import layoutMiddleware from "@/router/middleware/layout";

VueRouter.prototype.$updateQueryParams = function (queryParams) {
  const allQueryParams = {...this.currentRoute.query, ...queryParams};
  const newQueryParams = {};

  Object.keys(allQueryParams).forEach(key => {
    newQueryParams[key] = allQueryParams[key]?.toString() || undefined;
  });

  const from = this.currentRoute.fullPath;
  const to = this.resolve({
    query: {
      ...this.currentRoute.query,
      ...newQueryParams,
    }
  }).route.fullPath;

  if (from === to) {
    return;
  }

  // I don't know what the fuck is going on, but for some reason when we replace query params,
  // the showProjectSideDrawer store option gets reset. Therefore, we need to use this temporary hack
  // until we change the user flow :(
  const tempVar = store.state.ProjectSideDrawer.showProjectSideDrawer;

  return this.replace({
    query: newQueryParams,
  }).then(() => {
    if (tempVar) {
      // store.state.ProjectSideDrawer.showProjectSideDrawer = tempVar;
    }
  });
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (to.path === from.path) {
      return savedPosition;
    }
    return { x: 0, y: 0 }
  },
  routes
});

// Save previous page url
router.beforeEach((to, from, next) => {
  const hasRouterName = router.currentRoute.name
  const prevUrl = location.pathname + location.search

  sessionStorage.setItem('prevUrl', hasRouterName && to.name !== from.name ? prevUrl : sessionStorage.getItem('prevUrl') || '')
  next()
})

// Middleware handler
router.beforeEach((to, from, next) => {

  const routeMiddleware = to.meta.middleware ? to.meta.middleware : [];
  const middleware = [layoutMiddleware, ...routeMiddleware];

  const context = {
    to,
    from,
    next,
    store,
  };

  if (!store.getters.getModal('pageLeaveModal').dataSavedStatus) {
    store.dispatch('openModal', {
      modalName: 'pageLeaveModal',
      handlers: {
        onLeave: () => {
          store.getters.getModal('pageLeaveModal').dataSavedStatus = true;
          store.dispatch('closeModal', 'pageLeaveModal');
          router.push(to);
        }
      }
    });
    return;
  }

  // If user leave project pages, we have to close project WS connection and clear side drawer data
  if (from.params.project_id !== undefined && to.params.project_id === undefined) {
    Vue.prototype.$ws.updateOnMessageCallback('projectSideDrawerData', null);
    Vue.prototype.$ws.sendMessage(config.ws.methods.exitFromProject, {project_id: from.params.project_id});

    store.state.Project.isProjectWsConnect = false;
    store.dispatch('clearProjectSideDrawerData');
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });

})

router.afterEach((to, from) => {

  if (from.params.project_id === undefined && to.params.project_id !== undefined && !store.state.Project.isProjectWsConnect) {
    Vue.prototype.$ws.waitForConnection(() => {
      Vue.prototype.$ws.sendMessage(config.ws.methods.connectToProject, {project_id: to.params.project_id});
      store.state.Project.isProjectWsConnect = true;
    }, 100)
  }

  if (to.name !== from.name) {
    // Set page title from meta
    document.title = to.meta?.title || to?.name || to?.fullPath?.replace('/', '');
  }

});

export default router;
