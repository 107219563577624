export default {

  /**
   *
   * @param state
   * @returns *
   */
  getActiveFolder (state) {
    return state.activeFolder
  },

}
