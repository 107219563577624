<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.69266 7.65357L6.47573 12.1196C7.19597 12.7921 8.35942 12.7921 9.07965 12.1196L13.8627 7.65357C15.0262 6.56724 14.1951 4.70496 12.5515 4.70496L2.98538 4.70496C1.34178 4.70496 0.52921 6.56724 1.69266 7.65357Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCaretDown'
}
</script>
