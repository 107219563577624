import config from "../../../config";
import auth from "../../middleware/auth";

export default [
  {
    path: '/account',
    redirect: {name: 'UserProfile'},
    component: () => import(/* webpackChunkName: "AccountPage" */ '@/views/account/AccountPage'),
    children: [
      {
        path: 'profile',
        name: 'UserProfile',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Profile',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'password',
        name: 'UserPassword',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Password',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'billing',
        name: 'UserBilling',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Billing',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'notification',
        name: 'UserNotification',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Notification',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'activity',
        name: 'UserActivity',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Activity',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'download',
        name: 'UserDownload',
        meta: {
          layout: config.layout.app,
          title: 'Account Preferences: Download',
          middleware: [
            auth
          ]
        }
      },
    ]
  }
]
