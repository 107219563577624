<template>
  <div class="app-footer px-10 px-md-16">
    <div class="app-footer-grid mx-auto">

      <div class="d-flex flex-column flex-sm-row justify-space-between">
        <div class="app-footer-logo">
          <img v-if="$vuetify.breakpoint.smAndUp" width="160" :src="require('@/assets/images/light/logo.svg')" alt="">
          <img v-else width="160" :src="require('@/assets/images/dark/logo.svg')" alt="">

          <p class="my-6 text-body-lg">Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam</p>

          <ul class="d-flex align-center pa-0">
            <li v-for="(link, idx) in socialLinks" :key="idx">
              <a class="d-flex align-center mr-6 text-decoration-none" :href="link.url" :title="link.label" target="_blank">
                <component :is="link.icon" width="20" height="20" class="accent--text"/>
              </a>
            </li>
          </ul>
        </div>

        <div v-if="$vuetify.breakpoint.lgAndUp" class="app-footer-links-col">
          <h5 class="mb-10 font-weight-bold text-title-2">Product</h5>
          <ul class="pa-0">
            <li class="mb-5" v-for="(link, idx) in productLinks" :key="idx">
              <router-link :to="{name: link.routeName}" class="text-decoration-none">
                <span class="text-body-lg">{{ link.label }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="$vuetify.breakpoint.lgAndUp" class="app-footer-links-col">
          <h5 class="mb-10 font-weight-bold text-title-2">Company</h5>
          <ul class="pa-0">
            <li class="mb-5" v-for="(link, idx) in companyLinks" :key="idx">
              <router-link :to="{name: link.routeName}" class="text-decoration-none">
                <span class="text-body-lg">{{ link.label }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div v-if="$vuetify.breakpoint.lgAndUp" class="app-footer-links-col">
          <h5 class="mb-10 font-weight-bold text-title-2">Support</h5>
          <ul class="pa-0">
            <li class="mb-5" v-for="(link, idx) in supportLinks" :key="idx">
              <router-link :to="{name: link.routeName}" class="text-decoration-none">
                <span class="text-body-lg">{{ link.label }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <div class="app-footer-links-col app-footer-contacts mt-12 mt-sm-0">
          <h5 class="mb-10 font-weight-bold text-title-2">Contacts</h5>
          <ul class="pa-0">
            <li class="mb-5">
              <a href="mailto:contact@company.com" class="text-decoration-none d-flex align-start">
                <EnvelopeIcon class="mr-2" width="20" style="margin-top: 3px;"/>
                <span class="text-body-lg">contact@company.com</span>
              </a>
            </li>
            <li class="mb-5">
              <a href="tel:+4146875892" class="text-decoration-none d-flex align-start">
                <PhoneIcon class="mr-2" width="18"/>
                <span class="text-body-lg">(414) 687 - 5892</span>
              </a>
            </li>
            <li class="mb-5">
              <a href="https://goo.gl/maps/ddFoVyHM4SKNR6ki7" target="_blank" class="text-decoration-none d-flex align-start">
                <LocationIcon class="mr-2" width="18"/>
                <span class="text-body-lg">794 Mcallister St <br> San Francisco, 94102</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="app-sub-footer d-flex justify-space-between flex-wrap fill-width pt-6 pb-12 pb-sm-2">
        <p class="mb-0 mb-sm-4 mt-6 mt-sm-0 text-body-lg order-2 order-sm-0">Copyright © {{ copyrightYear }} UpSway</p>

        <ul class="d-sm-flex align-center flex-wrap flex-sm-nowrap pa-0 mb-sm-4">
          <li class="d-inline d-sm-flex text-sm-no-wrap text-body-lg">
            <span class="mx-1">All Rights Reserved</span> |
          </li>
          <li class="d-inline d-sm-flex text-sm-no-wrap text-body-lg">
            <span class="mx-1 accent--text text-decoration-underline cursor-pointer">Terms and Conditions</span> |
          </li>
          <li class="d-inline d-sm-flex text-sm-no-wrap text-body-lg">
            <router-link class="mx-1 accent--text text-decoration-underline cursor-pointer" :to="{name: 'PrivacyPolicy'}">Privacy Policy</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  components: {
    IconFacebook: () => import('@/components/icons/IconFacebook'),
    IconTwitter: () => import('@/components/icons/IconTwitter'),
    IconInstagram: () => import('@/components/icons/IconInstagram'),
    IconLinkedIn: () => import('@/components/icons/IconLinkedIn'),
    IconYouTube: () => import('@/components/icons/IconYouTube'),
    EnvelopeIcon: () => import('@/components/icons/IconEnvelopeSolid'),
    PhoneIcon: () => import('@/components/icons/IconPhoneSolid'),
    LocationIcon: () => import('@/components/icons/IconLocationSolid'),
  },
  data() {
    return {
      copyrightYear: new Date().getFullYear(),
      socialLinks: [
        {label: "Facebook", icon: "IconFacebook", url: ""},
        {label: "Twitter", icon: "IconTwitter", url: ""},
        {label: "Instagram", icon: "IconInstagram", url: ""},
        {label: "LinkedIn", icon: "IconLinkedIn", url: ""},
        {label: "YouTube", icon: "IconYouTube", url: ""},
      ],
      productLinks: [
        {label: "Features", routeName: "FeaturesLanding"},
        {label: "Pricing", routeName: "PricingLanding"},
        {label: "Case studies", routeName: ""},
        {label: "Reviews", routeName: ""},
        {label: "Updates", routeName: ""},
      ],
      companyLinks: [
        {label: "About", routeName: ""},
        {label: "Contact us", routeName: ""},
        {label: "Careers", routeName: ""},
        {label: "Culture", routeName: ""},
        {label: "Blog", routeName: ""},
      ],
      supportLinks: [
        {label: "Getting started", routeName: ""},
        {label: "Help center", routeName: ""},
        {label: "Server status", routeName: ""},
        {label: "Report a bug", routeName: ""},
        {label: "Chat support", routeName: ""},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.app-footer {
  padding-top: 100px;
  border-top: 1.05px solid var(--v-gray-15-base);
  color: var(--v-accent-40-base);

  ul {
    list-style-type: none;
  }

  .app-footer-logo {
    max-width: 326px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-top: 56px;
    border-top: none;
    color: var(--v-gray-0-base);
    background-color: var(--v-gray-100-base);
  }

  .app-footer-grid {
    max-width: 1384px;
  }

  .app-footer-links-col {
    h5,
    svg {
      color: var(--v-accent-800-base);

      @media #{map-get($display-breakpoints, 'xs-only')} {
        color: var(--v-accent-base);
      }
    }

    a {
      color: var(--v-accent-40-base);
      &:hover span {
        text-decoration: underline;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        color: var(--v-gray-0-base);
      }
    }
  }

  .app-sub-footer {
    margin-top: 100px;
    border-top: 1px solid var(--v-gray-15-base);

    li {
      color: var(--v-accent-40-base);
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-top: 16px;
      border-top: none;
      background-color: var(--v-gray-100-base);

      li {
        color: var(--v-gray-0-base);
      }
    }
  }
}
</style>
