<template>
  <v-dialog
    v-model="getModal('moveProjectFolderModal').isOpen"
    max-width="416"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
    :persistent="submitLoading"
    content-class="create-new-task-modal"
    :scrollable="true"
  >
    <div class="relative gray-0 fill-height">
      <div class="pt-7 px-4 px-sm-7">
        <div class="d-flex align-center justify-space-between">
          <h5 class="text-title-2 gray-100--text">
            <template v-if="modalData.type === 'project'">Move Project</template>
            <template v-else-if="modalData.type === 'folder'">Move Folder</template>
          </h5>

          <UiBtn
            fab
            color="gray-0"
            @click="hideModal"
          >
            <IconCancel width="16" class="gray-60--text"/>
          </UiBtn>
        </div>

        <v-divider class="mt-3 mb-4"/>

<!--        <div class="mb-2 text-body text-capitalize gray-100&#45;&#45;text">Search for a space to move your project</div>-->
<!--        <div class="d-flex align-center mb-4">-->
<!--          <v-text-field-->
<!--            v-model.trim="search"-->
<!--            dense-->
<!--            outlined-->
<!--            hide-details-->
<!--            placeholder="Search"-->
<!--            :disabled="submitLoading || true"-->
<!--            class="search-input"-->
<!--          >-->
<!--            <template #prepend-inner>-->
<!--              <IconSearch width="12" class="gray-60&#45;&#45;text"/>-->
<!--            </template>-->
<!--          </v-text-field>-->
<!--        </div>-->
        <div class="text-body font-weight-semi-bold mb-2">Spaces</div>
      </div>

      <ValidationObserver tag="div" ref="form" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(onMove)">
          <div class="gray-10 px-4 px-sm-7 py-2">

            <v-list class="mt-3 menu-list menu-list-spaces">
              <ValidationProvider
                mode="eager"
                slim
                name="Folder"
                vid="title"
                rules="required"
                v-slot="{ errors }"
                :custom-messages="{required: 'Please select Space'}"
              >
                <v-list-item-group
                  v-model="selectedSpaceId"
                  mandatory
                >
                  <v-list-item
                  :ripple="space.id !== space?.id"
                  class="space-list-item"
                  :class="[ {'v-list-item--active': space.id  === selectedFolder?.space_id}]"
                  :value="space.id"
                  :disable="space.id === modalData.spaceId && !modalData.folderId"
                >
                  <v-list-item-icon class="align-self-center mr-1 mt-0 mb-0">
                    <v-sheet rounded="circle" :color="getActiveSpace.color" width="14" height="14"></v-sheet>
                  </v-list-item-icon>
                  <v-list-item-action class="mt-0 mb-0 mr-2">
                    <UiBtn
                      v-show="space.folders?.length"
                      class="space-list-item-icon"
                      icon
                      width="auto"
                      @click.prevent="openSpace(space)"
                    >
                      <IconCaretDown width="6"/>
                    </UiBtn>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="gray-100--text">
                      {{ space.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                </v-list-item-group>
                <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
              </ValidationProvider>

              <v-expand-transition>
                <v-list v-if="space.isOpenFolders" class="py-0 menu-list">
                  <ValidationProvider
                    mode="eager"
                    slim
                    name="Folder"
                    vid="title"
                    :rules="selectedSpaceId === modalData.spaceId && !modalData.folderId ? 'required' : ''"
                    :custom-messages="{required: 'Please select Folder'}"
                    v-slot="{ errors }"
                  >
                    <v-list-item-group
                      v-model="selectedFolderId"
                    >
                      <v-list-item
                        v-for="(folder, idx) in space.folders" :key="idx"
                        :ripple="space"
                        class="pl-5 mt-1"
                        :class="[ {'v-list-item--active': folder.id  === selectedFolder?.id}]"
                        :value="folder.id"
                        :disabled="folder.id === modalData.folderId"
                      >
                        <v-list-item-icon class="align-self-center mr-2 mt-0 mb-0">
                          <div class="folder-icon flex-shrink-0 d-flex">
                            <IconFolderMini width="15" class="flex-shrink-0" :style="{color: space.color}"/>
                          </div>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="gray-100--text">
                            {{ folder.title }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                    <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
                  </ValidationProvider>

                </v-list>
              </v-expand-transition>
            </v-list>
          </div>
          <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3 px-4 px-sm-7 pb-6">
            <UiBtn
              outlined
              width="92"
              color="gray-60"
              class="mt-2"
              :disabled="submitLoading"
              @click="hideModal"
            >
              Cancel
            </UiBtn>
            <UiBtn
              type="submit"
              width="122"
              color="accent"
              :loading="submitLoading"
              class="mt-2"
            >
              Move
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import IconFolderMini from "@/components/icons/IconFolderMini.vue";
import IconCaretDown from "@/components/icons/IconCaretDown.vue";

export default {
  name: 'MoveProjectFolderModal',
  components: {
    IconCaretDown,
    IconFolderMini,
    IconCancel: () => import('@/components/icons/IconCancel'),
    UiBtn,
  },
  data() {
    return {
      dataLoading: true,
      submitLoading: false,
      search: '',
      space: null,
      selectedSpaceId: null,
      selectedFolderId: null,
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
      'getActiveSpace',
      'getSpaces',
    ]),
    modalData() {
      return this.getModal('moveProjectFolderModal').data;
    },
    modalActions() {
      return this.getModal('moveProjectFolderModal').handlers;
    },
    selectedFolder() {
      return this.getActiveSpace.folders.find(folder => folder.id === this.selectedFolderId) || null
    },
    selectedSpace() {
      return this.getActiveSpace
    },
  },
  watch: {
    'getActiveSpace': {
      handler(val) {
        this.space = {
          ...val,
          isOpenFolders: true,
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onMove() {
      const descriptionPlaceName = this.selectedFolder ? this.selectedFolder.title : this.selectedSpace.title;
      const descriptionPlace = this.selectedFolder ? 'folder' : 'space';

      this.$store.dispatch("openModal", {
        modalName: "confirmModal",
        data: {
          title: "Move project",
          confirmBtnText: "Confirm",
          confirmBtnIcon: '',
          confirmBtnColor: 'accent',
          cancelBtnColor: 'gray-60',
          showIconCross: true,
          actionBtnsPosition: 'right',
          modalMaxWidth: '416',
          descriptionAlign: 'left',
          description: `Are you sure want to move ${this.modalData.ids?.length === 1 ? 'this project' : 'these projects'} to '${descriptionPlaceName}' ${descriptionPlace}?`,
          descriptionColor: 'gray-100--text',
          loading: false,
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch("closeModal", "confirmModal");
          },
          onConfirm: async () => {
            await this.$store.dispatch("closeModal", "confirmModal");
            await this.onMoveConfirm()
          },
        },
      });
    },
    async onMoveConfirm() {
      try {
        this.submitLoading = true;

        const payload = {
          project_ids: this.modalData.ids,
          folder_id: this.selectedFolderId || null,
          space_id: this.selectedSpaceId
        }
        await this.$api.folder.move(payload)
        this.modalActions.onUpdate();

        this.$toast.open({
          message: 'Project was moved',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: 'Oops! Something went wrong!',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.submitLoading = false;
        if (this.getModal('moveProjectFolderModal').isOpen) {
          this.hideModal()
        }
      }
    },
    hideModal() {
      this.modalActions?.onClose();
      this.$store.dispatch('closeModal', 'moveProjectFolderModal');
    },
    openSpace(space) {
      space.isOpenFolders = !space.isOpenFolders
    },
  }
}
</script>

<style lang="scss" scoped>
.create-new-task-modal.v-dialog {
  overflow-y: auto;
}

.search-input {
  :deep(.v-input__slot) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.menu-list {
  &::v-deep {
    .v-list-item {
      border-radius: 5px;
      padding: 6px 9px;
      transition: 0.3s;
      min-height: 30px;

      &.space-action {
        background-color: transparent !important;
        padding-right: 0;
        padding-left: 0;

        .v-btn {
          background: #ffffff !important;
        }
      }

      &__content {
        padding: 0;
      }

      &__title {
        font-size: 14px;
      }

      &__icon {
        margin-left: 0 !important;
      }

      &__icon, &__action {
        min-width: unset;
      }


      &::before, &::after {
        display: none;
      }

      &:hover {
        background-color: var(--v-gray-0-base);
      }

      &--active {
        background-color: var(--v-gray-30-base);

        &:hover {
          background-color: var(--v-gray-30-base);
        }
      }
    }

    &.v-list {
      padding: 20px 0;

      &.mini {
        padding: 20px 0;

        .v-list-item {
          &:hover {
            background-color: var(--v-gray-60-base);

            i {
              color: var(--v-gray-0-base) !important;
            }
          }

          &--active {
            &:hover {
              background-color: var(--v-gray-30-base);

              i {
                color: var(--v-gray-80-base) !important;
              }
            }
          }
        }
      }
    }
  }

  &-spaces {
    padding: 0 !important;

    &:not(&.mini) {
      background-color: #ffffff;
      border-radius: 5px;
      overflow: hidden;
      padding: 8px !important;
    }

    &::v-deep {
      .v-list-item {
        border-radius: 0;
      }
    }
  }
}

.space-list-item {
  position: relative;
  border: 1px solid #F5F5F5;
}

.space-list-item-icon {
  height: 16px;
  padding: 0 6px;
}
</style>
