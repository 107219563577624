import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import LightTheme from './themes/light'
import DarkTheme from './themes/dark'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: LightTheme,
      dark: DarkTheme
    }
  }
})
