export default {

  /**
   *
   * @param state
   * @returns {[]}
   */
  getDashboardProjectsLoading(state) {
    return state.projectsLoading
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getDashboardFilters(state) {
    return state.filters
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  getProjectsList(state) {
    return state.projects
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  getUserColumnSettings(state) {
    return state.userColumnSettings
  }

}
