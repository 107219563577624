import { getDefaultProjectState } from "./index";

export default {

  /**
   * Set project data
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACTIVE_FOLDER (state, payload) {
    state.activeFolder = payload
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_ACTIVE_FOLDER(state, payload) {
    state.activeFolder = Object.assign(state.activeFolder, payload)
  },

  /**
   *
   * @param state
   * @constructor
   */
  DELETE_ACTIVE_FOLDER(state) {
    state.activeFolder = null
  },
}
