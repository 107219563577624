import { getDefaultProjectState } from "./index";

export default {

  /**
   * Set project data
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PROJECT (state, payload) {
    state.project = payload
  },

  /**
   *
   * @param state
   * @constructor
   */
  SET_DEFAULT_PROJECT_DATA (state) {
    state.project = getDefaultProjectState()
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_PROJECT(state, payload) {
    state.project = Object.assign(state.project, payload)
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_TEMPLATES(state, payload) {
    state.templates = payload
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_USER_TAGS(state, payload) {
    state.userTags = payload
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_DESIGN_MODULES(state, payload = []) {
    state.designModules = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_DESIGN_MODULE(state, payload) {
    if (!payload.id) {
      throw new Error('"UPDATE_DESIGN_MODULE" - module id is required');
    }
    state.designModules = state.designModules.map(module => module.id === payload.id ? {...module, ...payload} : module);
    state.project = {
      ...state.project,
      modules: state.project.modules.map(module => module.id === payload.id ? {...module, ...payload} : module),
    }
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },
}
