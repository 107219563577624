export default axios => ({

  /**
   * Get project permissions
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Permissions-GetApiV1ProjectsPermissions
   * @returns {*}
   */
  permissions(space_id) {
    return axios.get(`/projects/permissions${space_id ? '?space_id=' + space_id : ''}`)
  },

  /**
   * Get projects list
   *
   * @link https://devapi.upsway.io/apidoc/#api-7._Projects-GetApiV1Projects
   * @param params
   * @returns {*}
   */
  list(params = {}) {
    return axios.get(`/projects`, {params});
  },

  /**
   * Create new project
   *
   * @link https://devapi.upsway.io/apidoc/#api-7._Projects-PostApiV1Projects
   * @param data
   * @returns {*}
   */
  create(data) {
    return axios.post(`/projects`, data)
  },

  /**
   * Update project data
   *
   * @param project_id
   * @param data
   * @returns {*}
   */
  update(project_id, data) {
    return axios.put(`/projects/${project_id}`, data)
  },

  /**
   * Get project by project ID
   *
   * @link https://devapi.upsway.io/apidoc/#api-7._Projects-GetApiV1ProjectsId
   * @param project_id
   * @returns {*}
   */
  get(project_id) {
    return axios.get(`/projects/${project_id}`)
  },

  /**
   * Update project Team
   *
   * @link https://devapi.upsway.io/apidoc/#api-7._Projects-PatchApiV1ProjectsIdTeam
   * @param project_id
   * @param data
   * @returns {*}
   */
  updateTeam(project_id, data) {
    return axios.patch(`/projects/${project_id}/team`, data)
  },

  /**
   * Update project Guideline
   *
   * @link https://devapi.upsway.io/apidoc/#api-7._Projects-PatchApiV1ProjectsIdGuides
   * @param project_id
   * @param data
   * @returns {*}
   */
  updateGuideline(project_id, data) {
    return axios.patch(`projects/${project_id}/guides`, data);
  },

  /**
   * Upload attachments files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-PostApiV1ProjectsIdAttachment
   * @param project_id
   * @param data
   * @returns {*}
   */
  uploadAttachmentFile(project_id, data) {
    return axios.post(`projects/${project_id}/attachment`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /**
   * Update attachments files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-PatchApiV1ProjectsIdAttachments
   * @param project_id
   * @param data
   * @returns {*}
   */
  updateAttachment(project_id, data) {
    return axios.patch(`projects/${project_id}/attachments`, data)
  },

  /**
   * Update project module
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-PutApiV1ProjectsIdModulesProjectmoduleid
   * @param project_id
   * @param module_id
   * @param data
   * @returns {*}
   */
  updateProjectModule(project_id, module_id, data) {
    return axios.put(`/projects/${project_id}/modules/${module_id}`, data)
  },
  /**
   * Update project module
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Projects-PostApiV1ProjectsModulesProjectmoduleidEditTitle

   * @param module_id
   * @param data
   * @returns {*}
   */
  updateProjectModuleTitle(module_id, data) {
    return axios.post(`/projects/modules/${module_id}/edit-title`, data)
  },

  /**
   * Delete project by project ID
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-DeleteApiV1ProjectsId
   * @param project_id
   * @returns {*}
   */
  delete(project_id) {
    return axios.delete(`/projects/${project_id}`)
  },

  /**
   * Bulk delete projects by ID
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-DeleteApiV1ProjectsBatchDelete
   * @param data
   * @returns {*}
   */
  bulkDelete(data) {
    return axios.delete(`/projects/batch-delete`, {data})
  },

  /**
   * Duplicate project by project ID
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-PostApiV1ProjectsIdDuplicate
   * @param project_id
   * @returns {*}
   */
  duplicate(project_id) {
    return axios.post(`/projects/${project_id}/duplicate`)
  },

  /**
   * Update attachments files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-PutApiV1ProjectsIdStatusArchived
   * @param project_id
   * @param status ('progress', 'review',  'rejected',  'approved',  'archived',  'rollback',)
   * @returns {*}
   */
  updateStatus(project_id, status) {
    return axios.put(`projects/${project_id}/status/${status}`)
  },

  /**
   * Update attachments files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-PutApiV1ProjectsStatusBatchArchived
   * @param status ('progress', 'review',  'rejected',  'approved',  'archived',  'rollback',)
   * @param ids
   * @returns [{*}]
   */
  bulkUpdateStatus(status, ids) {
    return axios.put(`/projects/status/batch-${status}`, {ids});
  },

  /**
   *
   * Share project
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-DeleteApiV1ProjectsIdShare
   * @param project_id
   * @param data
   * @returns {*}
   */
  share(project_id, data) {
    return axios.post(`projects/${project_id}/share`, data)
  },

  /**
   * Bulk projects share
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Projects-DeleteApiV1ProjectsBatchShare
   * @param data
   * @returns {*}
   */
  bulkShare(data) {
    return axios.post(`projects/batch-share`, data)
  },

  /**
   * Request review for style files
   *
   * @param project_id
   * @param data
   * @returns {*}
   */
  requestReview(project_id, data) {
    return axios.post(`projects/${project_id}/review-request`, data)
  },

  /**
   * Request review for style files
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Requests_Review-GetApiV1Projects1RequestReview
   * @param project_id
   * @param params
   * @returns {*}
   */
  getFilesToReview(project_id, params) {
    return axios.get(`projects/${project_id}/request-review`, {params})
  },

  /**
   * Request review for style files
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Requests_Review-PutApiV1Projects1RequestReview
   * @param project_id
   * @param data
   * @returns {*}
   */
  reviewFiles(project_id, data) {
    return axios.put(`projects/${project_id}/request-review`, data)
  },

  /**
   * Get projects design files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-GetApiV1ProjectsProject_idItems
   * @param url
   * @param params
   * @returns {*}
   */
  getProjectDesignFiles(url, params = {}) {
    if (!url) return
    return axios.get(url, {
      params
    });
  },

  /**
   * Delete projects design files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-DeleteApiV1ProjectsProject_idItemsProject_item_id
   * @param project_id
   * @param item_id
   * @returns {*}
   */
  deleteProjectDesignFiles(project_id, item_id) {
    return axios.delete(`/projects/${project_id}/items/${item_id}`);
  },

  /**
   * Get projects design files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-7._Projects-GetApiV1ProjectsProject_idItems
   * @param url
   * @param params
   * @returns {*}
   */
  getProjectSideDrawerLogHistory(url, params = {}) {
    if (!url) return
    return axios.get(url, {
      params
    });
  },


  /**
   * Get projects shared modules
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Projects-GetApiV1ProjectsProductidModulesShared
   * @param project_id
   * @returns {*}
   */
  getProjectsSharedModules(project_id) {
    return axios.get(`/projects/${project_id}/modules/shared`)
  },

  /**
   * Get module users
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Projects-GetApiV1ProjectsModulesProjectmoduleidSharedWith
   * @param projectModuleId
   * @returns {*}
   */
  getModuleUsers(projectModuleId) {
    return axios.get(`projects/modules/${projectModuleId}/shared-with`);
  },

  /**
   * Share Project and Module
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Projects-PostApiV1ProjectsModulesProjectmoduleidSyncUsersToShare
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  shareProjectAndModule(projectModuleId, data) {
    return axios.post(`projects/modules/${projectModuleId}/sync-users-to-share`, data);
  },

  /**
   * Download style files
   *
   * @param {string} project_id
   * @returns {Promise}
   */
  downloadsAttachmentsFiles(project_id) {
    return axios.get(`/projects/${project_id}/attachments-download`, {responseType: "arraybuffer", skipResponseInterceptor: true})
  },

  /**
   * Get Project Workflow Scheme
   *
   * @param {string} project_id
   * @returns {Promise}
   */
  getProjectWorkflowScheme(project_id) {
    return axios.get(`/projects/${project_id}/scheme`)
  },

  /**
   * Update Project Workflow Scheme
   *
   * @param {string} project_id
   * @param data
   * @returns {Promise}
   */
  updateProjectWorkflowScheme(project_id, data) {
    return axios.patch(`/projects/${project_id}/scheme`, data)
  },

  /**
   * Get Projects Empty Modules
   *
   * @param {Array} project_ids
   * @returns {Promise}
   */
  getProjectsEmptyModules(project_ids) {
    return axios.get(`/projects/empty-modules`, {params: {ids: project_ids}})
  },

  /**
   * Set Projects as Complete
   *
   * @param {Array} project_ids
   * @returns {Promise}
   */
  setProjectsComplete(project_ids) {
    return axios.patch(`/projects/complete`, {ids: project_ids})
  },

  /**
   * Get List of projects & folders
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Projects-Folders-GetApiV1ProjectsFolders
   * @param params
   * @returns {*}
   */
  getProjectsAndFolders(params = {}) {
    return axios.get(`/projects-folders`, {params});
  },

  /**
   * Set Projects as Complete
   *
   * @param project_id
   * @param module_id
   * @returns {Promise}
   */
  downloadSummary(project_id, module_id) {
    return axios.post(`/projects/${project_id}/modules/${module_id}/design/summary`)
  },
})
