import {STORAGE_ADMIN_TOKEN_KEY, STORAGE_AUTH_KEY, STORAGE_TEMP_AUTH_KEY} from "@/store/modules/Auth/index";

export default {
  SET_AUTH(state, payload) {
    state.auth = payload
    localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(payload))
  },
  SET_TEMP_AUTH(state, payload) {
    state.temp_auth = payload
    sessionStorage.setItem(STORAGE_TEMP_AUTH_KEY, JSON.stringify(payload))
  },
  SET_ADMIN_TOKEN(state, payload) {
    state.admin_token = payload
    sessionStorage.setItem(STORAGE_ADMIN_TOKEN_KEY, JSON.stringify(payload))
  },
  LOGOUT(state) {
    state.auth = null
    localStorage.removeItem(STORAGE_AUTH_KEY)
  },
  LOGOUT_TEMP_AUTH(state) {
    state.temp_auth = null
    sessionStorage.removeItem(STORAGE_TEMP_AUTH_KEY)
  },
  LOGOUT_ADMIN(state) {
    state.admin_token = null
    sessionStorage.removeItem(STORAGE_ADMIN_TOKEN_KEY)
  },
  SET_USER(state, payload) {
    state.user = payload;
    state.user.settings.user_theme = 0; // for now there will be only light theme always
  },
  UPDATE_USER(state, payload) {
    state.user = {...state.user, ...payload};
  },
  SET_USER_SETTING(state, payload) {
    state.user.settings = payload
  },
  SET_ROLES_LIST(state, payload) {
    state.rolesList = payload
  }
}
