import {chatConfig} from "@/config/_chatConfig"
export default {

  /**
   *
   * @param state
   * @returns {*}
   */
  getNotifications(state) {
    return (type) => type === chatConfig.notifications.notificationType.messages ? state.messages : state.notifications
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  unreadNotifications(state) {
    return state.notifications.data.some(notification => notification.read_at === null);
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  unreadMessages(state) {
    return state.messages.data.some(notification => notification.read_at === null);
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectsNotifications(state) {
    return state.projectsNotifications;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getPushNotification(state) {
    return state.pushNotification;
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getNotificationBarType(state) {
    return state.notificationBarType
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getNotificationsSort(state) {
    return state.notificationBarSort
  },
  /**
   *
   * @param state
   * @returns {*}
   */
  getMessagesSort(state) {
    return state.messagesBarSort
  },
}
