<template>
  <v-layout column>
    <v-main>
      <router-view/>
    </v-main>
  </v-layout>
</template>

<script>

export default {
  name: 'AuthLayout',
}
</script>
