export default {
  methods: {
    setNotificationsLink(notification) {
      switch (notification.type) {
        case 'system': case 'project-archived': case 'project-unarchived': case 'project-delete': {
          return null;
        }
        case 'design-change-due-date': {
          return {
            url: null,
            callback: () => {
              this.$store.dispatch('toggleNotificationBar', {open: false});
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: notification.additional_data.project_id},
                query: {module: notification.additional_data.module_id}
              });
            }
          }
        }
        case 'design-request-review-design': {
          return {
            url: null,
            callback: () => {
              this.$store.dispatch('toggleNotificationBar', {open: false});
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: notification.additional_data.project_id},
                query: {openReview: notification.additional_data.open_review},
              });
            }
          }
        }
        case 'design-assignee-design': {
          return {
            url: null,
            callback: () => {
              this.$store.dispatch('toggleNotificationBar', {open: false});
              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: notification.additional_data.project_id},
                query: {
                  module: notification.additional_data.module_id,
                  tags: notification.additional_data.tag_id.toString(),
                  tagsList: notification.additional_data.tag_id.toString(),
                }
              });
            }
          }
        }
        case 'task-assigned': case 'task-change-status': case 'task-reminder': case 'mention-in-task': case this.$config.notifications.types.mentionInTaskFile: {
          return {
            url: null,
            callback: async () => {
              await this.$store.dispatch('toggleNotificationBar', {open: false});

              let queryParams = {
                modal_task_id: notification.additional_data.task_id,
                style_id: notification.additional_data.style_id
              }

              if (this.$route.name === 'ProjectDesign') {
                queryParams = {...this.$route.query, ...queryParams};
              }

              this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: notification.additional_data.project_id},
                query: queryParams
              });
            }
          }
        }
        case 'user-tagged-in-message': {
          return {
            url: null,
            callback: async () => {
              await this.$store.dispatch('toggleNotificationBar', {open: false});
              const filters = {
                project_tag_id: notification.additional_data.tag_id,
                project_style_id: notification.additional_data.style_id,
                project_module: notification.additional_data.module_id,
              };

              await this.$router.replace({
                name: 'ProjectDesign',
                params: {project_id: notification.additional_data.project_id},
              });

              if (this.$store.state.ProjectSideDrawer.initialRender) {
                await this.$store.dispatch('setProjectSideDrawerFilters', filters);
              }

              setTimeout(async () => {
                await this.$store.dispatch('toggleProjectSideDrawer', true);

                if (!this.$store.state.ProjectSideDrawer.initialRender) {
                  this.$eventBus.$emit('updateSidDrawerFilters', filters);
                  await this.$store.dispatch('setProjectSideDrawerFilters', filters);
                }
              }, 250);

              this.$store.state.ProjectSideDrawer.tabs = 'activities';
            }
          }
        }
        case 'mention-in-comment': case 'mention-task-file': {
          return {
            url: null,
            callback: async () => {
              this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.task;
              await this.$store.dispatch('toggleNotificationBar', {open: false});

              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: notification.additional_data.project_id},
                query: {
                  module_id: notification.additional_data.module_id,
                  style_id: notification.additional_data.style_id,
                  file_id: notification.additional_data.file_id,
                  comment_id: notification.additional_data.comment_id,
                  task_id: notification.additional_data.task_id,
                  view_mode: this.$config.project.designModes.task,
                }
              });
            }
          }
        }
        case 'new-comment': {
          return {
            url: null,
            callback: () => {
              this.$store.dispatch('toggleNotificationBar', {open: false});
              this.$router.replace({
                name: 'DesignViewPage',
                params: {project_id: notification.additional_data.project_id},
                query: {
                  module_id: notification.additional_data.module_id,
                  style_id: notification.additional_data.style_id,
                  file_id:notification.additional_data.file_id,
                  gallery_group_id:notification.additional_data.gallery_group_id,
                  comment_id:notification.additional_data.comment_id,
                }
              });
            }
          }
        }
      }
    },
  },
}
