<template>
  <UiMenu
    v-model="isOpen"
    offset-y
    left
    :min-width="menuWidth"
    :max-width="menuWidth"
    :nudge-bottom="8"
    max-height="300"
    :close-on-content-click="false"
    :disabled="disabled"
    :zIndex="zIndex"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="[attrs, $attrs]" v-on="{...on, ...$listeners}">
        <slot name="input" :assignedUser="assignedUser" :isOpen="isOpen"></slot>
      </div>
    </template>

    <v-list nav dense color="gray-10">
      <div class="py-2">
        <v-text-field
          v-model="searchAssignee"
          prepend-inner-icon=""
          solo
          dense
          hide-details
          placeholder="Search"
          class="search-input"
        >
          <template #prepend-inner>
            <IconSearch width="14" class="gray-60--text ml-2"/>
          </template>
        </v-text-field>
      </div>
      <v-list-item
          v-for="(user, index) in searchItems"
          :key="index"
          @click="selectUser(user.user)"
        >
        <v-list-item-title class="text-caption-1">
          <UiAvatar
            :src="user.user.avatar ? user.user.avatar.url : ''"
            :text="user.user.first_name"
            size="22"
            class="mr-2"
          />
          {{ user.user.first_name }} {{ user.user.last_name }}
        </v-list-item-title>
        <v-list-item-icon v-if="user.user_id === value.id">
          <IconCheck width="14" class="accent--text"/>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiAvatar from "@/components/UI/UiAvatar";

export default {
  name: "UISelectAssignee",
  components: {
    IconSearch: () => import('@/components/icons/IconSearch'),
    IconCheck: () => import('@/components/icons/IconCheck'),

    UiMenu,
    UiAvatar,
  },
  props: {
    assigneeList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    menuWidth: {
      type: [String, Number],
      default: 220,
    },
    zIndex: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      searchAssignee: '',
      isOpen: false,
      selectedUser: 0
    }
  },
  computed: {
    valueReactive: {
      get: function () {
        return this.value
      },
      set: function (val) {
        this.$emit('input', val)
      }
    },
    searchItems() {
      const items = this.assigneeList
      if (!this.searchAssignee.trim().length) return items;
      return items.filter(item => {
        const userFullname = `${item?.user.first_name} ${item?.user.last_name}`
        return userFullname.toLowerCase().includes(this.searchAssignee.toLowerCase().trim())
      });
    },
    assignedUser() {
      return this.assigneeList.find(user => {
        return user.user_id === this.valueReactive.id
      });
    },
  },
  methods: {
    selectUser(id) {
      this.$emit('input', id)
      this.isOpen = false
      this.searchAssignee = ''
    }
  }

}
</script>

<style scoped lang="scss">
.search-input {
  height: 27px;
  &::v-deep {
    .v-input__slot {
      padding-left: 0 !important;
      min-height: 28px;
      box-shadow: none !important;
      border: 1px solid var(--v-gray-60-base);
      font-size: 12px;
      input {
        padding-top: 2px;
        caret-color: var(--v-gray-60-base) !important;
      }
    }
    .v-input__icon {
      i {
        font-size: 12px;
        color: var(--v-gray-60-base) !important;
      }
    }
  }
}
</style>
