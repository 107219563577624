export default axios => ({

  /**
   * Get categories list
   * @link https://devapi.upsway.io/apidoc/#api-3._Categories-GetApiV1Categories
   * @returns {*}
   */
  list () {
    return axios.get('/categories')
  },
})
