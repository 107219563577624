<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :menu-props="{contentClass: contentClass, offsetY: true, left: true, minWidth: minWidth, ...menuProps}"
    class="priority-select mt-0"
  >
    <template v-slot:selection="{ item }">
      <div class="d-flex fill-width">
        <UiPriorityIcon :size="15" :priority="item" class="flex-shrink-0" />
        <span v-if="textLabel" class="text-overflow-ellipsis overflow-hidden text-no-wrap text-captions-1 ml-2">{{textLabel}}</span>
      </div>
    </template>
    <template v-slot:item="{ item }">
      <div class="text-captions-1 d-flex align-center">
          <UiPriorityIcon :size="15" :priority="item" class="flex-shrink-0 mr-2" />
        {{ $config.tasks.priorityLabels[item] }}
      </div>
    </template>
    <template #append>
      <slot name="append"/>
    </template>
  </v-select>
</template>

<script>
  export default {
    name: 'UiPrioritySelect',
    components: {
      UiPriorityIcon: () => import('@/components/icons/IconPriority'),
    },
    props: {
      minWidth: {
        type: Number,
        default: 150
      },
      contentClass: {
        type: String,
        default: 'priority-select-menu'
      },
      textLabel: {
        type: String,
        default: ''
      },
      menuProps: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>
<style lang="scss">
.priority-select-menu {
  .v-list {
    .primary--text {
      color: var(--v-accent-base) !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.priority-select {
  &::v-deep {
    .v-input__slot {
      &::before, &::after {
        display: none;
      }
      input {
        display: none;
      }
    }
  }
}

</style>
