export default axios => ({

  /**
   * Get all user tags
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-4._Tags-GetApiV1Tags
   * @returns {*}
   */
  list() {
    return axios.get(`tags`);
  },

  /**
   * Create new tag
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PostApiV1Tags
   * @param data
   * @returns {*}
   */
  create(data) {
    return axios.post(`tags`, data);
  },

  /**
   * Update tag
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PutApiV1TagsId
   * @param tagId
   * @param data
   * @returns {*}
   */
  update(tagId, data) {
    return axios.put(`tags/${tagId}`, data);
  },

  /**
   * Delete tag
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-DeleteApiV1TagsId
   * @param tagId
   * @param data
   * @returns {*}
   */
  delete(tagId) {
    return axios.delete(`tags/${tagId}`);
  }

})
