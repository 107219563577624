export default axios => ({

  /**
   * Get files list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-File_system-GetApiV1Files
   * @returns [{*}]
   * @param url
   * @param params
   */
  getFilesList (url, params= {}) {
    if(!url) return
    return axios.get(url, {
      params
    });
  },

  /**
   * Update file
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-File_system-PutApiV1FilesId
   * @returns {*}
   * @param fileId
   * @param data
   */
  updateFile (fileId, data) {
    return axios.put(`/files/${fileId}`, data);
  },

  /**
   * Get file by file id
   *
   * @link https://devapi.upsway.io/apidoc/#api-9._File_system-GetApiV1FilesUserId
   * @param fileId
   * @returns {*}
   */
  get (fileId) {
    return axios.get(`/files/${fileId}`)
  },

  /**
   * Create new file
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-2._Users-PostApiV1Users
   * @param data
   * @returns {*}
   */
  create (data) {
    return axios.post('/files/user', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  /**
   * Delete file by file id
   *
   * @link https://devapi.upsway.io/apidoc/#api-9._File_system-DeleteApiV1FilesId
   * @param fileId
   * @returns {*}
   */
  delete (fileId) {
    return axios.delete(`/files/${fileId}`)
  },

  /**
   * Get files extensions
   *
   *
   * @returns [{*}]
   * @param params
   */
  getFilesExtensions (params= {}) {
    return axios.get(`/files/extensions`, {
      params
    })
  }
})
