<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3354 3.21742H6.94243L5.80401 2.07901C5.74216 2.01699 5.66867 1.9678 5.58776 1.93427C5.50684 1.90073 5.4201 1.88352 5.33251 1.88361H2.66487C1.92927 1.88361 1.33105 2.48182 1.33105 3.21742V12.5541C1.33105 13.2897 1.92927 13.888 2.66487 13.888H13.3354C14.071 13.888 14.6692 13.2897 14.6692 12.5541V4.55124C14.6692 3.81564 14.071 3.21742 13.3354 3.21742Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFolderMini",
};
</script>
