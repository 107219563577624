export default function auth ({ from, next, store }) {

  if(!store.getters['getAuth']){
    return next({
      name: 'WelcomeLanding'
    })
  }

  return next()
}
