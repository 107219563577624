import config from '@/config';
import auth from '@/router/middleware/auth';

export default [
  {
    path: '/space/create',
    name: 'CreateSpacePage',
    redirect: {name: 'SpaceCreateView'},
    component: () => import(/* webpackChunkName: 'CreateSpacePage' */ '@/views/spaces/create/CreateSpacePage'),
    children: [
      {
        path: '',
        name: 'SpaceCreateView',
        meta: {
          layout: config.layout.app,
          title: 'Create New Space',
          middleware: [
            auth
          ]
        }
      },
      {
        path: ':id/add-members',
        name: 'SpaceAddMembersView',
        meta: {
          layout: config.layout.app,
          title: 'Create New Space',
          middleware: [
            auth
          ]
        }
      },
      {
        path: ':id/workflow',
        name: 'CreateSpaceWorkflowView',
        meta: {
          layout: config.layout.app,
          title: 'Space Workflow',
          middleware: [
            auth
          ]
        }
      },
      {
        path: ':id/general',
        name: 'SpaceCreateViewGeneral',
        meta: {
          layout: config.layout.app,
          title: 'Space General',
          middleware: [
            auth
          ]
        }
      }
    ],
  },
  {
    path: '/space/edit/:id',
    name: 'EditSpacePage',
    redirect: {name: 'SpaceGeneralView'},
    component: () => import(/* webpackChunkName: 'CreateSpacePage' */ '@/views/spaces/edit/EditSpacePage'),
    children: [
      {
        path: 'general',
        name: 'SpaceGeneralView',
        meta: {
          layout: config.layout.app,
          title: 'Edit Space',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'members',
        name: 'SpaceMembersView',
        meta: {
          layout: config.layout.app,
          title: 'Edit Space',
          middleware: [
            auth
          ]
        }
      },
      {
        path: 'workflow',
        name: 'SpaceWorkflowView',
        meta: {
          layout: config.layout.app,
          title: 'Edit Space',
          middleware: [
            auth
          ]
        }
      }
    ],
  },
]
