<template>
  <UITextModal modal-name="pageLeaveModal" max-width="800">
    <div class="pt-12 pb-8 px-6 px-sm-12 text-center">
      <h3 class="mb-6 text_color_18--text font-weight-bold text-h5 text-sm-h4">Unsaved changes</h3>
      <p class="mb-8 text_color_18--text font-weight-medium">Are you sure you want to leave? Unsaved changes may be lost.</p>
      <v-btn
        depressed
        width="180"
        height="40"
        outlined
        color="background_11"
        class="font-weight-bold rounded-0 text-capitalize mx-2 mb-4"
        style="letter-spacing: 0;"
        @click="getModal('pageLeaveModal').handlers.onLeave()"
      >
        Leave
      </v-btn>
      <v-btn
        depressed
        width="180"
        height="40"
        outlined
        color="primary"
        class="font-weight-bold rounded-0 text-capitalize mx-2 mb-4"
        style="letter-spacing: 0;"
        @click="$store.dispatch('closeModal', 'pageLeaveModal')"
      >
        Stay
      </v-btn>
    </div>
  </UITextModal>
</template>

<script>
import UITextModal from "../UI/UITextModal";
import {mapGetters} from "vuex";

export default {
  name: 'SaveFormChangesModal',
  components: {
    UITextModal,
  },
  computed: {
    ...mapGetters([
      'getModal'
    ])
  },
}
</script>
