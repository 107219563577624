<template>
  <UITextModal content-class="confirm-dialog" modal-name="confirmModal"  :max-width="getModal('confirmModal').data.modalMaxWidth" :close-btn="false" :persistent="getModal('confirmModal').data.loading">
    <div class="pt-7 pb-4 px-7 text-center">
      <div :class="[getModal('confirmModal').data.showIconCross ? 'd-flex justify-space-between' : '']">
        <h3
          v-if="getModal('confirmModal').data.title"
          v-html="getModal('confirmModal').data.title"
          class="text-title-2 gray-100--text"
          :class="[getModal('confirmModal').data.showIconCross ? '' : 'mb-6']"
        />
        <UiBtn
          v-if="getModal('confirmModal').data.showIconCross"
          fab
          color="gray-0"
          :disabled="getModal('confirmModal').data.loading"
          @click="getModal('confirmModal').handlers.onCancel()"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>
      <v-divider class="mt-3 mb-5"/>
      <div 
        v-if="!getModal('confirmModal').data.loadingContent"
        class="d-flex justify-space-between mb-9"
        :class="[getModal('confirmModal').data.descriptionQuoteColor ? `px-4 py-3 rounded` : '', getModal('confirmModal').data.descriptionQuoteColor, getModal('confirmModal').data.descriptionContainerClasses]"
      >
        <component
          v-if="getModal('confirmModal').data.descriptionIcon"
          :is="getModal('confirmModal').data.descriptionIcon"
          width="16"
          class="mr-3 flex-shrink-0 accent--text"
        />
        <p
          v-if="getModal('confirmModal').data.description"
          v-html="getModal('confirmModal').data.description"
          class="text-body mb-0"
          :class="[getModal('confirmModal').data.descriptionAlign === 'left' ? 'text-left' : '', getModal('confirmModal').data.descriptionColor]"
        />
       
      </div>
      <div v-else class="d-flex justify-center mb-10 mt-5">
          <v-progress-circular
            :size="30"
            color="accent"
            indeterminate
          />
        </div>
      <div :class="[getModal('confirmModal').data.actionBtnsPosition === 'left' ? 'd-flex justify-start' : getModal('confirmModal').data.actionBtnsPosition === 'right' ? 'd-flex justify-end' : '']">
        <UiBtn
          width="110"
          outlined
          :color="getModal('confirmModal').data.cancelBtnColor"
          class="text-capitalize mb-4"
          style="margin: 0 6px;"
          :disabled="getModal('confirmModal').data.loading"
          @click="getModal('confirmModal').handlers.onCancel()"
        >
          {{ getModal('confirmModal').data.cancelBtnText }}
        </UiBtn>
        <UiBtn
          width="128"
          :color="getModal('confirmModal').data.confirmBtnColor"
          class="text-capitalize mb-4"
          style="margin: 0 6px;"
          :loading="getModal('confirmModal').data.loading"
          @click="getModal('confirmModal').handlers.onConfirm()"
        >
          <component
            v-if="getModal('confirmModal').data.confirmBtnIcon"
            :is="getModal('confirmModal').data.confirmBtnIcon"
            width="16"
            class="mr-2"
          />
          {{ getModal('confirmModal').data.confirmBtnText }}
        </UiBtn>
      </div>
    </div>
  </UITextModal>
</template>

<script>
import {mapGetters} from "vuex";
import UITextModal from "@/components/UI/UITextModal";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'ConfirmModal',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconInfoFilled: () => import('@/components/icons/IconInfoFilled'),
    UITextModal,
    UiBtn,
  },
  computed: {
    ...mapGetters([
      'getModal'
    ])
  }
}
</script>
