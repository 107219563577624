import api from '../api'
import store from "../store";

const applicationServerKey = process.env.VUE_APP_VAPID_KEY;

export function checkPushInBrowser() {
  return !(!('serviceWorker' in navigator) || !('PushManager' in window) || !('showNotification' in ServiceWorkerRegistration.prototype));
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const pushUpdateSubscription = () => {
  if(!checkPushInBrowser()) return
  navigator.serviceWorker.ready
    .then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
    .then(subscription => {
      if (!subscription) {
        // We aren't subscribed to push, so set UI to allow the user to enable push
        pushSubscribe()
        return
      }

      // Keep your server in sync with the latest endpoint
      return pushSendSubscriptionToServer(subscription);
    })
    .then(subscription => subscription) // Set your UI to show they have subscribed for push messages
    .catch(e => {
      console.error('Error when updating the subscription', e);
    });
};

function checkNotificationPermission() {
  return new Promise((resolve, reject) => {
    if (Notification.permission === 'denied') {
      return reject(new Error('Push messages are blocked.'));
    }

    if (Notification.permission === 'granted') {
      return resolve();
    }

    if (Notification.permission === 'default') {
      return Notification.requestPermission().then(result => {
        if (result !== 'granted') {
          reject();
        }

        resolve();
      });
    }
  });
}

export const pushSubscribe = () => {
  if(!store.getters.getAuthId || !checkPushInBrowser()) return
  return checkNotificationPermission()
    .then(() => navigator.serviceWorker.ready)
    .then(serviceWorkerRegistration =>
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
      })
    )
    .then(subscription => {
      // Subscription was successful
      // create subscription on your server
      return pushSendSubscriptionToServer(subscription);
    })
    .then(subscription => subscription) // update your UI
    .catch(e => {
      if (Notification.permission === 'denied' || Notification.permission === 'default') {
        // The user denied the notification permission which
        // means we failed to subscribe and the user will need
        // to manually change the notification permission to
        // subscribe to push messages
        console.warn('Notifications are denied by the user.');
      } else {
        // A problem occurred with the subscription; common reasons
        // include network errors or the user skipped the permission
        console.error('Impossible to subscribe to push notifications', e);
      }
    });
};

function pushSendSubscriptionToServer(subscription) {
  if(!store.getters.getAuthId) return
  const key = subscription.getKey('p256dh');
  const token = subscription.getKey('auth');
  const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0];

  api.notification.subscribePushNotification({
    endpoint: subscription.endpoint,
    publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
    authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
    contentEncoding
  }).then(() => subscription);
}
