<template>
  <v-app-bar class="app-header" app flat color="gray-0" height="64">
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mdAndDown"
      @click="$store.dispatch('toggleSideMenu')"
      class="mr-2"
    ></v-app-bar-nav-icon>
    <v-spacer/>
    <ui-btn
      icon
      color="gray-80"
      @click="$store.dispatch('toggleNotificationBar', {open: true, type: $config.notifications.notificationType.notifications})"
    >
      <v-badge color="accent" dot overlap bordered v-model="unreadNotifications">
        <IconBell width="16" class="mt-1"/>
      </v-badge>
    </ui-btn>
    <div class="ml-3">
      <ui-btn
        icon
        color="gray-80"
        @click="$store.dispatch('toggleNotificationBar', {open: true, type: $config.notifications.notificationType.messages})"
      >
        <v-badge color="accent" dot overlap bordered v-model="unreadMessages">
          <IconChat width="16" class="mt-1"/>
        </v-badge>
      </ui-btn>
    </div>

    <v-menu
      v-if="getUser"
      offset-y
      left
      bottom
      nudge-bottom="8"
      min-width="222"
      content-class="user-menu">
      <template v-slot:activator="{ on }">
        <ui-btn v-on="on" icon width="24" color="gray-100" height="24" class="ml-3 rounded-circle" ripple>
          <ui-avatar size="24" :text="getUser.first_name" :src="getUser.avatar ? getUser.avatar.url : ''"/>
        </ui-btn>
      </template>

      <div class="d-flex flex-column align-center px-3 pt-6 pb-4 text-center">
        <ui-avatar size="40" text-class="text-body" :text="getUser.first_name"
                   :src="getUser.avatar ? getUser.avatar.url : ''"/>

        <strong class="mt-3 text-capitalize text-body font-weight-semi-bold gray-100--text">
          {{ getUser.first_name }} {{ getUser.last_name }}
        </strong>

        <span v-if="getUser.settings.default_project_role" class="mt-1 text-captions-1 gray-60--text">
          {{ $config.project.userRoleLabels[getUser.settings.default_project_role] }}
        </span>
      </div>

      <v-divider/>

      <v-list dense nav color="gray-0">
        <v-list-item
          v-for="(link, key) in profileLinks"
          :key="key"
          :to="{name: link.routeName}"
          class="rounded overflow-hidden"
        >
          <v-list-item-title class="d-flex align-center text-captions-1 font-weight-semi-bold gray-80--text">
            <component :is="link.icon" width="16" class="mr-3"/>
            {{ link.title }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout" class="rounded overflow-hidden">
          <v-list-item-title class="d-flex align-center text-captions-1 font-weight-semi-bold gray-80--text">
            <SignOutIcon width="16" class="mr-3"/>
            {{ isTempAuth ? 'Close session' : 'Sign Out' }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import UiAvatar from "@/components/UI/UiAvatar";

export default {
  name: "AppMainHeader",
  components: {
    IconBell: () => import('@/components/icons/IconBell'),
    UserIcon: () => import('@/components/icons/IconUserCircle'),
    SettingsIcon: () => import('@/components/icons/IconSettings'),
    SignOutIcon: () => import('@/components/icons/IconSignOut'),
    IconChat: () => import("@/components/icons/IconChat"),
    UiAvatar,
    UiBtn,
  },
  data() {
    return {
      profileLinks: [
        {
          title: 'Account',
          icon: 'UserIcon',
          routeName: 'UserProfile'
        },
        {
          title: 'Settings',
          icon: 'SettingsIcon',
          routeName: 'Settings'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'unreadMessages',
      'unreadNotifications',
      'getUser',
      'isTempAuth'
    ]),
  },
  methods: {
    ...mapActions(['logoutTempAuth']
    ),
    async logout() {
      try {
        if (this.isTempAuth) {
          this.closeSession()
          return
        }
        await this.$api.auth.logout();
        await this.$store.dispatch('setAuth', null);
        await this.$router.push({name: "Login"});
      } catch (error) {
        console.error(error)
      }
    },
    closeSession() {
      this.logoutTempAuth()
      window.location.href = '/admin'
    },
  }
}
</script>

<style scoped lang="scss">
.user-menu {
  border: 1px solid var(--v-gray-30-base);
  border-radius: 5px;
  box-shadow: 0 4px 31px rgba(0, 0, 0, 0.1);
  background-color: var(--v-gray-0-base);

  .v-list-item:hover {
    background-color: var(--v-gray-30-base);

    &::before {
      opacity: 0;
    }
  }
}

::v-deep .v-app-bar__nav-icon .v-icon {
  font-size: 18px;
}
.app-header {
  z-index: 11;
}
</style>
