<template>
  <v-layout column>
    <AppMainHeader/>
    <AppSideMenu />
    <v-main>
      <router-view :key="Number($route.params?.id || $route.params?.project_id || 0)"/>
    </v-main>
    <ProjectSideDrawer v-if="$route.path.includes('project')"/>
  </v-layout>
</template>

<script>
import AppMainHeader from "@/components/layout/AppMainHeader";
import AppSideMenu from "../components/layout/AppSideMenu"
import ProjectSideDrawer from "@/components/project/side-drawer/ProjectSideDrawer";

export default {
  name: 'AppLayout',
  components: {AppMainHeader, AppSideMenu, ProjectSideDrawer},
}
</script>
