export default {

  /**
   *
   * @param commit
   * @param payload
   */
  setDashboardLoading({ commit }, payload) {
    commit('SET_DASHBOARD_PROJECTS_LOADING', payload)
  },


  /**
   *
   * @param commit
   * @param payload
   */
  setProjects({ commit }, payload) {
    commit('SET_PROJECTS', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateDashboardProject({ commit }, payload) {
    commit('UPDATE_DASHBOARD_PROJECT', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  updateDashboardProjectFolder({ commit }, payload) {
    commit('UPDATE_DASHBOARD_PROJECT_FOLDER', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setDashboardFilters({ commit }, payload) {
    commit('SET_DASHBOARD_FILTERS', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setUserColumnSettings({ commit }, payload) {
    commit('SET_USER_COLUMN_SETTINGS', payload)
  }
}
