<template>
  <v-dialog
    v-model="getModal('createEditFolderNameModal').isOpen"
    max-width="416"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
    :persistent="submitLoading"
    content-class="create-new-task-modal"
    :scrollable="true"
  >
    <div class="relative pt-7 px-4 px-sm-7 pb-6 gray-0 fill-height">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">
          <template v-if="modalData.type === 'create'">New Folder</template>
          <template v-else-if="modalData.type === 'edit'">Rename Folder</template>
        </h5>

        <UiBtn
          fab
          color="gray-0"
          @click="hideModal"
        >
          <IconCancel width="16" class="gray-60--text"/>
        </UiBtn>
      </div>

      <v-divider class="mt-3 mb-4"/>

      <ValidationObserver tag="div" ref="form" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(createFolder)">

          <ValidationProvider 
            class="mb-16" 
            mode="eager" 
            tag="div"
            name="Folder name" 
            vid="title" 
            :rules="`max:255|required|duplicates:${existedFolderNames}`"
            v-slot="{ errors }"
          >
            <div class="mb-2 text-body text-capitalize gray-100--text">New Folder Name</div>
            <div class="d-flex align-center">
              <v-text-field
                v-model.trim="folder.title"
                dense
                outlined
                hide-details
                ref="name_input"
                placeholder="Type here..."
                :disabled="submitLoading"
                :error="!!errors.length"
              />
            </div>
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
          </ValidationProvider>

          <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
            <UiBtn
              outlined
              width="92"
              color="gray-60"
              class="mt-2"
              :disabled="submitLoading"
              @click="hideModal"
            >
              Cancel
            </UiBtn>
            <UiBtn
              type="submit"
              width="122"
              color="accent"
              :loading="submitLoading"
              class="mt-2"
            >
              {{modalData.type === 'create' ? 'Create Folder' : 'Rename'}}
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>

      <div v-if="dataLoading" class="d-flex justify-center align-center absolute inset-0" style="background-color:rgba(255, 255, 255, .5);">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'CreateEditFolderNameModal',
  components: {
    IconCancel: () => import('@/components/icons/IconCancel'),
    UiBtn,
  },
  data() {
    return {
      dataLoading: true,
      submitLoading: false,
      folder: {
        id: null,
        title: '',
      }
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
      'getPermission',
      'getActiveSpace',
      'getSpaces',
    ]),
    modalData() {
      return this.getModal('createEditFolderNameModal').data;
    },
    modalActions() {
      return this.getModal('createEditFolderNameModal').handlers;
    },
    existedFolderNames() {
      return this.modalData.folderData.space.folders?.map(el => el.title) || []
    }
  },
  async created() {
    this.folder = {...this.folder, ...JSON.parse(JSON.stringify(this.modalData.folderData))};

    this.dataLoading = false;
  },
  methods: {
    async createFolder() {
      if (this.folder.id !== null) {
        await this.updateFolder();
        return;
      }

      try {
        this.submitLoading = true;

        const {data} = await this.$api.folder.create({
          title: this.folder.title,
          space_id: this.modalData.folderData.space.id,
        })


        await this.modalActions?.onCreate();

        this.$toast.open({
          message: 'New Folder Created',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: 'Oops! Something went wrong!',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.submitLoading = false;
        if(this.getModal('createEditFolderNameModal').isOpen) {
          this.hideModal()
        }
      }
    },
    async updateFolder() {
      try {
        this.submitLoading = true;
        const {data} = await this.$api.folder.update(this.modalData.folderData.id, {
          title: this.folder.title,
          space_id: this.modalData.folderData.space.id,
        })

        await this.modalActions?.onUpdate(data);
        this.$toast.open({
          message: 'Folder name updated',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        console.error(error);
        this.$toast.open({
          message: 'Oops! Something went wrong!',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.submitLoading = false;
        if(this.getModal('createEditFolderNameModal').isOpen) {
          this.hideModal()
        }
      }
    },
    hideModal() {
      this.modalActions?.onClose();
      this.$store.dispatch('closeModal', 'createEditFolderNameModal');
    }
  }
}
</script>

<style lang="scss">
.create-new-task-modal.v-dialog {
  overflow-y: auto;
}
</style>
