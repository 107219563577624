export default {

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_WORKFLOW_MODULES(state, payload) {
    state.modules = [...payload];
  },
}
