export default {
  getAuth(state) {
    return state.temp_auth || state.auth
  },
  isTempAuth(state) {
    return !!state.temp_auth
  },
  getAdminToken(state) {
    return state.admin_token
  },
  getAuthId(state, getters) {
    if (!getters.getAuth) {
      return null
    }
    return parseInt(JSON.parse(atob(getters.getAuth.token.split('.')[1])).sub)
  },
  getUser(state) {
    return state.user
  },
  getRolesList(state) {
    return state.rolesList
  }
}
