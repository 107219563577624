<template>
  <DatePicker
    v-on="$listeners"
    v-bind="$attrs"
    :disabled-date="disabledDate"
    :disabled="disabled"
    :value="value"
    :confirm="!hideFooter"
    :clearable="false"
    :open.sync="isOpen"
    @open="toggleCalendar"
    @close="toggleCalendar"
    @pick="handleChange"
    title-format="YYYY-MM-DD"
    value-type="timestamp"
    :popup-class="['ui-due-datepicker', popupClass]"
    class="ui-due-date"
  >
    <template v-slot:header>
      <slot v-if="!hideHeader" name="header" :date="newDueDate">
        <div class="ui-due-datepicker-header font-weight-semi-bold">
          <IconClockSolid width="18"/>
          Due date
          <span class="font-weight-semi-bold">{{ format(newDueDate, 'MMM dd, yyyy') }}</span>
        </div>
      </slot>
    </template>
    <template v-slot:input>
      <div :class="{
        'is-open--input' : isOpen,
        'is-closed--input': !isOpen,
        'is-disabled--input': disabled,
      }">
        <slot name="input" :open="isOpen" :dayLeft="daysLeft" :value="value">
          <UiBtn
            outlined
            width="165"
            :color="daysLeft < 0 ? 'error' : 'success-100'"
            class="due-date-btn text-body text-capitalize font-weight-light"
          >
            <IconClockSolid width="20" class="mr-2"/>
            <template v-if="daysLeft < 0">Missed due</template>
            <template v-else>{{ daysLeft }} {{ daysLeft === 1 ? 'day' : 'days' }} left</template>
            <IconPen width="18" class="ml-4"/>
          </UiBtn>
        </slot>
      </div>
    </template>
    <template v-slot:footer>
      <slot v-if="!hideFooter" name="footer" :cancel="cancel" :save="save">
        <button type="button" @click="cancel">Cancel</button>
        <button type="button" @click="save" class="due-datepicker-save-btn">Save</button>
      </slot>
    </template>
  </DatePicker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import {differenceInDays, format} from 'date-fns'
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "UIDatePicker",
  components: {
    IconClockSolid: () => import('@/components/icons/IconClockSolid'),
    IconPen: () => import('@/components/icons/IconPen'),
    DatePicker,
    UiBtn,
  },
  props: {
    value: {
      type: [String, Number],
      default: new Date(),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledDate: {
      type: Function,
      default: () => false,
    },
    title: {
      type: String,
      default: 'Due date'
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    popupClass: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      isOpen: false,
      newDueDate: new Date().getTime(),
    }
  },
  computed: {
    daysLeft() {
      const now = new Date().setHours(0, 0, 0, 0);

      return differenceInDays(new Date(this.value), now)
    }
  },
  methods: {
    format,
    toggleCalendar() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen)
    },
    handleChange(value) {
      this.newDueDate = new Date(value).getTime();
    },
    save() {
      this.toggleCalendar();
      this.$emit('input', this.newDueDate)
      this.$nextTick(() => {
        this.$emit('save', {
          value: this.newDueDate,
          daysLeft: this.daysLeft
        })
      })
    },
    cancel() {
      this.toggleCalendar();
    },
  }
}
</script>

<style lang="scss">
.mx-datepicker.ui-due-date {
  width: auto;

  .mx-icon-calendar {
    display: none;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.mx-datepicker-main.ui-due-datepicker {
  width: 320px;
  margin-top: 6px;
  border: 1px solid var(--v-gray-30-base);
  border-radius: 4px;
  box-shadow: 0 4px 31px rgb(0, 0, 0, 0.1);
  color: #8181A5;

  .mx-datepicker-header {
    padding: 0;
    border: none;
  }

  .ui-due-datepicker-header {
    display: flex;
    align-items: center;
    padding: 16px;
    font-size: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    svg {
      margin: -3px 12px 0 0;
      color: #FF6363;
    }

    span {
      margin-top: 2px;
      margin-left: auto;
    }
  }

  .mx-calendar-panel-date .mx-btn-icon-double-left,
  .mx-calendar-panel-date .mx-btn-icon-double-right {
    display: none;
  }

  .mx-btn-icon-left,
  .mx-btn-icon-right,
  .mx-btn-icon-double-left,
  .mx-btn-icon-double-right {
    i::before {
      width: 14px;
      height: 14px;
      border-width: 3px 0 0 3px;
    }

    i::after {
      display: none;
    }
  }

  .mx-btn-current-month:hover,
  .mx-btn-current-year:hover,
  .mx-btn-icon-left:hover,
  .mx-btn-icon-right:hover {
    border-color: #6FCF97;
    color: #6FCF97;
  }

  .mx-calendar {
    padding: 0;
    width: 100%;
  }

  .mx-calendar-header,
  .mx-time-header {
    height: 36px;
    padding: 0 38px;
    line-height: 36px;
    background-color: #FCFCFC;
  }

  .mx-table th {
    width: 28px;
    height: 28px;
    font-weight: 400;
  }

  .mx-table td {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin: auto;
      border: 2px solid transparent;
      border-radius: 50%;
    }
  }

  .mx-calendar.mx-calendar-panel-date {
    .mx-calendar-content {
      padding: 12px 36px;
      height: auto;

      .cell {
        &.disabled {
          background-color: transparent;
        }
        &.not-current-month {
          height: 0;
          overflow: hidden;
          opacity: 0;
          pointer-events: none;
          div {
            display: none;
          }
        }
      }

      .cell:not(.disabled) {
        &:hover,
        &.active, {
          background-color: #fff;
          color: #FF6363;

          div {
            background-color: #FCFCFC;
            border-color: #FF6363;
          }
        }

        &.today {
          background-color: #fff;
          color: #6FCF97;

          div {
            border-color: #6FCF97;
          }
        }
      }
    }
  }

  .mx-calendar-panel-month .cell,
  .mx-calendar-panel-year .cell {
    &:hover {
      background-color: #FF6363;
      color: #fff;
    }

    &.active {
      background-color: #6FCF97;
      color: #fff;
    }
  }

  .mx-datepicker-footer {
    padding: 0 30px 22px;
    border: none;

    button {
      margin-left: 8px;
      width: 120px;
      height: 36px;
      text-transform: uppercase;
      font-weight: 500;
      border: 1px solid transparent;
      border-radius: 4px;

      &.due-datepicker-save-btn {
        border-color: rgba(129, 129, 165, 0.4);
      }

      &:hover {
        background-color: rgba(0, 0, 0, .1);
        border-color: rgba(129, 129, 165, 0.4);
      }
    }

    .mx-datepicker-btn-confirm {
      display: none;
    }
  }

  @media screen and (max-width: 344px) {
    width: 296px;
  }

  .mx-datepicker-footer button {
    width: 108px;
  }
}

</style>
