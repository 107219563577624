import Vue from 'vue'
import Vuex from 'vuex'

import Auth from './modules/Auth'
import Modals from './modules/Modals'
import Project from './modules/Project'
import ProjectDesign from './modules/ProjectDesign'
import Dashboard from './modules/Dashboard'
import Workflow from './modules/Workflow'
import ProjectSideDrawer from './modules/ProjectSideDrawer'
import Notifications from './modules/Notifications'
import SideMenu from './modules/SideMenu'
import Spaces from './modules/Spaces'
import Folder from './modules/Folder'
import config from "@/config";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    workflowModules: [],
    gridView: JSON.parse(localStorage.getItem(config.storage.storageGridKey)) || {}
  },
  mutations: {
    SET_GRID_VIEW(state, payload) {
      state.gridView = payload
    }
  },
  getters: {
    getGridView(state) {
      return state.gridView
    }
  },
  actions: {
    setGridView({commit, state}, {key, value}) {
      const data = {
        ...state.gridView,
        [key]: value
      }
      commit('SET_GRID_VIEW', data)
      localStorage.setItem(config.storage.storageGridKey, JSON.stringify(data))
    },
    setDefaultGridView({commit}, payload) {
      commit('SET_GRID_VIEW', payload)
      localStorage.setItem(config.storage.storageGridKey, JSON.stringify(payload))
    },
  },
  modules: {
    Auth,
    Modals,
    Project,
    ProjectDesign,
    Dashboard,
    Workflow,
    ProjectSideDrawer,
    Notifications,
    SideMenu,
    Spaces,
    Folder
  }
})
