export default axios => ({

  /**
   * Get project mood board info
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-GetApiV1ProjectsProjectidModulesMoodBoard
   * @param project_id
   * @returns {*}
   */
  info(project_id) {
    return axios.get(`projects/${project_id}/modules/mood-board`);
  },

  /**
   * Complete mood board
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-PutApiV1ProjectsProjectidModulesMoodBoardMoodboardid
   * @param project_id
   * @param moodBoardId
   * @returns {*}
   */
  complete(project_id, moodBoardId) {
    return axios.put(`/projects/${project_id}/modules/mood-board/${moodBoardId}`)
  },

  /**
   * Skip mood board
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Mood_board-PatchApiV1ProjectsProjectidModulesMoodBoard
   * @param project_id
   * @returns {*}
   */
  skip(project_id) {
    return axios.patch(`/projects/${project_id}/modules/mood-board`)
  },

  /**
   * Create mood board
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Mood_board-PostApiV1ProjectsProjectidModulesMoodBoard
   * @param project_id
   * @param data
   * @returns {*}
   */
  create(project_id, data) {
    return axios.post(`/projects/${project_id}/modules/mood-board`, data)
  },

  /**
   * Delete mood board
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Mood_board-PostApiV1ProjectsProjectidModulesMoodBoard
   * @param project_id
   * @param moodBoardId
   * @returns {*}
   */
  delete(project_id, moodBoardId) {
    return axios.delete(`/projects/${project_id}/modules/mood-board/${moodBoardId}`)
  },

  /**
   * Update mood board
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Mood_board-PostApiV1ProjectsProjectidModulesMoodBoardMoodboardid
   * @param project_id
   * @param moodBoardId
   * @param data
   * @returns {*}
   */
  update(project_id, moodBoardId, data) {
    return axios.put(`/projects/${project_id}/modules/mood-board/${moodBoardId}`, data)
  },

  /**
   * Get mood board files list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-GetApiV1ProjectsProjectidModulesMoodboardidMoodBoardFiles
   * @param project_id
   * @param moodBoardId
   * @returns {*}
   */
  filesList(project_id, moodBoardId) {
    return axios.get(`/projects/${project_id}/modules/${moodBoardId}/mood-board/files`);
  },

  /**
   * Create new mood board files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-PostApiV1ProjectsProjectidModulesMoodboardidMoodBoardFiles
   * @param project_id
   * @param moodBoardId
   * @param data
   * @returns {*}
   */
  createFiles(project_id, moodBoardId, data) {
    return axios.post(`/projects/${project_id}/modules/${moodBoardId}/mood-board/files`, data)
  },

  /**
   * Update mood board files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-PutApiV1ProjectsProjectidModulesMoodboardidMoodBoardFiles
   * @param project_id
   * @param moodBoardId
   * @param data
   * @returns {*}
   */
  updateFiles(project_id, moodBoardId, data) {
    return axios.post(`/projects/${project_id}/modules/${moodBoardId}/mood-board/files/update`, data)
  },

  /**
   * Duplicate mood board file by ID
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-PutApiV1ProjectsProjectidModulesMoodboardidMoodBoardFilesMoodboardfileidDuplicate
   * @param project_id
   * @param moodBoardId
   * @param fileId
   * @returns {*}
   */
  duplicateFile(project_id, moodBoardId, fileId) {
    return axios.put(`/projects/${project_id}/modules/${moodBoardId}/mood-board/files/${fileId}/duplicate`)
  },

  /**
   * Delete mood board file by ID
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-12._Mood_board-DeleteApiV1ProjectsProjectidModulesMoodboardidMoodBoardFilesMoodboardfileid
   * @param project_id
   * @param moodBoardId
   * @param fileId
   * @returns {*}
   */
  deleteFile(project_id, moodBoardId, fileId) {
    return axios.delete(`/projects/${project_id}/modules/${moodBoardId}/mood-board/files/${fileId}`)
  },

})
