import config from "@/config";

import landing from "@/router/routes/group/landing";
import auth from "@/router/routes/group/auth";
import user from "@/router/routes/group/user";
import project from "@/router/routes/group/project";
import page from "@/router/routes/group/page";
import spaces from "@/router/routes/group/spaces";
import error from "@/router/routes/group/error";

const redesignRoute = process.env.NODE_ENV === 'development' ?   [{
  path: '/ui',
  name: 'UI',
  component: () => import(/* webpackChunkName: 'UiKit' */ '@/views/_UiKit/UiKitPage'),
  meta: {
    layout: config.layout.app,
    title: 'UI Kit',
  }
}] : [];

export default [
  ...landing,
  ...auth,
  ...user,
  ...page,
  ...error,
  ...project,
  ...spaces,
  ...redesignRoute
];
