import {chatConfig} from "@/config/_chatConfig"
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const state = {
  showNotificationBar: false,
  notificationBarType: chatConfig.notifications.notificationType.notifications,
  notifications: {
    data: [],
    nextLink: null,
    type: chatConfig.notifications.notificationType.notifications
  },
  messages: {
    data: [],
    nextLink: null,
    type: chatConfig.notifications.notificationType.messages
  },
  projectsNotifications: [],
  pushNotification: false,
  notificationBarSort: {name: 'All', slug: 'all'},
  messagesBarSort: {name: 'All', slug: 'all'}
}

export default {
  state,
  getters,
  mutations,
  actions,
}
