import Vue from 'vue'
import {extend, ValidationProvider, ValidationObserver} from "vee-validate";
import {confirmed, email, max, min, required, excluded} from "vee-validate/dist/rules";

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...required,
  message: 'Please enter {_field_}',
});

extend('email', {
  ...email,
  message: 'This {_field_} is not a valid email address',
});

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} symbols.',
});

extend('max', {
  ...max,
  message: '{_field_} must have max {length} symbols.',
});

extend('confirmed', {
  ...confirmed,
  message: '{_field_} do not match.'
})

extend('youtube_url', {
  validate: value => /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm.test(value) && value.match(/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)?.[1].length,
  message: 'Please enter valid {_field_}',
});

extend('unique_chars', {
  params: ['uniqueCharCount'],
  validate: (value, {uniqueCharCount}) => {
    const set = new Set(value.split(''));
    return set.size >= +uniqueCharCount;
  },
  message: 'Please enter 5 or more unique characters.',
});

extend('password_spec_char', {
  validate: value => /^.*(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~!@#$%^*\-_=+[{\]}\/;:,.?])/.test(value),
  message: 'Please enter At least 1 of the following: uppercase, lowercase, numeric, or special characters. The allowed special characters are: ~ ! @ # $ % ^ * - _ = + [ { ] } / ; : , . ?',
});


extend('excluded', {
  ...excluded,
  message: '{_field_} already exists'
})

extend('name_validate', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: null
});


extend('phone', {
  validate(value) {
    const phoneRegex = /^\+(?=(?:\D*\d){5,13}$)(([1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?([0-9]{3,4}[ -]?[0-9]{3,4})?$/;
    return phoneRegex.test(String(value));
  },
  message: () =>  `The phone number must start with + and have a minimum of 5 and a maximum of 13 digits.`,
});

extend('duplicates', {
  params: ['target'],
  validate(value, { target }) {
    return !target.includes(value);
  },
  message: 'Folder with same name already exist'
});
