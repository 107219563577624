export default {

  /**
   *
   * @param state
   * @returns Boolean
   */
  designsLoading(state) {
    return state.designsLoading
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  noCreatedStyles(state) {
    return state.noCreatedStyles
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  showFilesCarousel(state) {
    return state.filesCarousel
  },

  /**
   *
   * @param state
   * @returns [{}]
   */
  getProjectDesigns(state) {
    return state.designs
  },

  /**
   *
   * @param state
   * @returns [{}]
   */
  getActiveDesignModule(state) {
    return state.activeDesignModule
  },

  /**
   *
   * @param state
   * @returns [{}]
   */
  getActiveStyle(state) {
    return {
      ...state.activeStyle,
      grouped_items: state.activeStyle?.items?.reduce((acc, file) => {
        if(acc[file.gallery_group_id]) {
          acc[file.gallery_group_id].push(file)
        } else {
          acc[file.gallery_group_id] = [file]
        }
        return acc
      }, {}) || {}
    }
  },

  /**
   *
   * @param state
   * @returns [{}]
   */
  getActiveFile(state) {
    return state.activeFile
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getProjectDesignsFilters(state) {
    return state.filters
  },

  /**
   *
   * @param state
   * @returns [{*}]
   */
  getAllProjectStyles(state) {
    return state.allProjectStyles
  },

  /**
   *
   * @param state
   * @returns String
   */
  getActiveDesignMode(state) {
    return state.activeDesignMode
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getDrawModeData(state) {
    return state.drawModeData
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  getDrawModeHistory(state) {
    return state.drawModeHistory
  },
}
