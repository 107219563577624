<template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled"
    :content-class="`ui-menu-content elevation-0 ${contentClass}`"
    :z-index="zIndex"
    :rounded="rounded"
  >
    <template #activator="{on, attrs}">
      <slot name="activator" :attrs="attrs" :on="on"/>
    </template>
    <slot>
      <v-list nav dense color="gray-10">
        <v-list-item v-for="item in items" :key="item.title" v-bind="item.attrs" @click="item.action">
          <component :is="item.icon" width="18" class="mr-2 gray-60--text"/>
          <v-list-item-title class="text-captions-1">{{item.title}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  name: "UiMenu",
  inheritAttrs: false,
  components: {
    IconPen: () => import('@/components/icons/IconPen'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconSettings: () => import('@/components/icons/IconSettings'),
    IconAddNewItem: () => import("@/components/icons/IconAddNewItem"),
    IconFolderMini: () => import("@/components/icons/IconFolderMini"),
  },
  props: {
    contentClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: [Number, String],
      default: undefined
    },
    rounded: {
      type: String,
      default: 'sm'
    }
  }
}
</script>

<style scoped lang="scss">
.ui-menu-content {
  border: 1px solid var(--v-gray-60-base);
  background: var(--v-gray-10-base);
  //border-radius: 2px !important;

  &.fixed-menu {
    position: fixed !important;
  }

  ::v-deep .v-list {
    &--nav {
      .v-list-item {
        .v-list-item__title, .v-list-item__subtitle {
          display: flex;
          align-items: center;
        }

        &--disabled {
          .v-icon {
            color: currentColor;
          }
        }

        &::before, &::after {
          display: none;
        }

        &:hover {
          background: var(--v-gray-0-base) !important;
        }
      }
    }
  }
}
</style>
