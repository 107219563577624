export default axios => ({

  /**
   * Get workflow schemes list
   *
   * @link https://devapi.upsway.io/apidoc/#api-6._Workflow_Schemes-GetApiV1WorkflowSchemes
   * @returns {*}
   */
  list () {
    return axios.get('/workflow-schemes')
  },

  /**
   * Get workflow scheme
   *
   * @link https://devapi.upsway.io/apidoc/#api-6._Workflow_Schemes-GetApiV1WorkflowSchemesId
   * @param schemeId
   * @returns {*}
   */
  get (schemeId) {
    return axios.get(`/workflow-schemes/${schemeId}`)
  },

  /**
   * Create new scheme
   *
   * @link https://devapi.upsway.io/apidoc/#api-6._Workflow_Schemes-PostApiV1WorkflowSchemes
   * @param data
   * @returns {*}
   */
  create (data) {
    return axios.post('/workflow-schemes', data)
  },

  /**
   * Update scheme by scheme id
   *
   * @link https://devapi.upsway.io/apidoc/#api-6._Workflow_Schemes-PutApiV1WorkflowSchemesId
   * @param schemeId
   * @param data
   * @returns {*}
   */
  update (schemeId, data) {
    return axios.put(`/workflow-schemes/${schemeId}`, data)
  },

})
