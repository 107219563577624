<template>
  <v-dialog
    v-if="modalName"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="getModal(modalName).isOpen"
    @keydown.esc="!persistent ? hideModal : null"
    @click:outside="!persistent ? hideModal : null"
    style="z-index: 999!important;"
    :persistent="persistent"
  >
    <div class="relative gray-0">
      <UiBtn
        v-if="closeBtn"
        absolute
        top
        right
        fab
        color="gray-0"
        class="mt-5 mr-n3"
        @click="hideModal"
      >
        <IconCancel width="16" class="gray-80--text"/>
      </UiBtn>

      <slot></slot>
    </div>
  </v-dialog>

  <v-dialog
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    @keydown.esc="!persistent ? $emit('hideModal') : null;"
    @click:outside="!persistent ? $emit('hideModal') : null;"
    style="z-index: 999!important;"
    :persistent="persistent"
  >
    <div class="relative gray-0">
      <UiBtn
        v-if="closeBtn"
        absolute
        top
        right
        fab
        color="gray-0"
        class="mt-5 mr-n3"
        @click="$emit('hideModal');"
      >
        <IconCancel width="16" class="gray-80--text"/>
      </UiBtn>

      <slot></slot>
    </div>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: 'UITextModal',
  components: {
    IconCancel: () => import('@/components/icons/IconCancel'),
    UiBtn
  },
  props: {
    modalName: {
      type: String,
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getModal',
    ]),
  },
  methods: {
    hideModal() {
      if (typeof this.getModal(this.modalName)?.handlers?.onClose === "function") {
        this.getModal(this.modalName).handlers.onClose();
      }

      this.$emit('hideModal');
      this.$store.dispatch('closeModal', this.modalName);
    },
  }
}
</script>
