<template>
  <v-avatar v-bind="$attrs" v-on="$listeners" :class="['ui-avatar', {'no-image': !hasAvatar}]">
    <slot>
      <v-img
        v-if="hasAvatar"
        :src="src"
        :alt="text"
        @error="failedLoad = true"
      />
      <span v-else class="font-weight-semi-bold text-uppercase" :class="textClass">{{ firstLetter }}</span>
    </slot>
  </v-avatar>
</template>

<script>
export default {
  name: "UiAvatar",
  inheritAttrs: false,
  data() {
    return {
      failedLoad: false,
    }
  },
  props: {
    src: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    textClass: {
      type: [String, Array],
      default: "text-captions-1"
    }
  },
  computed: {
    hasAvatar() {
      return this.src && !this.failedLoad
    },
    firstLetter() {
      return typeof this.text === "string" ? this.text.charAt(0) : ''
    }
  }
}
</script>

<style scoped lang="scss">
.ui-avatar.ui-avatar {
  border: 1px solid;
  background: var(--v-gray-30-base) !important;
  &.no-image {
    border-color: var(--v-accent-base);
  }
}
</style>
