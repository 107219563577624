<template>
  <v-navigation-drawer
    id="appSideMenu"
    :mini-variant="$vuetify.breakpoint.mdAndDown? false : mini"
    width="216"
    app
    v-model="$store.state.SideMenu.menu"
    class="navigation-drawer-menu"
    :class="{'sidebar-app-menu': $vuetify.breakpoint.mdAndDown}"
  >
    <v-list-item class="menu-top">
      <v-list-item-avatar max-height="30" class="menu-avatar d-flex justify-start mr-0"
                          :class="{'menu-avatar-mini': mini}">
        <UIImage class="menu-avatar-img" width="30" @click="clickAvatar" :name-path="`logo-b.svg`"></UIImage>
        <v-btn
          class="menu-btn--mini"
          icon
          @click.stop="toggleMini"
        >
          <IconExpandRight width="17" class="gray-0--text"/>
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-title>UpSway</v-list-item-title>

      <v-btn
        class="menu-btn"
        icon
        @click.stop="$vuetify.breakpoint.mdAndDown ? $store.dispatch('toggleSideMenu') : toggleMini()"
      >
        <IconExpandLeft width="17"/>
      </v-btn>
    </v-list-item>
    <v-list class="py-0 mt-3 menu-list" :class="{mini: mini}">
      <v-list-item
        v-for="(link, idx) in navLinks"
        :key="idx"
        exact-path
        :to="{name: link.routeName, params: link.params}"
        :ripple="$route.name !== link.routeName"
        :inactive="$route.name === link.routeName"
        :class="['mb-5', {'v-list-item--active': $route.name === link.routeName}]"
      >
        <v-list-item-icon class="align-self-center mr-4 mt-0 mb-0">
          <component :is="link.icon" width="14" class="gray-80--text"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="gray-100--text">
            {{ link.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="mt-3 menu-list menu-list-spaces" :class="{mini: mini}">
      <v-list-item
        class="d-flex flex-wrap mb-3"
        :class="[mini ? '' : 'px-4']"
        @click="clickMySpaces"
      >
        <v-list-item-icon class="align-self-center mr-4 mt-0 mb-0">
          <IconSpaces width="14" class="gray-80--text"/>
        </v-list-item-icon>
        <template v-if="!mini">
          <v-list-item-content>
            <v-list-item-title class="gray-100--text font-weight-semi-bold">
              My Spaces
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="align-self-center ml-4 mt-0 mb-0">
            <component :is="expand ? 'IconChevronUp' : 'IconChevronDown'" width="13" class="gray-80--text"/>
          </v-list-item-icon>
        </template>
      </v-list-item>
      <v-expand-transition>
        <div v-if="expand && !mini" class="space-y-3 px-2">
<!--          <v-list-item-->
<!--            exact-path-->
<!--            :to="{name: 'Dashboard'}"-->
<!--            :ripple="getActiveSpace"-->
<!--            :inactive="!getActiveSpace"-->
<!--            :class="[ {'v-list-item&#45;&#45;active': !getActiveSpace}]"-->
<!--          >-->
          <v-list-item
            exact-path
            :to="{name: 'Dashboard'}"
            :ripple="getActiveSpace"
            class="space-list-item"

          >
            <v-list-item-icon class="align-self-center mr-4 mt-0 mb-0">
              <v-sheet rounded="circle" color="in_progress" width="14" height="14"></v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="gray-100--text">
                My Space
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div
            v-for="(space, idx) in spaces"
            :key="idx"
          >
            <v-hover #default="{hover}" >
              <v-list-item
                :to="{name: 'Space', params: {id: space.id}}"
                :ripple="space.id !== getActiveSpace?.id"
                class="space-list-item"
                :class="[ {'v-list-item--active': space.id  === getActiveSpace?.id && !getActiveFolder, 'gray-0': space.isOpenSettings && space.id  !== getActiveSpace?.id}]"
              >
                <v-list-item-icon class="align-self-center mr-1 mt-0 mb-0">
                  <v-sheet rounded="circle" :color="space.color" width="14" height="14"></v-sheet>
                </v-list-item-icon>
                <v-list-item-action class="mt-0 mb-0 mr-2">
                  <UiBtn
                    v-show="space.folders?.length"
                    class="space-list-item-icon"
                    icon
                    width="auto"
                    @click.prevent="openSpace(space)"
                  >
                    <IconCaretDown width="6" />
                  </UiBtn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="gray-100--text">
                    {{ space.title }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="mt-0 mb-0" v-show="hover || space.isOpenSettings">
                  <UiMenu
                    bottom
                    right
                    offset-y
                    v-model="space.isOpenSettings"
                    :nudge-bottom="2"
                    :min-width="156"
                    :items="getSpaceSettings(space)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <UiBtn v-bind="attrs" v-on="on" @click.prevent class="p-0" height="16" width="16" icon
                            color="gray-60">
                        <IconSettings width="14"/>
                      </UiBtn>
                    </template>
                  </UiMenu>
                </v-list-item-action>
                <UiCountBadge :count="space.count_projects" v-if="space.count_projects" filled class="space-count-badge" />
              </v-list-item>
            </v-hover>
            <v-expand-transition>
              <v-list v-if="space.isOpenFolders" class="py-0 menu-list">
                <v-hover #default="{hover}" v-for="(folder, idx) in space.folders" :key="idx">
                  <v-list-item
                    :to="{name: 'Folder', params: {id: folder.id}}"
                    :ripple="getActiveSpace"
                    class="pl-5 mt-1"
                    :class="[ {'v-list-item--active': folder.id  === getActiveFolder?.id}]"
                  >
                    <v-list-item-icon class="align-self-center mr-2 mt-0 mb-0">
                      <div class="folder-icon flex-shrink-0 d-flex">
                        <IconFolderMini width="15" class="flex-shrink-0" :style="{color: space.color}" />
                      </div>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="gray-100--text">
                        {{folder.title}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-0 mb-0">
                      <UiCountBadge :count="folder.count_projects" v-if="!hover && !folder.isOpenSettings && folder.count_projects" />
                      <UiMenu
                        v-if="hover || folder.isOpenSettings"
                        bottom
                        right
                        offset-y
                        v-model="folder.isOpenSettings"
                        :nudge-bottom="2"
                        :min-width="156"
                        :items="getActiveFolderSettings(folder, space)"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <UiBtn v-bind="attrs" v-on="on" @click.prevent class="p-0" height="16" width="16" icon
                                color="gray-60">
                            <IconSettings width="14"/>
                          </UiBtn>
                        </template>
                      </UiMenu>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </v-list>
            </v-expand-transition>
          </div>

        </div>
      </v-expand-transition>
      <v-list-item v-if="!mini" class="space-action mt-3">
        <v-list-item-content>
          <UiBtn color="accent" outlined :disabled="!getUser.email_verified" block :to="{name: 'CreateSpacePage'}">
            <IconPlus width="14" class="mr-2"/>
            Create New Space
          </UiBtn>
          <div class="text-captions-2 text-center gray-60--text mt-2 mb-2" v-if="!getUser.email_verified">
            Please verify your email to create a new space <br>
            <button class="text-decoration-underline underline-offset-1" :disabled="resending" @click="resendEmail">
              Resend email
            </button>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import UiBtnSelect from '../UI/UiBtnSelect'
import UIImage from "../UI/UIImage";
import UiBtn from "@/components/UI/UiBtn";
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import UiCountBadge from "@/components/UI/UiCountBadge";

export default {
  name: "AppMenu",
  components: {
    UiChangeNameInput,
    UiMenu,
    UiBtn,
    IconExpandLeft: () => import('@/components/icons/IconExpandLeft'),
    IconExpandRight: () => import('@/components/icons/IconExpandRight'),
    IconDashboard: () => import('@/components/icons/IconDashboard'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    IconChevronUp: () => import('@/components/icons/IconChevronUp'),
    IconTasksCheck: () => import('@/components/icons/IconTasksCheck'),
    IconChat: () => import('@/components/icons/IconChat'),
    IconCopy: () => import('@/components/icons/IconCopy'),
    IconSpaces: () => import('@/components/icons/IconSpaces'),
    IconSettings: () => import('@/components/icons/IconSettings'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconPalette: () => import('@/components/icons/IconPalette'),
    IconAddNewItem: () => import("@/components/icons/IconAddNewItem"),
    IconFolderMini: () => import("@/components/icons/IconFolderMini"),
    IconCaretDown: () => import("@/components/icons/IconCaretDown"),
    UiBtnSelect,
    UIImage,
    UiCountBadge
  },
  data() {
    return {
      mini: false,
      expand: true,
      resending: false,
      spaces: []
    }
  },
  computed: {
    ...mapGetters([
      'getSpaces',
      'getActiveSpace',
      'getUser',
      'getActiveFolder'
    ]),
    navLinks() {
      const params = this.getActiveSpace ? {id: this.getActiveSpace.id} : {}
      return [
        // {title: 'Dashboard', routeName: this.getActiveSpace ? 'Space' : 'Dashboard', icon: 'IconDashboard', params},
        {title: 'Global Dashboard', routeName: 'GlobalDashboard', icon: 'IconDashboard', params},
        // {title: 'Tasks', routeName: 'Tasks', icon: 'IconTasksCheck'},
        // {title: 'Chat', routeName: 'Chat',icon: 'IconChat'},
        {
          title: 'Files',
          routeName: 'FilesByProjects',
          icon: 'IconCopy',
          params: {space_id: this.getActiveSpace?.id || 'default'}
        }
      ]
    },
  },
  watch: {
    // getActiveSpace(val) {
    //   if (val && this.$route.name === 'Dashboard') {
    //     this.$router.push({name: 'Space', params: {id: val.id}})
    //   } else if (!val && this.$route.name === 'Space') {
    //     this.$router.push({name: 'Dashboard'})
    //   }
    // }
    'getActiveFolder': {
      handler(val) {
        if(val && val.space_id && this.spaces?.length) {
          this.spaces = this.spaces.map(space => {
            if(space.id === val.space_id) {
              return {
                ...space,
                isOpenFolders: true
              }
            }
            return space
          })
        }

      },
      immediate: true,
    },
    'getSpaces': {
      handler(val) {
        this.spaces = val?.map(space => {
          return {
            ...space,
            isOpenSettings: false,
            isOpenFolders: this.getActiveFolder && this.getActiveFolder.space_id === space.id ? true : false,
            folders: space.folders?.map(el => {
              return {
                ...el,
                isOpenSettings: false
              }
            }) || []
          }
        }) || []
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    toggleMini() {
      if (!this.$vuetify.breakpoint.mdAndDown) {
        this.mini = !this.mini;
        this.$store.dispatch('toggleSideMenuWidth');
      }
    },
    clickAvatar() {
      if (this.mini) {
        this.toggleMini()
      }
    },
    clickMySpaces() {
      if (this.mini) {
        this.toggleMini()
        this.expand = true
      } else {
        this.expand = !this.expand
      }
    },
    duplicateSpace(space) {
      this.$router.push({
        name: 'CreateSpacePage', query: {
          duplicate_from: space.id
        }
      }).catch(() => {
      })
    },
    async resendEmail() {
      this.resending = true
      try {
        await this.$api.user.resendEmailVerification();
        this.$toast.open({
          message: 'Verification email has been resent',
          type: 'success',
          position: 'top-right'
        });
      } catch (e) {
        console.error(e)
      } finally {
        this.resending = false
      }
    },
    openSpace(space) {
      space.isOpenFolders = !space.isOpenFolders
    },
    getActiveFolderSettings(folder, space) {
      const {spaceAdmin} = this.$config.space.userRole;
      return [
        {
          title: 'Rename Folder',
          icon: 'IconPen',
          action: () => {
            this.$store.dispatch('openModal', {
              modalName: 'createEditFolderNameModal',
              data: {
                type: 'edit',
                folderData: {
                  title: folder.title,
                  id: folder.id,
                  space: space,
                },
              },
              handlers: {
                onUpdate: async () => {
                  const spaces = await this.$api.spaces.list()
                  await this.$store.dispatch('setSpaces', spaces.data);
                  if(this.getActiveSpace?.id === space.id) {
                    this.$eventBus.$emit('reloadProjectsAndFolders');
                  }
                },
                onClose: () => {},
                onCreate: () => {}
              },
            });
          },
          attrs: {
            to: '',
            disabled: space.currentSpaceUser.user_role.name !== spaceAdmin
          }
        },
        {
          title: 'Delete Folder',
          icon: 'IconDelete',
          action: () => {

            this.$store.dispatch("openModal", {
              modalName: "confirmModal",
              data: {
                title: "Delete Folder",
                description: "Are you sure want to delete this folder? Its content will be archived. This action is irreversible.",
                confirmBtnText: "Delete",
                confirmBtnIcon: '',
                cancelBtnColor: 'gray-60',
                showIconCross: true,
                actionBtnsPosition: 'right',
                modalMaxWidth: '416',
                descriptionAlign: 'left',
                descriptionColor: 'gray-100--text',
              },
              handlers: {
                onCancel: () => {
                  this.$store.dispatch("closeModal", "confirmModal");
                },
                onConfirm: async () => {
                  try {
                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: true,
                      }
                    })

                    const folderTitle = folder.title
                    const spaceId = folder.space_id;
                    const res = await this.$api.folder.delete([folder.id])


                    // if(this.getActiveFolder.id === folder.id) {
                    //   this.$router.replace({name: 'Space', params: {id: space.id}})
                    // }

                    const spaces = await this.$api.spaces.list()
                    await this.$store.dispatch('setSpaces', spaces.data);

                    if(this.getActiveSpace?.id === spaceId) {
                      this.$eventBus.$emit('reloadProjectsAndFolders');
                    }

                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: false,
                      }
                    })
                    this.$toast.open({
                      message: `“${folderTitle}” folder has been deleted`,
                      type: 'success',
                      position: 'top-right'
                    });

                    this.$store.dispatch("closeModal", "confirmModal");

                    if(this.getActiveFolder?.id === folder.id) {
                      this.$router.replace({name: 'Space', params: {id: spaceId}})
                    }
                  } catch(error) {
                    console.error(error);
                    this.$toast.open({
                      message: 'Oops! Something went wrong!',
                      type: 'error',
                      position: 'top-right'
                    });
                    this.$store.dispatch('updateModalData', {
                      modalName: 'confirmModal',
                      data: {
                        loading: false,
                      }
                    })
                  }
                },
              },
            });
          },
          attrs: {
            to: '',
            disabled: space.currentSpaceUser.user_role.name !== spaceAdmin
          }
        }
      ]
    },
    getSpaceSettings(space) {
      const {spaceAdmin} = this.$config.space.userRole;
      return[
        {
          title: 'Settings',
          icon: 'IconSettings',
          action: () => {},
          attrs: {
            to: {name: 'SpaceGeneralView', params: {id: space.id}},
          }
        },
        {
          title: 'Duplicate Space',
          icon: 'IconAddNewItem',
          action: () => {},
          attrs: {
            to: { name: 'CreateSpacePage', query: { duplicate_from: space.id }},
          }
        },
        {
          title: 'New Folder',
          icon: 'IconFolderMini',
          action: () => {
            this.$store.dispatch('openModal', {
              modalName: 'createEditFolderNameModal',
              data: {
                type: 'create',
                folderData: {
                  title: '',
                  id: null,
                  space: space
                },
              },
              handlers: {
                onUpdate: () => {},
                onClose: () => {},
                onCreate: async () => {
                  const spaces = await this.$api.spaces.list()
                  await this.$store.dispatch('setSpaces', spaces.data);
                  if(this.getActiveSpace?.id === space.id) {
                    this.$eventBus.$emit('reloadProjectsAndFolders');
                  }
                }
              },
            });
          },
          attrs: {
            disabled: space.currentSpaceUser.user_role.name !== spaceAdmin
          }
        },
      ]
    }
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mdAndDown) {
      this.$store.dispatch('toggleSideMenu')
    }
  }
}
</script>

<style scoped lang="scss">
#appSideMenu {
  &::v-deep {
    .v-navigation-drawer {
      &__content {
        background-color: var(--v-gray-10-base);
      }
    }

    .v-divider {
      border-color: var(--v-border-10-base);
    }
  }

  .menu-list {
    &::v-deep {
      .v-list-item {
        border-radius: 5px;
        padding: 6px 9px;
        transition: 0.3s;
        min-height: 30px;

        &.space-action {
          background-color: transparent !important;
          padding-right: 0px;
          padding-left: 0px;

          .v-btn {
            background: #ffffff !important;
          }
        }

        &__content {
          padding: 0;
        }

        &__title {
          font-size: 14px;
        }

        &__icon {
          margin-left: 0 !important;
        }

        &__icon, &__action {
          min-width: unset;
        }


        &::before, &::after {
          display: none;
        }

        &:hover {
          background-color: var(--v-gray-0-base);
        }

        &--active {
          background-color: var(--v-gray-30-base);

          &:hover {
            background-color: var(--v-gray-30-base);
          }
        }
      }

      &.v-list {
        padding: 20px 0px;

        &.mini {
          padding: 20px 0px;

          .v-list-item {
            &:hover {
              background-color: var(--v-gray-60-base);

              i {
                color: var(--v-gray-0-base) !important;
              }
            }

            &--active {
              &:hover {
                background-color: var(--v-gray-30-base);

                i {
                  color: var(--v-gray-80-base) !important;
                }
              }
            }
          }
        }
      }
    }
    &-spaces {
      padding: 0px !important;
      &:not(&.mini) {
        background-color: #ffffff;
        border-radius: 5px;
        overflow: hidden;
        padding: 8px !important;
      }

      &::v-deep {
        .v-list-item {
          border-radius: 0px;
        }
      }
    }
  }

  .menu-top {
    padding: 0 8px 0 8px;

    &::v-deep {
      .v-list-item__title {
        font-weight: 600;
        font-size: 20px;
        color: var(--v-gray-100-base);
      }
    }
  }

  .menu-btn {
    color: var(--v-gray-80-base);
    border-radius: 5px;
    height: 31px;
    width: 31px;

    &:hover {
      background: var(--v-gray-30-base);
    }

    &--mini {
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      height: 31px;
      width: 31px;
    }
  }

  .menu-avatar {
    border-radius: unset;
    width: 30px !important;
    min-width: 30px !important;

    .menu-avatar-img {
      width: 30px;
      border-radius: 50%;
    }
  }

  .menu-avatar-mini {
    justify-content: center !important;

    &:hover {
      .menu-avatar-img {
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
      }

      .menu-btn {
        &--mini {
          visibility: visible;
          z-index: 1;
          opacity: 1;
          border-radius: 5px;
          background: var(--v-gray-80-base);
        }
      }
    }
  }
}

.sidebar-app-menu {
  z-index: 10;
}

.navigation-drawer-menu {

  :deep(.v-navigation-drawer__content) {
    padding: 0 14px;
  }
  &.v-navigation-drawer--mini-variant {
    :deep(.v-navigation-drawer__content) {
      padding: 0 12px;
    }
  }
}

.folder-icon {
  opacity: 0.6;
}

.space-list-item {
  position: relative;
  border: 1px solid #F5F5F5;
}

.space-count-badge {
  position: absolute;
  right: -13px;
}
.space-list-item-icon {
  height: 16px;
  padding: 0 6px;
}
</style>
