export default {

  /**
   *
   * @param state
   * @param projectModuleId
   * @returns {*}
   */
  getProjectModule(state) {
    return projectModuleId => state.modules.find(module => module.id === projectModuleId);
  },

  /**
   *
   * @param state
   * @returns {[]}
   */
  getWorkflowModules(state) {
    return state.modules
  },

}
