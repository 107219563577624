<template>
  <UiMenu
    v-model="menu"
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
    :close-on-content-click="true"
  >
    <template v-slot:activator="{attrs, on }">
      <UiBtn v-bind="attrs" v-on="on" @click="click" icon color="gray-60">
        <IconDotsH width="18"/>
      </UiBtn>
    </template>

    <v-list dense nav color="gray-10">
      <v-list-item @click="openTaskModal">
        <v-list-item-title class="text-captions-1">
          <IconEye width="14" class="mr-2 gray-60--text"/>
          Open
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="updateTaskFormField(task, 'assignee')">
        <v-list-item-title class="text-captions-1">
          <IconSendMessage width="14" class="mr-2 gray-60--text"/>
          Assign to
        </v-list-item-title>
      </v-list-item>
      <v-list-item disabled>
        <v-list-item-title class="text-captions-1">
          <IconShare width="14" class="mr-2 gray-60--text"/>
          Share
          </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="task && task.parent === null" @click="openSubTaskModal">
        <v-list-item-title class="text-captions-1">
          <IconDraw width="14" class="mr-2 gray-60--text"/>
          Create sub task
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="editEnable(task)" @click="deleteTask">
        <v-list-item-title class="text-captions-1">
          <IconDelete width="14" class="mr-2 gray-60--text"/>
          Delete
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </UiMenu>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
  export default {
    components: {
      IconDotsH: () => import('@/components/icons/IconDotsH'),
      IconEye: () => import('@/components/icons/IconEye'),
      IconSendMessage: () => import('@/components/icons/IconSendMessage'),
      IconShare: () => import('@/components/icons/IconShare'),
      IconDraw: () => import('@/components/icons/IconDraw'),
      IconDelete: () => import('@/components/icons/IconDelete'),

      UiMenu,
      UiBtn
    },
    props: {
      task: {
        type: Object,
        default: () => {}
      },
      openTaskModal: {
        type: Function
      },
      updateTaskFormField: {
        type: Function
      },
      openSubTaskModal: {
        type: Function
      },
      editEnable: {
        type: Function
      },
      deleteTask: {
        type: Function
      }
    },
    data() {
      return {
        menu: false
      }
    },
    methods: {
      click() {
        this.$emit('onClick')
      }
    }
  }
</script>
