export const spaceConfig = {
  space: {
    userRole: {
      spaceAdmin: 'space_admin',
      spaceMember: 'space_member',
    },
    userRoleLabels: {
      'space_admin': 'Admin',
      'space_member': 'Member',
    },
    userStatuses: {
      invited: 1,
      active: 2,
      inactive: 3,
    },
    userStatusLabels: {
      1: 'Invited',
      2: 'Active',
      3: 'Inactive',
    },
    userStatusColors: {
      1: 'in_progress',
      2: 'done',
      3: 'in_review',
    },
  },
}
