<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0506 12.1575C11.0506 12.0166 10.9991 11.8948 10.8961 11.7918C10.7932 11.6889 10.6713 11.6374 10.5305 11.6374C10.3896 11.6374 10.2677 11.6889 10.1648 11.7918C10.0618 11.8948 10.0104 12.0166 10.0104 12.1575C10.0104 12.2984 10.0618 12.4203 10.1648 12.5232C10.2677 12.6261 10.3896 12.6776 10.5305 12.6776C10.6713 12.6776 10.7932 12.6261 10.8961 12.5232C10.9991 12.4203 11.0506 12.2984 11.0506 12.1575ZM13.1309 12.1575C13.1309 12.0166 13.0794 11.8948 12.9765 11.7918C12.8736 11.6889 12.7517 11.6374 12.6108 11.6374C12.47 11.6374 12.3481 11.6889 12.2451 11.7918C12.1422 11.8948 12.0907 12.0166 12.0907 12.1575C12.0907 12.2984 12.1422 12.4203 12.2451 12.5232C12.3481 12.6261 12.47 12.6776 12.6108 12.6776C12.7517 12.6776 12.8736 12.6261 12.9765 12.5232C13.0794 12.4203 13.1309 12.2984 13.1309 12.1575ZM14.1711 10.3372V12.9376C14.1711 13.1543 14.0953 13.3385 13.9436 13.4902C13.7919 13.6419 13.6077 13.7178 13.391 13.7178H1.42886C1.21216 13.7178 1.02796 13.6419 0.876267 13.4902C0.724574 13.3385 0.648727 13.1543 0.648727 12.9376V10.3372C0.648727 10.1205 0.724574 9.93629 0.876267 9.78459C1.02796 9.6329 1.21216 9.55705 1.42886 9.55705H5.20765L6.30472 10.6622C6.61894 10.9656 6.98734 11.1173 7.40991 11.1173C7.83249 11.1173 8.20088 10.9656 8.51511 10.6622L9.6203 9.55705H13.391C13.6077 9.55705 13.7919 9.6329 13.9436 9.78459C14.0953 9.93629 14.1711 10.1205 14.1711 10.3372ZM11.53 5.71325C11.6221 5.93538 11.5842 6.12499 11.4162 6.2821L7.7756 9.92274C7.67808 10.0257 7.55619 10.0771 7.40991 10.0771C7.26364 10.0771 7.14174 10.0257 7.04422 9.92274L3.40359 6.2821C3.23564 6.12499 3.19772 5.93538 3.28982 5.71325C3.38191 5.50197 3.54173 5.39632 3.76927 5.39632H5.84964V1.75569C5.84964 1.61483 5.90111 1.49293 6.00404 1.39C6.10698 1.28706 6.22887 1.2356 6.36973 1.2356H8.45009C8.59095 1.2356 8.71285 1.28706 8.81578 1.39C8.91872 1.49293 8.97019 1.61483 8.97019 1.75569V5.39632H11.0506C11.2781 5.39632 11.4379 5.50197 11.53 5.71325Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDownload'
}
</script>
