export default {

  /**
   *
   * @param commit
   * @param payload
   */
  toggleProjectSideDrawer({commit}, payload) {
    commit('TOGGLE_PROJECT_SIDE_DRAWER', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setProjectSideDrawerTabData({commit}, payload) {
    commit('SET_PROJECT_SIDE_DRAWER_TAB_DATA', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setProjectSideDrawerFilters({commit}, payload) {
    commit('SET_PROJECT_SIDE_DRAWER_FILTERS', payload)
  },

  /**
   *
   * @param commit
   */
  clearProjectSideDrawerFilters({commit}) {
    commit('CLEAR_PROJECT_SIDE_DRAWER_FILTERS')
  },

  /**
   *
   * @param commit
   */
  clearProjectSideDrawerData({commit}) {
    commit('CLEAR_PROJECT_SIDE_DRAWER_DATA')
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setActiveTaskInTaskTab({commit}, payload = null) {
    commit('SET_ACTIVE_TASK_IN_TASK_TAB', payload)
  },

  /**
   *
   * @param commit
   * @param payload
   */
  setActiveTab({commit}, payload) {
    commit('SET_ACTIVE_TAB', payload)
  },

}
