export default axios => ({

  /**
   * Create space folder
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Folders-PostApiV1Folders
   * @param data
   * @returns {*}
   */
  create(data) {
    return axios.post(`folders`, data);
  },

  /**
   * Update space folder
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Folders-PutApiV1FoldersId
   * @param folder_id
   * @param data
   * @returns {*}
   */
  update(folder_id, data) {
    return axios.put(`folders/${folder_id}`, data);
  },

  /**
   * Delete space folder
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Folders-DeleteApiV1FoldersId
   * @param folder_ids
   * @returns {*}
   */
  delete(folder_ids) {
    return axios.delete(`folders`, {params: {ids: folder_ids}});
  },

  /**
   * Move Project to Folder
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Folders-PostApiV1FoldersProject
   * @param data
   * @returns {*}
   */
  move(data) {
    return axios.post(`folders/project`, data);
  },
})
