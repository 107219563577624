export default {
  setAuth ({ commit }, payload) {
    commit('SET_AUTH', payload)
  },
  setTempAuth ({ commit }, payload) {
    commit('SET_TEMP_AUTH', payload)
  },
  setAdminToken ({ commit }, payload) {
    commit('SET_ADMIN_TOKEN', payload)
  },
  logout({ commit }) {
    commit('LOGOUT')
  },
  logoutTempAuth({ commit }) {
    commit('LOGOUT_TEMP_AUTH')
  },
  logoutAdmin({ commit }) {
    commit('LOGOUT_ADMIN')
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },
  setRolesList({ commit }, payload) {
    commit('SET_ROLES_LIST', payload);
  },
  updateUser({ commit }, payload) {
    commit('UPDATE_USER', payload);
  },
  setUserSetting({ commit }, payload) {
    commit('SET_USER_SETTING', payload);
  }
}
