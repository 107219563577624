import config from "@/config";
import guest from "@/router/middleware/guest";

export default [
  {
    path: '/',
    name: 'WelcomeLanding',
    component: () => import(/* webpackChunkName: "WelcomeLanding" */ '@/views/landings/WelcomeLanding/WelcomeLanding'),
    meta: {
      layout: config.layout.landing,
      title: 'UpSway',
      middleware: [
        guest
      ]
    }
  },

  {
    path: '/features',
    name: 'FeaturesLanding',
    component: () => import(/* webpackChunkName: "FeaturesLanding" */ '@/views/landings/FeaturesLanding/FeaturesLanding'),
    meta: {
      layout: config.layout.landing,
      title: 'UpSway Features',
      middleware: [
        guest
      ]
    }
  },

  {
    path: '/applications',
    name: 'ApplicationsLanding',
    component: () => import(/* webpackChunkName: "ApplicationsLanding" */ '@/views/landings/ApplicationsLanding/ApplicationsLanding'),
    meta: {
      layout: config.layout.landing,
      title: 'UpSway Applications',
      middleware: [
        guest
      ]
    }
  },

  {
    path: '/pricing',
    name: 'PricingLanding',
    component: () => import(/* webpackChunkName: "PricingLanding" */ '@/views/landings/PricingLanding/PricingLanding'),
    meta: {
      layout: config.layout.landing,
      title: 'UpSway Pricing',
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: 'PrivacyPolicy' */ '@/views/landings/PrivacyPolicy.vue'),
    meta: {
      layout: config.layout.landing,
      title: 'UpSway Pricing',
      middleware: [
        guest
      ]
    }
  },
]
