import config from "@/config";
import guest from "@/router/middleware/guest";
import checkToken from "@/router/middleware/checkToken";

export default [
  {
    path: '/auth',
    redirect: {name: 'Login'},
    component: () => import(/* webpackChunkName: "AuthPage" */ '@/views/auth/AuthPage'),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: {
          layout: config.layout.auth,
          title: 'Login',
          middleware: [
            guest
          ]
        }
      },
      {
        path: '/registration',
        name: 'Registration',
        meta: {
          layout: config.layout.auth,
          title: 'Registration',
          needAuth: false,
          middleware: [
            guest
          ]
        }
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: {
          layout: config.layout.auth,
          title: 'Forgot password',
          needAuth: false,
          middleware: [
            guest
          ]
        }
      },
      {
        path: '/verify-email',
        name: 'VerifyEmail',
        meta: {
          layout: config.layout.auth,
          title: 'Verify email',
        }
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        meta: {
          layout: config.layout.auth,
          title: 'Reset you forgot password',
          needAuth: false,
          middleware: [
            guest,
            checkToken
          ]
        }
      },
    ]
  },
]
