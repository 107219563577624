import config from "../../../config";

export default [
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'NotFoundPage',
    component: () => import(/* webpackChunkName: "NotFoundPage" */ '@/views/errors/NotFoundPage'),
    meta: {
      layout: config.layout.auth,
      title: 'Page not found',
    }
  },
  {
    path: '/403',
    name: 'ForbiddenPage',
    component: () => import(/* webpackChunkName: "ForbiddenPage" */ '@/views/errors/ForbiddenPage'),
    meta: {
      layout: config.layout.auth,
      title: 'Forbidden',
    }
  },
  {
    path: '/500',
    name: 'InternalServerErrorPage',
    component: () => import(/* webpackChunkName: "InternalServerErrorPage" */ '@/views/errors/InternalServerErrorPage'),
    meta: {
      layout: config.layout.auth,
      title: 'Internal Server Error',
    }
  },
]
