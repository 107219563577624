export default axios => ({

  /**
   * Get all roles list
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-1._Authorizations-GetApiV1Authorizations
   * @returns {*}
   */
  rolesList() {
    return axios.get(`/authorizations`)
  },

  /**
   * Login Route authorization
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PostApiV1AuthorizationsSocialLogin
   * @param data
   * @returns {*}
   */
  login (data) {
    return axios.post('/authorizations/social/login', data);
  },

  /**
   * Registration new user
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PostApiV1AuthorizationsSocialRegister
   * @param data
   * @returns {*}
   */
  registration (data) {
    return axios.post('/authorizations/social/register', data);
  },

  /**
   * Set email for users who have become from social networks without email
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PatchApiV1AuthorizationsSocialSetUserEmail
   * @param data
   * @returns {*}
   */
  setUserEmail(data) {
    return axios.patch(`/authorizations/social/set-user-email `, data);
  },

  /**
   * Verify user email
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PatchApiV1AuthorizationsSocialVerifyEmail
   * @param data
   * @returns {*}
   */
  verifyEmail(data) {
    return axios.patch(`/authorizations/social/verify-email`, data);
  },

  /**
   * Recovery password
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PatchApiV1AuthorizationsSocialRecoverPassword
   * @param data
   * @returns {*}
   */
  recoverPassword(data) {
    return axios.patch(`/authorizations/social/recover-password`, data);
  },

  /**
   * Reset user password
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-Auth-PatchApiV1AuthorizationsSocialResetPassword
   * @param data
   * @returns {*}
   */
  resetPassword(data) {
    return axios.patch(`/authorizations/social/reset-password`, data);
  },

  /**
   * Refresh JWT token
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-1._Authorizations-PutApiV1Authorizations
   * @returns {*}
   */
  refreshToken () {
    return axios.put('/authorizations')
  },

  /**
   * Delete current token
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-1._Authorizations-DeleteApiV1Authorizations
   * @returns {*}
   */
  logout () {
    return axios.delete('/authorizations')
  },

  /**
   * Impersonate user | List users
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Impersonate_user-GetApiV1ImpersonateUsers
   * @param config
   * @returns {*}
   */
  getAllUsers (config) {
    return axios.get('/impersonate/users', config)
  },

  /**
   * Impersonate user | Impersonate user
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Impersonate_user-PostApiV1ImpersonateId
   * @param id
   * @param config
   * @returns {*}
   */
  impersonateUser (id, config) {
    return axios.post(`/impersonate/${id}`, null, config)
  },


})
