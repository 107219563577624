export default axios => ({

  /**
   * Get all spaces
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-4._Tags-GetApiV1Tags
   * @returns {*}
   */
  list() {
    return axios.get(`spaces`);
  },

  /**
   * Create new space
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PostApiV1Tags
   * @param data
   * @returns {*}
   */
  create(data) {
    return axios.post(`spaces`, data);
  },

  /**
   * Update space
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PutApiV1TagsId
   * @param spaceId
   * @param data
   * @returns {*}
   */
  update(spaceId, data) {
    return axios.put(`spaces/${spaceId}`, data);
  },

  /**
   * Delete space
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-DeleteApiV1TagsId
   * @param spaceId
   * @returns {*}
   */
  delete(spaceId) {
    return axios.delete(`spaces/${spaceId}`);
  },

  /**
   * Get all users spaces
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-4._Tags-GetApiV1Tags
   * @param spaceId
   * @param params
   * @returns {*}
   */
  userList(spaceId, params) {
    return axios.get(`spaces/${spaceId}/users`, {params});
  },

  /**
   * Get all users from spaces
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Spaces-GetApiV1SpacesAllUsers
   * @returns {*}
   */

  getAllUsersSpaces() {
    return axios.get(`/spaces/all-users`);
  },

  /**
   * Invite user in space
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PostApiV1Tags
   * @param spaceId
   * @param data
   * @returns {*}
   */
  userCreate(spaceId, data) {
    return axios.post(`spaces/${spaceId}/users`, data);
  },

  /**
   * Update user
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-PutApiV1TagsId
   * @param spaceId
   * @param userId
   * @param data
   * @returns {*}
   */
  userUpdate(spaceId, userId, data) {
    return axios.put(`spaces/${spaceId}/users/${userId}`, data);
  },

  /**
   * Delete user
   *
   * @link: https://devapi.upsway.io/apidoc/index.html#api-4._Tags-DeleteApiV1TagsId
   * @param spaceId
   * @param userId
   * @returns {*}
   */
  userDelete(spaceId, userId) {
    return axios.delete(`spaces/${spaceId}/users/${userId}`);
  },

  /**
   * Duplicate space
   *
   * @link: https://devapi2.upsway.io/apidoc/index.html#api-Spaces-PostApiV1SpacesIdDuplicate
   * @param id
   * @param data
   * @returns {*}
   */
  duplicateSpace(id, data) {
    return axios.post(`/spaces/${id}/duplicate`, data)
  }

})
