<template>
  <v-navigation-drawer
    id="appSideMenu"
    :mini-variant="$vuetify.breakpoint.mdAndDown? false : mini"
    width="186"
    app
    v-model="$store.state.SideMenu.menu"
    :class="{'sidebar-app-menu': $vuetify.breakpoint.mdAndDown}"
  >
    <v-list-item class="menu-top">
      <v-list-item-avatar max-height="31" class="menu-avatar d-flex justify-start mr-0"
                          :class="{'menu-avatar-mini': mini}">
        <UIImage class="menu-avatar-img" width="31" @click="clickAvatar" :name-path="`logo-b.svg`"></UIImage>
        <v-btn
          class="menu-btn--mini"
          icon
          @click.stop="toggleMini"
        >
          <IconExpandRight width="17" class="gray-0--text"/>
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-title>UpSway</v-list-item-title>

      <v-btn
        class="menu-btn"
        icon
        @click.stop="$vuetify.breakpoint.mdAndDown ? $store.dispatch('toggleSideMenu') : toggleMini()"
      >
        <IconExpandLeft width="17"/>
      </v-btn>
    </v-list-item>
    <v-list class="py-0 mt-3 menu-list" :class="{mini: mini}">
      <v-list-item
        v-for="(link, idx) in navLinks"
        :key="idx"
        exact-path
        :to="{name: link.routeName, params: link.params}"
        :ripple="$route.name !== link.routeName"
        :inactive="$route.name === link.routeName"
        :class="['mb-5', {'v-list-item--active': $route.name === link.routeName}]"
      >
        <v-list-item-icon class="align-self-center mr-4 mt-0 mb-0">
          <component :is="link.icon" width="14" class="gray-80--text"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="gray-100--text">
            {{ link.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import UIImage from "../UI/UIImage";

export default {
  name: "AdminSideMenu",
  components: {
    IconExpandLeft: () => import('@/components/icons/IconExpandLeft'),
    IconExpandRight: () => import('@/components/icons/IconExpandRight'),
    IconUserSolid: () => import("@/components/icons/IconUserSolid"),
    UIImage
  },
  data() {
    return {
      mini: false,
      expand: false
    }
  },
  computed: {
    navLinks() {
      return [
        {title: 'Users', routeName: 'Admin', icon: 'IconUserSolid',},
      ]
    }
  },
  methods: {
    toggleMini() {
      if (!this.$vuetify.breakpoint.mdAndDown) {
        this.mini = !this.mini;
        this.$store.dispatch('toggleSideMenuWidth');
      }
    },
    clickAvatar() {
      if (this.mini) {
        this.toggleMini()
      }
    },
  },
  mounted() {
    if (!this.$vuetify.breakpoint.mdAndDown) {
      this.$store.dispatch('toggleSideMenu')
    }
  }
}
</script>

<style scoped lang="scss">
#appSideMenu {
  &::v-deep {
    .v-navigation-drawer {
      &__content {
        background-color: var(--v-gray-10-base);
      }
    }

    .v-divider {
      border-color: var(--v-border-10-base);
    }
  }

  .menu-list {
    &::v-deep {
      .v-list-item {
        border-radius: 5px;
        padding: 6px 9px;
        transition: 0.3s;
        min-height: 30px;

        &.space-action {
          background-color: transparent !important;

          .v-btn {
            background: #ffffff !important;
          }
        }

        &__content {
          padding: 0;
        }

        &__title {
          font-size: 14px;
        }

        &__icon {
          margin-left: 0 !important;
        }

        &__icon, &__action {
          min-width: unset;
        }


        &::before, &::after {
          display: none;
        }

        &:hover {
          background-color: var(--v-gray-0-base);
        }

        &--active {
          background-color: var(--v-gray-30-base);

          &:hover {
            background-color: var(--v-gray-30-base);
          }
        }
      }

      &.v-list {
        padding: 20px 8px 20px 8px;

        &.mini {
          padding: 20px 12px 20px 12px;

          .v-list-item {
            &:hover {
              background-color: var(--v-gray-60-base);

              i {
                color: var(--v-gray-0-base) !important;
              }
            }

            &--active {
              &:hover {
                background-color: var(--v-gray-30-base);

                i {
                  color: var(--v-gray-80-base) !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-top {
    padding: 0px 8px 0px 8px;

    &::v-deep {
      .v-list-item__title {
        font-weight: 600;
        font-size: 20px;
        color: var(--v-gray-100-base);
      }
    }
  }

  .menu-btn {
    color: var(--v-gray-80-base);
    border-radius: 5px;
    height: 31px;
    width: 31px;

    &:hover {
      background: var(--v-gray-30-base);
    }

    &--mini {
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      height: 31px;
      width: 31px;
    }
  }

  .menu-avatar {
    border-radius: unset;

    .menu-avatar-img {
      width: 31px;
      border-radius: 50%;
    }
  }

  .menu-avatar-mini {
    justify-content: center !important;

    &:hover {
      .menu-avatar-img {
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
      }

      .menu-btn {
        &--mini {
          visibility: visible;
          z-index: 1;
          opacity: 1;
          border-radius: 5px;
          background: var(--v-gray-80-base);
        }
      }
    }
  }
}

.sidebar-app-menu {
  z-index: 10;
}
</style>
