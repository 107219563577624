<template>
  <component :is="moduleIcon.name" :width="width || moduleIcon.width"/>
</template>

<script>
export default {
  name: 'UiProjectModuleIcon',
  components: {
    IconLightbulb: () => import('@/components/icons/IconLightbulb'),
    IconFolder: () => import('@/components/icons/IconFolder'),
    IconChat: () => import('@/components/icons/IconChat'),
    IconDraw: () => import('@/components/icons/IconDraw'),
    IconCube: () => import('@/components/icons/IconCube'),
    IconPenCurved: () => import('@/components/icons/IconPenCurved'),
    IconSpaces: () => import('@/components/icons/IconSpaces'),
    IconCopy: () => import('@/components/icons/IconCopy'),
    IconTag: () => import('@/components/icons/IconTag'),
    IconPalette: () => import('@/components/icons/IconPalette'),
    IconMoodboard: () => import('@/components/icons/IconMoodboard'),
    IconPaperclip: () => import('@/components/icons/IconPaperclip'),
    IconGlobe: () => import('@/components/icons/IconGlobe'),
    IconPen: () => import('@/components/icons/IconPen'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    IconFile: () => import('@/components/icons/IconFile'),
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
    }
  },
  computed: {
    moduleIcon() {
      return this.$config.project.moduleIcons.find(icon => icon.name === this.$props.icon);
    }
  }
}
</script>
