<template>
  <div class="s-select relative" v-click-outside="close">
    <div class="s-select__input mt-0" @click="isOpen = !isOpen">
      <div class="s-select__input__text">
        <template v-if="selectedItem">
          <span >
            {{selectedItem[itemName]}}
          </span>
        </template>
        <template v-else>
          <span class="placeholder">
            {{placeholder}}
          </span>
        </template>
      </div>
      <IconCaretDown width="6" :class="['s-select__input__icon', {'rotate': isOpen}]"/>
    </div>
    <div class="s-select__dropdown" v-if="isOpen">
      <div
        class="s-select__input"
        v-for="(inp, index) in items.filter(el => el[itemId] !== (selectedItem && selectedItem[itemId]))"
        :key="index"
        @click="selectItem(inp)"
      >
        <span class="s-select__input__text">{{ inp[itemName] }}</span>
      </div>
      <div
        v-if="appendItemText && appendItemFunc"
        class="s-select__input"
        @click="appendItemFunc"
      >
        <span class="s-select__input__append-item-text">{{ appendItemText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UiBtnSelect',
    components: {
      IconCaretDown: () => import('@/components/icons/IconCaretDown'),
    },
    props: {
      placeholder: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: () => []
      },
      value: {
        required: true
      },
      itemName: {
        type: String,
        default: 'name'
      },
      itemId: {
        type: String,
        default: 'id'
      },
      appendItemText: {
        type: String,
        default: ''
      },
      appendItemFunc: {
        type: Function
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    computed: {
      itemToShow() {
        return this.items.find(el => el.id === this.value);
      },
      selectedItem: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      }
    },
    methods: {
      selectItem(inp) {
        this.selectedItem = inp
        this.$emit('change', inp)
        this.isOpen = false
      },
      close() {
        this.isOpen = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.s-select {
  * {
    transition: 0.3s;
  }
  min-width: 100%;
  min-height: 40px !important;
  background: transparent;
  &__input {
    display: flex;
    background: var(--v-gray-0-base);
    padding: 9px;
    border-radius: 2px;
    margin-top: 2px;
    min-height: 32px;
    &__icon {
      color: var(--v-gray-80-base) !important;
      &.rotate {
        transform: rotate(180deg) !important;
      }
    }
    &__text {
      width: 100%;
      font-size: 14px;
      line-height: 1;
      color: var(--v-gray-100-base);
      overflow: hidden;
      span {
        white-space: nowrap;
      }
    }
    &__append-item-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      width: 100%;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--v-accent-base);
    }
  }
  .placeholder {
    opacity: 0.6;
  }
}
</style>
