<template>
  <div class="badge d-flex align-center justify-center" :class="[filled ? 'filled' : '', small ? 'small' : '']">
    {{ count }}
  </div>
</template>

<script>
  export default {
    props: {
      count: {
        type: [String, Number],
        default: '0',
      },
      filled: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
.badge {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  letter-spacing: 0.1px;

  border: 1px solid #FFB800;
  color: #A56300;
  &.filled {
    border: 1px solid #A56300;
    color: #fff;
    background-color: #FF9F2E;
  }
  &.small {
    font-size: 9px;
    letter-spacing: -0.2px;
  }
}
</style>
