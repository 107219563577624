import config from "../../../config";
import auth from "../../middleware/auth";

export default [
  {
    path: '/project/create',
    name: 'ProjectCreate',
    component: () => import(/* webpackChunkName: "ProjectCreateGeneralStep" */ '@/views/project/create-or-edit/ProjectCreateEdit.vue'),
    meta: {
      layout: config.layout.app,
      title: 'Create new project',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/project/:project_id/edit',
    name: 'ProjectEdit',
    component: () => import(/* webpackChunkName: "ProjectCreateGeneralStep" */ '@/views/project/create-or-edit/ProjectCreateEdit.vue'),
    meta: {
      layout: config.layout.app,
      title: 'Edit project',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/project/:project_id',
    name: 'Project',
    redirect: { name: 'ProjectEdit'},
    meta: {
      layout: config.layout.app,
      title: 'Create new project',
      middleware: [
        auth,
      ]
    },
  },
  {
    path: '/project/:project_id/edit-workflow',
    name: 'ProjectEditWorkflow',
    component: () => import(/* webpackChunkName: "ProjectEditWorkflow" */ '@/views/project/ProjectEditWorkflowPage.vue'),
    meta: {
      layout: config.layout.app,
      title: 'Edit Workflow',
      middleware: [
        auth,
      ]
    },
  },
  // {
  //   path: '/project/:project_id/specifications/general',
  //   name: 'SpecificationsGeneral',
  //   component: () => import(/* webpackChunkName: "ProjectUpdateGeneralStep" */ '@/views/project/specification/GeneralStepUpdate'),
  //   meta: {
  //     layout: config.layout.app,
  //     title: 'Project: Specifications',
  //     middleware: [
  //       auth,
  //     ]
  //   },
  // },
  // {
  //   // Path used in new_teammate_email_template
  //   path: '/project/:project_id/specifications/team',
  //   name: 'SpecificationsTeam',
  //   component: () => import(/* webpackChunkName: "ProjectTeamStep" */ '@/views/project/specification/TeamStep'),
  //   meta: {
  //     layout: config.layout.app,
  //     title: 'Project: Specifications > team',
  //     middleware: [
  //       auth,
  //     ]
  //   }
  // },
  // {
  //   path: '/project/:project_id/specifications/guidelines',
  //   name: 'SpecificationsGuidelines',
  //   component: () => import(/* webpackChunkName: "ProjectGuidelinesStep" */ '@/views/project/specification/GuidelinesStep'),
  //   meta: {
  //     layout: config.layout.app,
  //     title: 'Project: Specifications > guidelines',
  //     middleware: [
  //       auth,
  //     ]
  //   }
  // },
  // {
  //   path: '/project/:project_id/specifications/attachments',
  //   name: 'SpecificationsAttachments',
  //   component: () => import(/* webpackChunkName: "ProjectAttachmentsStep" */ '@/views/project/specification/AttachmentsStep'),
  //   meta: {
  //     layout: config.layout.app,
  //     title: 'Project: Specifications > attachments',
  //     middleware: [
  //       auth,
  //     ]
  //   }
  // },
  {
    path: '/project/:project_id/modules/whiteboard',
    name: 'MoodBoard',
    component: () => import(/* webpackChunkName: "MoodBoardPage" */ '@/views/project/moodboard/MoodBoardPage'),
    meta: {
      layout: config.layout.app,
      title: 'Project: Whiteboard',
      middleware: [
        auth,
      ]
    },
  },
  {
    // Path used in request_review_email_template, change_project_status_template
    path: '/project/:project_id/modules/design',
    name: 'ProjectDesign',
    component: () => import(/* webpackChunkName: "ProjectDesignListPage" */ '@/views/project/design-list/ProjectDesignListPage'),
    meta: {
      layout: config.layout.app,
      title: 'Project: Design',
      middleware: [
        auth,
      ]
    },
  },
  {
    // Path used in new_comment_email_template, assigned_task_email_template with all query parameters
    path: '/project/:project_id/modules/design-view',
    name: 'DesignViewPage',
    component: () => import(/* webpackChunkName: "DesignViewPage" */ '@/views/project/design-view/DesignViewPage'),
    meta: {
      layout: config.layout.app,
      title: 'Project: Design View',
      middleware: [
        auth,
      ]
    },
  },
]
