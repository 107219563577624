<template>
  <div class="d-flex flex-column fill-height">
    <div class="pt-3 px-6 mb-3">
      <div class="text-captions-1 gray-80--text mb-2">
        Activity ({{ getProjectSideDrawerActivities.total }})
      </div>
      <v-divider/>
    </div>

    <Chat
      :messages="getProjectSideDrawerActivities.data"
      :users="getProject.team"
      :permissions="{
        canCreateMessage: getPermission($route.params.project_id)['project-s-drawer']['can-create-message-p-s-d'],
        canDeleteMessage: getPermission($route.params.project_id)['project-s-drawer']['can-delete-message-p-s-d'],
      }"
      @loadMessages="loadMessages"
      @sendMessage="sendMessage"
      @editMessage="editMessage"
      @deleteMessage="deleteMessage"
    />
  </div>
</template>

<script>
import Chat from "../../common/Chat";
import {mapGetters} from "vuex";

export default {
  name: 'ActivityTab',
  components: {
    Chat,
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getPermission',
      'getProjectSideDrawer',
      'getProjectSideDrawerFilters',
      'getProjectSideDrawerActivities',
    ]),
  },
  mounted() {
    this.$eventBus.$on('updateSidDrawerFilters', async data => {
      const tabFilters = this.getProjectSideDrawer.filtersByTab.activities;
      const updatedFilterItems = Object.keys(data);
      const updateNeeded = updatedFilterItems.some(filterItem => tabFilters.includes(filterItem) && JSON.stringify(data[filterItem]) !== JSON.stringify(this.getProjectSideDrawerFilters[filterItem]))
      if (updateNeeded) {
        await this.$store.dispatch('setProjectSideDrawerTabData', {tab: 'activities', data: []});
        this.getMessages({
          ...data,
          project_module: data.project_module ? [data.project_module] : null,
        });
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.$off('updateSidDrawerFilters');
  },
  methods: {
    deleteMessage(message) {
      this.$ws.sendMessage(this.$config.ws.methods.deleteMessage, {
        message_id: message.id
      });
    },
    sendMessage(data) {
      const {project_tag_id, project_style_id, order, project_module} = this.getProjectSideDrawerFilters;
      const {message_text, message_images, tagged_users, replied_message_id} = data;
      this.$ws.sendMessage(this.$config.ws.methods.createMessage, {
        project_style_id,
        project_tag_id,
        order,
        project_module: project_module ? [project_module] : null,
        project_id: this.getProject.id,
        from: 0,
        message_text: encodeURIComponent(message_text),
        file: message_images.map(message_image => message_image.url.split(',')[1]),
        tagged_users,
        replied_message_id,
      });
    },
    editMessage(message) {
      const {message_text, message_images, ...other} = message;
      this.$ws.sendMessage(this.$config.ws.methods.updateMessage, {
        message_text: encodeURIComponent(message_text),
        new_file: message_images.map(message_image => message_image.url.split(',')[1]),
        ...other
      });
    },
    loadMessages() {
      if (this.getProjectSideDrawerActivities.data.length === this.getProjectSideDrawerActivities.total) return;
      const {project_module} = this.getProjectSideDrawerFilters;
      const lastItemId = this.getProjectSideDrawerActivities.data[this.getProjectSideDrawerActivities.data.length - 1].id;
      this.$ws.sendMessage(this.$config.ws.methods.paginationMessage, {
        ...this.getProjectSideDrawerFilters,
        project_module: project_module ? [project_module] : null,
        project_id: this.getProject.id,
        from: lastItemId,
        search: encodeURIComponent(this.getProjectSideDrawerFilters.search),
      });
    },
    getMessages(data) {
      const {project_module} = this.getProjectSideDrawerFilters;
      this.$ws.sendMessage(this.$config.ws.methods.paginationMessage, {
        ...this.getProjectSideDrawerFilters,
        project_id: this.getProject.id,
        project_module: project_module ? [project_module] : null,
        from: 0,
        search: encodeURIComponent(this.getProjectSideDrawerFilters.search),
        ...data,
      });
    }
  },
}
</script>
