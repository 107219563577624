export default {

  /**
   * Set notifications
   *
   * @param state
   * @param payload
   */
  TOGGLE_NOTIFICATION_BAR(state, payload) {
    state.showNotificationBar = payload;
  },
  /**
   * Set notifications
   *
   * @param state
   * @param payload
   */
  SET_NOTIFICATION_TYPE(state, payload) {
    state.notificationBarType = payload;
  },

  /**
   * Set notifications
   *
   * @param state
   * @param payload
   */
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = {...state.notifications, ...payload}
  },
  /**
   * Set messages
   *
   * @param state
   * @param payload
   */
  SET_MESSAGES(state, payload) {
    state.messages = {...state.messages, ...payload}
  },

  SET_PROJECTS_NOTIFICATIONS(state, payload) {
    state.projectsNotifications = payload.map(item => ({...item, loading: false}))
  },
  /**
   * Set push notification
   *
   * @param state
   * @param payload
   */
  SET_PUSH_NOTIFICATION(state, payload) {
    state.pushNotification = payload
  },

  /**
   * Set notifications sort
   *
   * @param state
   * @param payload
   */
  SET_NOTIFICATION_SORT(state, payload) {
    state.notificationBarSort = payload;
  },

  /**
   * Set messages sort
   *
   * @param state
   * @param payload
   */
  SET_MESSAGES_SORT(state, payload) {
    state.messagesBarSort = payload;
  },
}
